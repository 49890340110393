import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {

};

const getters = {

};
const mutations = {

};
const actions = {
  /**
   * 获取推荐列表
   */
  // getShopFeatured({ rootState }, {
  //   search, sortBy, filters, id,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getShopFeatured(id),
  //     params: {
  //       ...filters,
  //       search,
  //       game: rootState.game,
  //       sort_by: sortBy,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 获取在售货品列表
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  // getShopSellOrder({ rootState }, {
  //   search, sortBy, filters, page, id,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getShopSellOrder(id),
  //     params: {
  //       ...filters,
  //       search,
  //       game: rootState.game,
  //       sort_by: sortBy,
  //       page_num: page.pageNum,
  //       page_size: page.pageSize,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 获取求购货品列表
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  // getShopBuyOrder({ rootState }, {
  //   search, sortBy, filters, page, id,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getShopBuyOrder(id),
  //     params: {
  //       ...filters,
  //       search,
  //       game: rootState.game,
  //       sort_by: sortBy,
  //       page_num: page.pageNum,
  //       page_size: page.pageSize,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 获取最近成交货品列表
   * @param {string} id 用户id
   */
  // getShopBillOrder({ rootState }, { id } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getShopBillOrder(id),
  //     params: {
  //       game: rootState.game,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 设置推荐饰品
   * @param {string} sell_order 订单号
   * @param {number} featured 0:取消 1:推荐
   */
  // putSellOrderFeatured(_, { sellOrder, featured }) {
  //   return Axios({
  //     method: 'put',
  //     url: api.putSellOrderFeatured(),
  //     data: {
  //       sell_order: sellOrder,
  //       featured,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 设置店铺信息
   * @param {string} sell_order 订单号
   * @param {number} featured 0:取消 1:推荐
   */
  // postShopInfo(_, { allowBillOrdersDisplay, desc, images }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postShopInfo(),
  //     data: {
  //       desc,
  //       images,
  //       allow_bill_orders_display: allowBillOrdersDisplay,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 获取店铺浮层
   * @param {string} sell_order 订单号
   * @param {number} featured 0:取消 1:推荐
   */
  getUserStorePopup({ rootState }, { id }) {
    return Axios({
      method: 'get',
      url: api.getUserStorePopup(),
      params: {
        game: rootState.game,
        user_id: id,
      },
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
