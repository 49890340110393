<template>
  <div class="countdown-box">
    <div class="countdown-desc font15">{{ $t('new_coupon.countdown') }}</div>
    <div class="countdown-time font16b">{{ timeInfo.hour }}</div> :
    <div class="countdown-time font16b">{{ timeInfo.minute }}</div> :
    <div class="countdown-time font16b">{{ timeInfo.second }}</div>
  </div>
</template>

<script>

export default {
  props: {
    timeInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  computed: {
    currencySymbol() {
      return this.$store.getters.currencySymbol;
    },
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.countdown-box {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(50%);
  color: var(--el-gf1);
}
.countdown-desc {
  margin-right: 6px;
}
.countdown-time {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: linear-gradient(180deg, #000 0%, #323232 100%);
  color: var(--fu-white1);
  border-radius: 4px;
  margin: 0 4px;
  @media (max-width: 750px) {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
}
</style>
