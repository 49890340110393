import { TextType } from '@/enums';

import { isArray, isNumber } from '.';

const LOOTBAR_DEFAULT_TITLE = {
  [TextType.en]: 'LootBar.gg - Safer and Cheaper Game Marketplace',
  [TextType.ja]: 'LootBar.gg - より安全で、より安いゲームマーケット',
  [TextType.zh_Hant]: 'LootBar.gg - 更安全和更便宜的遊戲市場',
};
const LOOTBAR_DEFAULT_DESCRIPTION = {
  [TextType.en]: 'LootBar is a global professional and secure gaming transaction platform, offering services such as game recharge, item trading, and more. LootBar supports popular games like PUBG, Genshin Impact, Free Fire, Mobile Legends, and others. LootBar provides safe, convenient, and efficient transaction services for gamers worldwide. For game transactions, choose LootBar.',
  [TextType.ja]: 'LootBarは、世界級のプロかつ安全なゲーム取引プラットフォームであり、ゲームのチャージ、アイテム取引などのサービスを提供しており、PUBG、原神Impact、Free fire、Mobile Legendsなどの人気ゲームに対応しています！LootBarは、世界中のプレイヤーに安全、便利かつ効率的な取引サービスを提供します！ゲーム取引にはLootBarをご利用ください。',
  [TextType.zh_Hant]: 'LootBar是一個全球專業且安全的遊戲交易平台，提供遊戲充值、遊戲幣交易、物品交易等服務。LootBar支持PUBG、原神、Free Fire、Mobile Legends等熱門遊戲。LootBar為全球玩家提供安全、方便、高效的交易服務。遊戲交易就選擇LootBar。',
};
const LOOTBAR_DEFAULT_KEYWORDS = {
  [TextType.en]: 'Game Top-Up, Game Trade, Game Account Trade, Item Trade, Fast Delivery, Cheap, Safe, Reliable, Mobile Game Trade, PUBG, Genshin Impact, Free fire, Mobile Legends, NIKKE',
  [TextType.ja]: 'ゲーム取引,モバイルゲーム取引,ゲームチャージ,ゲームアカウント,アイテム取引,装備取引,原神,勝利の女神：NIKKE,原神 課金,Top Up,Recharge,ブルアカ（ブルーアーカイブ）,ウマ娘 プリティーダービー,ゲームトレード,スマホゲーム,アカウント',
  [TextType.zh_Hant]: '遊戲充值, 遊戲交易, 遊戲幣, 物品交易, 快速交付, 便宜, 安全, 可靠, 手機遊戲交易, PUBG, 原神, Free Fire, 傳說對決, NIKKE',
};
const MARKET_DEFAULT_TITLE = {
  [TextType.en]: 'LootBar - Buy & Sell Knivesout items',
  [TextType.ja]: 'LootBar - 荒野行動アイテムの購入および販売',
  [TextType.zh_Hant]: 'LootBar - 購買和出售荒野行動道具',
};
const MARKET_DEFAULT_DESCRIPTION = {
  [TextType.en]: 'LootBar is an in-game item trading platform dedicated to games such as Knives Out, providing secure, convenient and efficient trading services for each trader. Users are able to purchase various weapon items and chests of Knives Out, or as sellers, they could list their items and sell them here. Purchase Knives Out items, choose LootBar.',
  [TextType.ja]: 'LootBar(旧BUFFMARKET) は、荒野行動などのゲームを中心とするアイテム取引プラットフォームであり、購入者と販売者に安全安心、かつ便利的な取引サービスを提供します。荒野行動でさまざまな銃器やアイテム、ギフト ボックスなどを購入したり、出品者としてて販売したりことができます。荒野行動のアイテムを購入するのなら、LootBarに行きましょう！',
  [TextType.zh_Hant]: 'LootBar是一個聚焦於荒野行動等遊戲的道具交易平台， 為買賣雙方提供安全、方便、高效的交易服務。你可以購買荒野行動中的各種槍械道具和禮盒，或者作為賣家上架你的荒野行動槍械道具並出售。買荒野行動道具，就上LootBar',
};
const MARKET_DEFAULT_KEYWORDS = {
  [TextType.en]: 'Knives Out, Knives Out weapon, Knives Out Hall of Fame auto, Knives Out item trade, Knives Out RMT, Item trading platform, secure platform for tradin',
  [TextType.ja]: '荒野行動, 荒野行動ガン, 荒野行動カー, 荒野行動ＲＭＴ, アイテム取引プラットフォーム, 安全な取引プラットフォーム, 荒野 行動 贈り物, 荒野 行動 チャージ センター, 荒野 行動 バインド 金券, バフマーケット, buffマーケット, buff荒野, 荒野マーケット, 荒野バフマーケット, 旧BUFFMARKET',
  [TextType.zh_Hant]: '荒野行動, 荒野行動槍, 荒野行動殿堂車, 荒野行動道具交易, 荒野行動RMT, 道具交易平台, 安全交易平',
};

function generateLdJson(product) {
  const {
    name,
    description,
    image,
    offers,
    rating,
  } = product;
  const { origin, pathname } = window.location;
  const o = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name,
    image,
    url: `${origin}${pathname}`,
    description,
    brand: { '@type': 'Brand', name: 'LootBar' },
  };
  if (offers) {
    o.offers = {
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
      priceCurrency: 'USD',
    };
    if (offers.price) {
      if (isNumber(offers.price)) {
        o.offers['@type'] = 'Offer';
        o.offers.price = offers.price;
      } else if (isArray(offers.price)) {
        o.offers['@type'] = 'AggregateOffer';
        [o.offers.lowPrice, o.offers.highPrice] = offers.price;
        o.offers.offerCount = offers.count ? offers.count.toString() : '1';
      }
      if (product.name === 'Squad Busters') {
        o.offers.shippingDetails = {
          '@type': 'OfferShippingDetails',
          'deliveryTime': {
            '@type': 'ShippingDeliveryTime',
            'handlingTime': {
              '@type': 'QuantitativeValue',
              'minValue': 0,
              'maxValue': 0,
              'unitCode': 'DAY',
            },
            'transitTime': {
              '@type': 'QuantitativeValue',
              'minValue': 0,
              'maxValue': 0,
              'unitCode': 'DAY',
            },
          },
        };
      }
    }
  }
  if (rating) {
    o.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: rating.value.toString(),
      reviewCount: rating.count.toString(),
      worstRating: 1,
      bestRating: 5,
    };
  }
  return o;
}

function setLdJson(ldJson) {
  let script = document.querySelector('script[type="application/ld+json"]');
  if (!script) {
    script = document.createElement('script');
    script.type = 'application/ld+json';
    document.head.appendChild(script);
  }
  script.innerHTML = JSON.stringify(ldJson);
}
function removeLdJson() {
  const script = document.querySelector('script[type="application/ld+json"]');
  if (script) {
    script.remove();
  }
}

function setCanonical(pathname) {
  const { origin, pathname: _pathname } = window.location;
  const _href = `${origin}${pathname || _pathname}`;
  const canonical = document.querySelector('link[rel="canonical"]');
  if (canonical) {
    canonical.setAttribute('href', _href);
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', _href);
    document.head.appendChild(link);
  }
}

/**
 *
 * @param {Object} params
 * @param {String} params.title
 * @param {String} params.description
 * @param {String} params.keywords
 * @param {String} params.keyword
 * @param {Object} params.product 商品信息
 * @param {String} params.product.name 商品名称
 * @param {String} params.product.description 商品描述
 * @param {String} params.product.image 商品图片
 * @param {Object} params.product.offers [可选]商品供应信息
 * @param {Number} params.product.offers.price 商品价格
 * @param {Array} params.product.offers.price 商品价格范围
 * @param {Number} params.product.offers.count 商品数量
 * @param {Object} params.product.rating [可选]商品评分
 * @param {Number} params.product.rating.value 商品评分值
 * @param {Number} params.product.rating.count 商品评分数量
 * @param {Object} defaultOpt 默认选项
 * @param {Boolean} defaultOpt.isMarket tdk为空时显示市场默认tdk
 * @param {String} defaultOpt.language 默认tdk显示语言
 */
export default function setMeta(params = {}, defaultOpt = {}, pathname = '') {
  const {
    title,
    description,
    keywords,
    keyword,
    product,
    canonical,
  } = params;

  const { isMarket = false, language = TextType.en } = defaultOpt;

  if (product && (product.offers || product.rating)) {
    const ldJson = generateLdJson(product);
    setLdJson(ldJson);
  } else {
    removeLdJson();
  }

  setCanonical(canonical || pathname);

  const DEFAULT_TITLE = isMarket
    ? MARKET_DEFAULT_TITLE : LOOTBAR_DEFAULT_TITLE;
  const DEFAULT_DESCRIPTION = isMarket
    ? MARKET_DEFAULT_DESCRIPTION : LOOTBAR_DEFAULT_DESCRIPTION;
  const DEFAULT_KEYWORDS = isMarket
    ? MARKET_DEFAULT_KEYWORDS : LOOTBAR_DEFAULT_KEYWORDS;

  const defaultTitle = DEFAULT_TITLE[language]
    || DEFAULT_TITLE[TextType.en];
  const defaultDescription = DEFAULT_DESCRIPTION[language]
    || DEFAULT_DESCRIPTION[TextType.en];
  const defaultKeywords = DEFAULT_KEYWORDS[language]
    || DEFAULT_KEYWORDS[TextType.en];

  const _title = title || defaultTitle;
  const _description = description || defaultDescription;
  const _keywords = keywords || keyword || defaultKeywords;

  document.title = _title;
  document.querySelector('meta[name="description"]').setAttribute('content', _description);
  document.querySelector('meta[name="keywords"]').setAttribute('content', _keywords);

  document.querySelector('meta[property="og:title"]').setAttribute('content', _title);
  document.querySelector('meta[property="og:description"]').setAttribute('content', _description);
}
