<template>
  <div class="c-home-cell"
    :class="{'is-mobile': isMobile, 'is-loading':loading}"
    @click="$emit('click')">
    <template v-if="!loading">
      <div class="c-home-cell-label">
        <HomeLabel :html-content="info.market_tag" />
      </div>

      <div v-if="info.save_price_rate"
        class="c-home-cell_badge">
        <SaveTag :rate="info.save_price_rate" class="font14r-min" />
      </div>
    </template>

    <div class="c-home-cell_left">
      <img v-if="iconUrl" :key="shopName" :alt="shopName" :title="shopName"
        :src="`${iconUrl}?fop=imageView/2/w/128/h/128`" />
    </div>

    <div class="c-home-cell-right" :class="{'is-center': !isOnline}">
      <div class="font15r c-home-cell-right_title">
        <SearchName v-if="keyword" :keyword="keyword">{{ shopName }}</SearchName>
        <template v-else>{{ shopName }}</template>
      </div>
      <template v-if="isOnline">
        <div class="c-home-cell-right_content">
          <template v-if="isOldCard">
            <!-- 价格：一种只展示单价一种展示最低和最高 -->
            <div class="c-home-cell-right_price font18b-min font-fin" v-html="price"></div>
          </template>
          <template v-else-if="info.comment_score_info">
            <div v-if="info.comment_score_info.total" class="c-home-cell-right_comment">
              <span class="font12b-min c-home-cell-right_comment-score">
                {{ info.comment_score_info.score.toFixed(1) }}</span>
              <Rate class="c-home-cell-right_comment-rate"
                :value="info.comment_score_info.score" size="s" disabled />
              <span class="font14r-min c-home-cell-right_comment-total">
                <span>{{ info.comment_score_info.total
                }}</span>{{ $t('evaluate.item_evaluate') }}</span>
            </div>
          </template>
        </div>
        <div class="font12r-min c-home-cell-right_remark">
          <span>{{ soldInfos }}</span>
        </div>
      </template>
      <template v-else>
        <div class="font14b c-home-cell-right_bookmark">
          <span>{{ $t('global.hot_bookmark') }}</span>
        </div>
      </template>
      <div v-if="info.attributes && info.attributes.length>0" class="c-home-cell-right_tag">
        <span
          v-for="(vtem, index) in info.attributes[0].options"
          :key="index"
          slot="tag"
          class="tag-list">
          {{ vtem.text }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import formatPrice from '@/filters/format-price';
import formatSaleVolume from '@/filters/format-sale-volume';
import { OnlineStatus, TABNAME } from '@/enums';
import Rate from '@/components/Comment/Rate.vue';
import { isMobile } from '@/utils';
import SaveTag from '@/components/SaveTag.vue';
import SearchName from '../Nav/components/SearchName.vue';
import HomeLabel from './HomeLabel.vue';

export default {
  components: {
    Rate,
    SaveTag,
    SearchName,
    HomeLabel,
  },
  props: {
    loading: Boolean,
    info: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'default',
    },
    serviceType: { type: String, default: '' },
    keyword: { type: [String, Number], default: '' },
  },
  computed: {
    ...mapGetters(['currencySymbol']),
    isMobile() {
      return isMobile();
    },
    isOnline() {
      if (!this.info.online) return true;
      const isOnline = this.info.online === OnlineStatus.Online;
      const isPreOrder = this.info.online === OnlineStatus.PreOrder;
      const isComingSoon = this.info.game_app_service_extra_info?.home_page_coming;
      return isOnline || (isPreOrder && !isComingSoon);
    },
    // 商品的类型
    shopType() {
      return this.serviceType ?? this.info.service_type;
    },
    shopName() {
      return this.type === 'default' ? this.info.desc : `${this.info.app_name} ${this.info.asset_info.info.asset_name}`;
    },
    iconUrl() {
      return this.info?.extra?.list_icon_url
      ?? this.info?.game_extra_info?.list_icon_url ?? this.info?.asset_info?.info.properties.icon;
    },

    isOldCard() {
      return [TABNAME.CDKEY, TABNAME.ACCOUNT].includes(this.shopType);
    },

    price() {
      const { info, type } = this;
      if (type === 'search') {
        return `
          <span class="cell-symbol font12b-min font-fin">${this.currencySymbol}</span><span class="cell-price font20b-min font-fin">${formatPrice(info.unit_price)}</span>`;
      }

      const minPrice = info.sell_min_price
      || info.game_app_service_extra_info?.sell_info?.min_price;
      const maxPrice = info.sell_max_price
      || info.game_app_service_extra_info?.sell_info?.max_price;
      if (minPrice === maxPrice) {
        return `
          <span class="cell-symbol font12b-min font-fin">${this.currencySymbol}</span><span class="cell-price font20b-min font-fin">${formatPrice(maxPrice)}</span>`;
      }

      return `
        <div class="cell-right-item">
          <span class="cell-symbol font12b-min font-fin">${this.currencySymbol}</span><span class="cell-price font20b-min font-fin">${formatPrice(minPrice)}</span>
        </div>
        <div class="cell-space">~</div>
        <div class="cell-right-item">
          <span class="cell-symbol font12b-min font-fin">${this.currencySymbol}</span><span class="cell-price font20b-min font-fin">${formatPrice(maxPrice)}</span>
        </div>`;
    },

    soldInfos() {
      if (this.type === 'search') {
        const platform = this.info.asset_info?.attribute?.find((item) => (item.key === 'platform'));
        if (platform) return platform.option_text;
        return this.info?.asset_info?.subgame_desc;
      }
      const tab = this.shopType;

      if (tab === TABNAME.ACCOUNT) {
        return ` ${this.info?.sell_num} ${this.$t('sell.selling')}`;
      }
      if (tab === TABNAME.TOPUP
        || tab === TABNAME.COINTRADE
        || tab === TABNAME.ASSET || tab === TABNAME.ITEMTRADE) {
        const count = this.info?.game_app_service_extra_info?.sell_info?.transacted_num
        ?? this.info?.transacted_num;
        const saleVolume = formatSaleVolume(count);
        return `${saleVolume} ${this.$t('favorites.sold')}`;
      }
      return null;

      // 分为已出售 和 在售商品数
    },
  },
};
</script>

<style lang="less" scoped>
.c-home-cell {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  position: relative;
  transform: translate(0, 0);
  box-shadow: none;
  transition: all 0.1s linear;
  // align-items: center;
  &:not(.is-mobile):hover {
    transform: translate(0, -2px);
    box-shadow: var(--shadow-3);
    .c-home-cell_left {
      img {
        transform: scale(1.1);
        transition: all 0.1s linear;
      }
    }
  }
  &:not(.is-mobile):active {
    transform: translate(0, 0);
    box-shadow: none;
    .c-home-cell_left {
      img {
        transform: scale(1);
        transition: all 0.05s linear;
      }
    }
  }
  &_badge {
    position: absolute;
    top: 4px;
    right: 4px;

    .c-save-tag {
      line-height: 18px;
    }
  }
  &.is-mobile {
    padding: 16px 0;

    .c-home-cell_badge {
      top: 0;
    }

    .c-save-tag {
      padding-top: 0;
      padding-bottom: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &_left {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: scale(1);
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 0.1s linear;
    }
  }
  &-right {
    flex: 1;
    margin-left: 12px;
    min-width: 0;
    align-self: center;
    &.is-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &_title {
      color: var(--el-gf1);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 16px;
      line-height: 20px;
      color: @label_primary_1;
      font-weight: 500;
    }
    &_comment {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: var(--nc-g5);
      line-height: 21px;
      margin-right: 8px;
      &-score {
        display: inline-block;
        padding: 0 4px;
        min-width: 21px;
        text-align: center;
        border-radius: var(--radius-2);
        background: var(--el-bc1);
        color: var(--al-gal4);
        margin-right: 2px;
        white-space: nowrap;
      }
      &-rate {
        margin-right: 8px;
      }
      &-total {
        line-height: 20px;
        color: var(--el-gf2);
        span {
          margin-right: 2px;
        }
      }
    }

    :deep(&_price) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 6px;
      color: var(--el-bc2);

      .cell-symbol {
        align-self: flex-end;
        margin-bottom: 1px;
      }

      .cell-space {
        margin: 0 4px;
      }
    }

    &_remark {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: var(--el-gf3);
      padding: 2px 0;
    }
    &_bookmark span {
      display: inline-block;
      padding: 0 2px;
      background: linear-gradient(90deg, rgba(#ffcc32, 0.1) 0%, rgba(#ffcc32, 0));
      color: var(--fu-orange1);
    }
    &_tag {
      display: flex;
      flex-wrap: wrap;
      .tag-list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 6px;
        border-radius: 2px;
        background: var(--bg-bg2);
        color: rgba(31, 33, 40, 0.60);
        font-size: 12px;
        margin-top: 2px;
        margin-right: 8px;
        line-height: 22px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &-label {
    // color: @label_light_1;
    position: absolute;
    top: 22px;
    transform: translateY(-50%);
  }
  &.is-loading & {
    &_left {
      .skeleton-loading-bg
      }
    &-right_title {
      .skeleton-loading-bg;
      width: 50%;
      height: 24px;
    }
    &-right_content {
      .skeleton-loading-bg;
      width: 90%;
      height: 16px;
      margin-top: 4px;
    }
    &-right_remark {
      .skeleton-loading-bg;
      width: 30%;
      height: 16px;
      margin-top: 4px;
    }
  }

}
</style>
