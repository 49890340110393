import { mapGetters } from 'vuex';

import { ProfileGuideEnum, TextTypeHreflangMap } from '@/enums';
import bus from '@/utils/bus';

let $loadingToast = null;
const mixin = {
  computed: {
    ...mapGetters(['gameType', 'textType']),
  },
  methods: {
    async $_waitCompleteProfiles(profileGuideArr) {
      for (let i = 0; i < profileGuideArr.length; i += 1) {
        // eslint-disable-next-line
        await this.$_waitCompleteProfile(profileGuideArr[i]);
      }
    },
    async $_waitCompleteProfile(profileGuide) {
      if (this.$_isCompleteProfileNeeded(profileGuide)) {
        await this.$guide(profileGuide);
      }
    },
    $_isCompleteProfileNeeded(profileGuide) {
      const { loginStatus, userInfo } = this.$store.state.Account;
      switch (profileGuide) {
        case ProfileGuideEnum.signin:
          return loginStatus !== 2;
        case ProfileGuideEnum.email:
          return !userInfo.email;
        case ProfileGuideEnum.tradeUrl:
          return !userInfo.trade_url;
        case ProfileGuideEnum.apikey:
          return userInfo.steam_api_key_state !== 2;
        case ProfileGuideEnum.cannotTrade:
          return userInfo.trade_url_state !== 0;
        default:
          throw new Error('Unknown Profile Guide');
      }
    },
    $_handleError(err) {
      console.log(err);
      const { type, data } = err;
      if (type === 'net_error') {
        this.$Message.error(data.message || data.msg);
      } else if (type === 'paramater_error') {
        this.$Message.error(this.$t('global.paramater_error_tips'));
      } else if (type === 'data_error') {
        this.$Message.error(data.error || data.msg);
      } else if (type === 'verify_error') {
        this.$verify();
      }
    },
    // 全局加载loading
    $_loadingToast(text) {
      if ($loadingToast) {
        $loadingToast.show = false;
        $loadingToast = null;
      }
      if (text) {
        $loadingToast = this.$Message.loading(text);
      }
    },
    $_handlePay(payParams = {}, { goodsId, goodsName, goodsImg } = {}) {
      // 具体购买发起支付流程
      return this.$store.dispatch('postMarketBuy', payParams).then((res) => {
        // 购买成功提醒
        this.$dialog({
          title: this.$t(`buy.success_tips.header.${this.gameType}`),
          content: this.$t(`buy.success_tips.content.${this.gameType}`),
          confirmText: this.$t('shareDialog.share_btn'),
          cancelText: this.$t('global.ok'),
          onConfirm: () => {
            const logPrefix = 'page_list_share_';
            this.$logger(`${logPrefix}click`);
            this.$share({
              id: 6,
              img: goodsImg,
              url: `https://${window.location.host}/market/goods/${goodsId}`,
              extraParams: { goods_id: goodsId },
              message: this.$t('shareDialog.buy_success', { goodsName }),
              logPrefix,
            });
          },
        });
        return res;
      }, (err) => {
        // console.log('支付失败');
        this.$_handleError(err);
        return Promise.reject(err);
      }).finally(() => {
        // 更新个人信息
        this.$store.dispatch('fetchUserInfo');
      });
    },
    $_handleAfterFeedback() {
      const name = this.$store.state.game
        ? 'Market.Account.Feedback'
        : 'Account.Feedback';
      // app收银台进入使用replace
      const method = this.$route.name === 'App.Buy'
        ? 'replace' : 'push';
      this.$router[method]({ name });
      bus.$emit('feedback.submit');
    },
    /**
     * 触发安装提示（如果不满足触发条件则不触发）
     * @param {Function} cb 触发安装提示前执行的回调
     *   如果 cb 为函数，将触发安装提示方法作为参数传递给 cb，让 cb 自己决定触发时机
     *   如果 cb 不为函数，则立即触发安装提示
     */
    $_handleInstall(cb) {
      const { isInStandalone } = this.$store.state;
      const { deferredInstallPrompt } = this.$store.state;
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      // standalone 内不再显示安装提示
      // 安卓内如果没有捕获到 deferred Prompt 不显示安装提示
      if (isInStandalone || (!isIOS && !deferredInstallPrompt)) return;

      const promptFn = async () => {
        if (isIOS) this.$guide(ProfileGuideEnum.addBookmarkSafari);
        else {
          deferredInstallPrompt.prompt();
          const { outcome } = await deferredInstallPrompt.userChoice;
          if (outcome === 'accepted') {
            this.$store.commit('SET_DEFERRED_INSTALL_PROMPT', null);
          }
        }
      };

      if (!cb || typeof cb !== 'function') promptFn();
      else cb(promptFn);
    },
    $_localePath(route) {
      const _route = this.$router.resolve(route).route;
      const hreflang = TextTypeHreflangMap[this.textType];
      const { params, ...other } = _route;
      const _params = { ...params, hreflang };
      return { ...other, params: _params };
    },
  },
};

const Mixins = {};

Mixins.install = (VueInstance) => {
  VueInstance.mixin(mixin);
};

export default Mixins;
