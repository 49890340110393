<template>
  <w-dialog v-if="!isMobile"
    :show="show"
    :title="title"
    :width="750"
    :need-cancel-btn="false"
    :need-confirm-btn="false"
    @close="handleClose">
    <div class="p-tutorial-dialog">
      <component :is="tutorial" v-if="loading || tutorial" :content="content" />
      <div v-else v-html="doc"></div>
    </div>
  </w-dialog>
  <w-drawer v-else
    :show="show"
    :title="title"
    @close="handleClose">
    <div class="p-tutorial-drawer">
      <component :is="tutorial" v-if="loading || tutorial" :content="content" />
      <div v-else v-html="doc"></div>
    </div>
  </w-drawer>
</template>

<script>
import { isMobile } from '@/utils';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: String,
    content: String,
  },
  data() {
    return {
      show: false,
      isMobile: isMobile(),
      loading: false,
      tutorial: null,
      doc: '',
    };
  },
  mounted() {
    this.loadTutorial().then(() => {
      if (this.tutorial || this.doc) {
        this.show = true;
        return;
      }
      this.handleClose();
    });
  },
  methods: {
    handleClose() {
      this.show = false;
      this.$emit('close');
    },
    loadTutorial() {
      this.loading = true;
      return import(`./Tutorials/${this.id}.vue`).then((component) => {
        this.tutorial = component.default;
        const promises = (component.prefetchList || [])
          .map((key) => this.$et(key, true));
        return Promise.all(promises);
      }).catch(() => this.$et(this.id, true).then((doc) => {
        this.doc = doc;
      })).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.p-tutorial {
  &-dialog {
    min-height: 400px;
  }
  &-drawer {
    max-height: 400px;
    overflow-y: auto;
    padding: 16px;
  }
}
</style>
