<template>
  <w-dialog
    :title="title"
    :tips="tips"
    :show="show"
    :width="width"
    :max-height="maxHeight"
    :need-close-icon="needCloseIcon"
    :need-confirm-btn="needConfirmBtn"
    :need-cancel-btn="needCancelBtn"
    :confirm-text="confirmText"
    :cancel-text="cancelText"
    :confirm-type="confirmType"
    :loading="loading"
    :countdown="countdown"
    :theme="theme"
    @confirm="handleConfirm"
    @cancel="handleCancel"
    @close="handleClose">
    <div class="p-dialog-content">
      <p v-html="content"></p>
    </div>
  </w-dialog>
</template>

<script>
import { DialogState } from '@/enums';

export default {
  props: {
    show: Boolean,
    title: String,
    content: String,
    tips: String,
    theme: String,
    needCloseIcon: {
      type: Boolean,
      default: true,
    },
    needConfirmBtn: {
      type: Boolean,
      default: true,
    },
    needCancelBtn: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmType: {
      type: String,
      default: 'primary',
    },
    width: Number,
    maxHeight: Number,
    countdown: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,

      onConfirm: null,
      onCancel: null,
    };
  },
  methods: {
    handleClose(state = DialogState.close) {
      this.$emit('close', state);
    },
    async handleConfirm() {
      let remainDialog = false;
      if (this.onConfirm) {
        this.loading = true;
        try {
          remainDialog = await this.onConfirm();
        } catch (err) {
          remainDialog = true;
        }
        this.loading = false;
      }
      if (!remainDialog) this.handleClose(DialogState.confirm);
    },
    async handleCancel() {
      let remainDialog = false;
      if (this.onCancel) {
        this.loading = true;
        try {
          remainDialog = await this.onCancel();
        } catch (err) {
          remainDialog = true;
        }
        this.loading = false;
      }
      if (!remainDialog) this.handleClose(DialogState.cancel);
    },
  },
};
</script>

<style lang="less" scoped>
.p-dialog-content {
  min-height: 148px;
  :deep(a) {
    text-decoration: underline;
    color: @tint_primary_2;
    &:hover {
      color: @tint_primary_1;
    }
    &:active {
      color: @tint_primary_2;
    }
  }
}
</style>
