import padLeft from './pad-left';
import formatTime from './format-time';
import formatString from './format-string';
import formatPrice from './format-price';
import formatPriceInt from './format-price-int';
import formatPriceDec from './format-price-dec';
import formatPriceSeparator from './format-price-separator';
import formatUsd from './format-usd';
import formatUsdInt from './format-usd-int';
import formatUsdDec from './format-usd-dec';
import formatSaleVolume from './format-sale-volume';
import capitalizeFirstLetter from './capitalize-first-letter';

const filters = {
  padLeft,
  formatTime,
  formatString,
  formatPrice,
  formatPriceInt,
  formatPriceDec,
  formatPriceSeparator,
  formatUsd,
  formatUsdInt,
  formatUsdDec,
  formatSaleVolume,
  capitalizeFirstLetter,
};

const Filters = {};

Filters.install = (VueInstance) => {
  Object.keys(filters).forEach((key) => {
    VueInstance.filter(key, filters[key]);
  });
};

export default Filters;
