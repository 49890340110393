import goods from './goods';
import order from './order';
import popup from './popup';

const state = {
  ...goods.state,
  ...order.state,
  ...popup.state,
};

const getters = {
  ...goods.getters,
  ...order.getters,
  ...popup.getters,
};
const mutations = {
  ...goods.mutations,
  ...order.mutations,
  ...popup.mutations,
};
const actions = {
  ...goods.actions,
  ...order.actions,
  ...popup.actions,
};

const module = {
  state, getters, mutations, actions,
};

export default module;
