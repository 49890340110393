import store from '@/store';

import creator from './creator';

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$verify = creator(store);
};

export default plugin;
