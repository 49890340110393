import { getUnitPrice } from '@/utils';
import { multiply } from '@/utils/math';
import store from '../store';

export default function formatPriceInt(price) {
  let _price = parseFloat(price, 10);
  const rate = store.getters.currencyRate;
  const precision = store.getters.currencyPrecision;
  const separator = store.getters.currencySeparator || ',';
  _price *= rate;
  let strPrice = (Math.ceil(multiply(_price, 10 ** precision))
    / (10 ** precision)).toFixed(precision);
  const zeroPrice = (0).toFixed(precision);
  if (Number(price) > 0 && strPrice === zeroPrice) strPrice = getUnitPrice(precision);
  else if (Number.isNaN(_price)) strPrice = zeroPrice;
  const splitPrice = strPrice.replace(/\d(?=(\d{3})+(\.|$))/g, '$&,').split('.');
  return splitPrice[0].replaceAll(',', separator);
}
