// 解析推送中的 click_action
export const extractSchema = (schema) => {
  const matched = schema.match(/^gearup-wagon:\/\/(.*)$/);
  if (!matched) return null;
  const scheme = matched[1];
  const [method, query] = scheme.split('?');
  const payload = query ? query.split('&').reduce((o, str) => {
    const [key, val] = str.split('=');
    if (val === undefined) o[key] = true;
    else o[key] = val;
    return o;
  }, {}) : {};
  return { method, payload };
};

// 根据 method 和 payload 返回默认的路由跳转地址
export const MethodRouteMap = {
  go_buy_order_detail: ({ bill_order_id: orderId }) => ({
    name: 'Account.Buy.Order',
    params: { orderId },
  }),
  go_message_center: (_, userType) => {
    if (!userType || userType !== 2) {
      return { name: 'Messages.Chat' };
    }
    return { name: 'Seller.Messages' };
  },
  go_feedback_record_page: () => ({
    name: 'Account.Feedback',
  }),
  go_im_page: ({ to_user_id: toId, bill_order_id: orderId }) => ({
    name: 'Messages.Chat.Conversation',
    params: { type: 1, toId },
    query: { bill_order_id: orderId },
  }),
  go_submit_info_page: ({ bill_order_id: orderId }) => ({
    name: 'Account.Buy.Order',
    params: { orderId },
  }),
};

// 如果页面需要特殊处理某些点击通知事件
// 可以把对应的method添加到这个Set里面
// 防止App.common.mixin.js里面触发默认的路由跳转
export const preventDefaultMethods = new Set();
