import Axios from '@/utils/axios.ext';
import api from '@/api';
import LocalStorage from '@/utils/local-storage';
import { ConversationType, LoginStatus } from '@/enums';

function formatP2PConversation(conversation, uid) {
  const {
    conversation_users: users,
    conversation_id: id,
    conversation_type: type,
    last_msg: lastMsg,
  } = conversation;
  let user;
  let self;
  let vistorToken;
  let lang;
  if (uid) {
    user = users.find((item) => item.uid !== uid);
    self = users.find((item) => item.uid === uid);
    users.forEach((item) => {
      if (item.uid !== uid) {
        vistorToken = item.vistor_token;
        item.vipRank = item.vip_rank ? `VIP${item.vip_rank}` : '';
      } else {
        lang = item.target_lang;
      }
    });
  } else {
    user = users.find((item) => item.user_type !== ConversationType.GROUP);
    self = users.find((item) => item.user_type === ConversationType.GROUP);
    users.forEach((item) => {
      if (item.user_type !== ConversationType.GROUP) {
        vistorToken = item.vistor_token;
      } else {
        lang = item.target_lang;
      }
    });
  }
  return {
    id,
    type,
    toId: type === 2 && vistorToken ? vistorToken : user.uid,
    unread: self?.unread_count,
    avatar: user.profile_pic,
    title: user.nickname,
    users,
    lastMsg,
    self,
    vistorToken,
    lang,
  };
}

const state = {
  /** conversation
   * @param {String} conversation.id 会话id
   * @param {Number} conversation.type 会话类型
   * @param {String} conversation.toId 跳转会话页面id
   * @param {Number} conversation.unread 未读消息数量
   * @param {String} conversation.avatar 会话头像
   * @param {String} conversation.title 会话标题
   * @param {Number} conversation.lastMsg 最后一条消息
   */
  conversations: [],
  // 索引：toId，值：会话
  conversationsMap: {},
  // 索引：toId, 值：发送中的图片数组
  conversationsSendingImages: {},
  sightseerToken: LocalStorage.get('sightseer-token'), // 游客token
  sightseerInfo: {}, // 游客信息
  sightseerMsgNum: {
    uid: '',
    num: 0,
  }, // 游客未读消息数
  isMergeSightseer: false, // 是否合并游客信息
};

const getters = {
  conversations: (_state) => _state.conversations.map((toId) => _state.conversationsMap[toId]),
  conversationsSendingImages: (_state) => _state.conversationsSendingImages,
  sightseerToken: (_state) => _state.sightseerToken,
  sightseerInfo: (_state) => _state.sightseerInfo,
  sightseerMsgNum: (_state) => _state.sightseerMsgNum,
  isMergeSightseer: (_state) => _state.isMergeSightseer,
};

const mutations = {
  SET_IS_MERGE_SIGHTSEER(_state, isMerge) {
    _state.isMergeSightseer = isMerge;
  },
  SET_SIGHTSEER_TOKEN(_state, sightseerToken) {
    _state.sightseerToken = sightseerToken;
  },
  SET_SIGHTSEER_INFO(_state, sightseerInfo) {
    _state.sightseerInfo = sightseerInfo;
  },
  SET_SIGHTSEER_MSG_NUM(_state, sightseerMsgNum) {
    _state.sightseerMsgNum = sightseerMsgNum;
  },
  SET_IM_CONVERSATIONS(_state, conversations) {
    _state.conversations = _state.conversations
      .filter((toId) => !_state.conversationsMap[toId].lastMsg) // 保留空会话
      .filter((toId) => {
        const matched = conversations.find((conversation) => conversation.toId === toId);
        return !matched;
      })
      .concat(conversations.map((conversation) => conversation.toId));
    conversations.forEach((conversation) => {
      _state.conversationsMap[conversation.toId] = conversation;
    });
  },
  UPDATE_NEW_IM_CONVERSATIONS(_state, conversations) {
    const prevConversations = _state.conversations.slice();
    if (!conversations.length) return;
    conversations.forEach((conversation) => {
      const index = prevConversations.findIndex((toId) => toId === conversation.toId);
      if (index !== -1) prevConversations.splice(index, 1);
      _state.conversationsMap[conversation.toId] = conversation;
    });
    const toIds = conversations.map((conversation) => conversation.toId);
    _state.conversations = toIds.concat(prevConversations);
  },
  APPEND_OLD_IM_CONVERSATIONS(_state, conversations) {
    const prevConversations = _state.conversations.slice();
    conversations.forEach((conversation) => {
      const index = prevConversations.findIndex((toId) => toId === conversation.toId);
      if (index !== -1) prevConversations.splice(index, 1);
      _state.conversationsMap[conversation.toId] = conversation;
    });
    const toIds = conversations.map((conversation) => conversation.toId);
    _state.conversations = prevConversations.concat(toIds);
  },
  UPDATE_IM_CONVERSATION(_state, conversation) {
    _state.conversationsMap = { ..._state.conversationsMap, [conversation.toId]: conversation };
    // 空会话需要添加到顶部
    if (!conversation.lastMsg) {
      const index = _state.conversations.findIndex((toId) => toId === conversation.toId);
      if (index !== -1) _state.conversations.splice(index, 1);
      _state.conversations = [conversation.toId].concat(_state.conversations);
    }
  },
  REMOVE_IM_SIGHTSEER_CONVERSATION(_state, info) {
    const { toId } = info;
    _state.conversations.forEach((item, index) => {
      if (item === toId) {
        _state.conversations.splice(index + 1, 1);
      }
    });
  },
  ADD_IM_CONVERSATION_SENDING_IMAGE_INFO(_state, info) {
    const { toId } = info;
    const sendingImages = _state.conversationsSendingImages[toId] || [];
    sendingImages.push(info);
    _state.conversationsSendingImages = {
      ..._state.conversationsSendingImages,
      [toId]: sendingImages,
    };
  },
  REMOVE_IM_CONVERSATION_SENDING_IMAGE_INFO(_state, info) {
    const { toId } = info;
    const sendingImages = _state.conversationsSendingImages[toId] || [];
    const index = sendingImages.findIndex((item) => item === info);
    if (index !== -1) sendingImages.splice(index, 1);
    _state.conversationsSendingImages = {
      ..._state.conversationsSendingImages,
      [toId]: sendingImages,
    };
  },
  CLEAR_IM_CONVERSATIONS(_state) {
    _state.conversations = [];
    _state.conversationsMap = {};
    _state.conversationsSendingImages = {};
  },
};

const actions = {
  getFaqList(_, { url }) {
    return Axios({
      method: 'get',
      params: {
        url,
      },
      url: api.getFaqList(),
    }).then((resp) => resp.data);
  },
  postFaqReview(_, { id, like }) {
    return Axios({
      method: 'post',
      data: {
        question_id: id,
        like,
      },
      url: api.postFaqReview(),
    }).then((resp) => resp.data);
  },
  postImAckMsgRead({ dispatch }, { msgId }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'post',
      url: api.postImAckMsgRead(),
      data: {
        msg_id: msgId,
        ...data,
      },
    }).then((resp) => {
      const isLogined = this.getters.loginStatus === LoginStatus.logined;
      if (isLogined) dispatch('fetchMessageNotification');
      else dispatch('getSightseerMsg');
      return resp.data;
    });
  },
  getSightseerMsg() {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'get',
      url: api.getSightseerMsg(),
      params: data,
      metadata: { ignore_log: true },
    }).then((resp) => resp.data);
  },
  postSetLanguage(_, {
    to,
    language,
    conversationType,
  }) {
    return Axios({
      method: 'post',
      url: api.postSetLanguage(),
      data: {
        to,
        conversation_type: conversationType,
        language,
      },
    }).then((resp) => resp.data);
  },
  postImSendMsg(_, {
    to,
    msgType,
    conversationType,
    body,
  }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'post',
      url: api.postImSendMsg(),
      data: {
        to,
        msg_type: msgType,
        conversation_type: conversationType,
        body,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  postImTransMsgBody(_, {
    conversationType, to, msgType, body, translateMethod, lang,
  }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'post',
      url: api.postImTransMsgBody(),
      data: {
        conversation_type: conversationType,
        to,
        msg_type: msgType,
        target_lang: lang,
        body,
        trans_method: translateMethod,
        ...data,
      },
      timeout: 60 * 1e3,
    }).then((resp) => resp.data);
  },
  postImTransFail(_, { ids }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'post',
      url: api.postImTransFail(),
      data: {
        msg_ids: ids,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  postImRetryTranslate(_, { id, translateMethod }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'post',
      url: api.postImRetryTranslate(),
      data: {
        msg_id: id,
        trans_method: translateMethod,
        ...data,
      },
    });
  },
  postImVistorMerge({ commit }) {
    const data = {};
    if (!this.getters.sightseerToken) return Promise.resolve();
    data.vistor_token = this.getters.sightseerToken;
    commit('SET_SIGHTSEER_TOKEN', '');
    return Axios({
      method: 'post',
      url: api.postImVistorMerge(),
      data: {
        ...data,
      },
    }).then((resp) => {
      commit('SET_IS_MERGE_SIGHTSEER', true);
      return resp.data;
    });
  },
  getImAskTemplate() {
    return Axios({
      method: 'get',
      url: api.getImAskTemplate(),
    }).then((resp) => resp.data);
  },
  getImMsgList({ state: _state, dispatch }, {
    conversationType, to, begintime, endtime, limit, desc,
  }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'get',
      url: api.getImMsgList(),
      params: {
        conversation_type: conversationType,
        to,
        begintime,
        endtime,
        limit,
        desc,
        ...data,
      },
      metadata: { ignore_log: true },
    }).then((resp) => {
      // 匹配获取到的消息，删除对应msgId发送中的图片
      const { msgs } = resp.data;
      const images = _state.conversationsSendingImages[to] || [];
      if (images.length) {
        msgs.forEach((msg) => {
          const image = images.find((item) => item.msgId === msg.msg_id);
          if (image) dispatch('removeConversationSendingImageInfo', image);
        });
      }
      return resp.data;
    });
  },
  getImMsgInfo(_, { ids }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'get',
      url: api.getImMsgInfo(),
      params: {
        msg_ids: ids.join(','),
        ...data,
      },
    }).then((resp) => resp.data);
  },
  getImSearchMsgList(_, {
    conversationId, searchKeyword, endtime, limit,
  }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'get',
      url: api.getImSearchMsgList(),
      params: {
        conversation_id: conversationId,
        search_keyword: searchKeyword,
        endtime,
        limit,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  getImConversationInfo({ rootState }, { conversationType, to }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'get',
      url: api.getImConversationInfo(),
      params: {
        conversation_type: conversationType,
        to,
        ...data,
      },
    }).then((resp) => {
      const conversation = resp.data.conversation_info;
      // if (conversation.conversation_type === ConversationType.P2P) {
      //   return formatP2PConversation(conversation, rootState.Account.userInfo.uid);
      // }
      return formatP2PConversation(conversation, rootState?.Account?.userInfo?.uid);
      // return null;
    });
  },
  getImConversationList({ commit, rootState }, {
    begintime, endtime, limit, desc,
  }) {
    const data = {};
    if (this.getters.loginStatus !== LoginStatus.logined) {
      data.vistor_token = this.getters.sightseerToken;
    }
    return Axios({
      method: 'get',
      url: api.getImConversationList(),
      sightseer: true,
      params: {
        begintime,
        endtime,
        limit,
        desc,
        ...data,
      },
      metadata: { ignore_log: true },
    }).then((resp) => {
      const conversations = resp.data.conversation_infos
        .map((conversation) => {
          // if (conversation.conversation_type === ConversationType.P2P) {
          //   return formatP2PConversation(conversation, rootState.Account.userInfo.uid);
          // }
          const info = formatP2PConversation(conversation, rootState?.Account?.userInfo?.uid);
          commit('SET_SIGHTSEER_INFO', info.self);
          return formatP2PConversation(conversation, rootState?.Account?.userInfo?.uid);
          // return null;
        })
        .filter((item) => !!item);
      return conversations;
    });
  },
  getImSearchConversationList({ rootState }, {
    nickname,
  }) {
    return Axios({
      method: 'get',
      url: api.getImSearchConversationList(),
      params: {
        nickname,
      },
    }).then((resp) => {
      const conversations = resp.data.conversation_infos
        .map((conversation) => {
          if (conversation.conversation_type === ConversationType.P2P) {
            return formatP2PConversation(conversation, rootState.Account.userInfo.uid);
          }
          return null;
        })
        .filter((item) => !!item);
      return conversations;
    });
  },
  initConversationList({ commit, dispatch }, { limit }) {
    return dispatch('getImConversationList', {
      limit,
    }).then((conversations) => {
      commit('SET_IM_CONVERSATIONS', conversations);
      return conversations;
    });
  },
  fetchOlderConversationList({ commit, dispatch, state: _state }, { limit }) {
    const len = _state.conversations.length;
    let selected;
    for (let i = 0; i < len; i += 1) {
      const conversation = _state.conversationsMap[_state.conversations[len - 1 - i]];
      if (conversation.lastMsg) { selected = conversation; break; }
    }
    if (!selected) return dispatch('initConversationList', { limit });
    const endtime = selected.lastMsg.send_time;
    return dispatch('getImConversationList', {
      endtime,
      limit,
    }).then((conversations) => {
      commit('APPEND_OLD_IM_CONVERSATIONS', conversations);
      return conversations;
    });
  },
  fetchNewerConversationList({ commit, dispatch, state: _state }, { limit } = {}) {
    const len = _state.conversations.length;
    let selected;
    for (let i = 0; i < len; i += 1) {
      const conversation = _state.conversationsMap[_state.conversations[i]];
      if (conversation.lastMsg) { selected = conversation; break; }
    }
    if (!selected) return dispatch('initConversationList', { limit });
    const begintime = selected.lastMsg.send_time;
    return dispatch('getImConversationList', {
      begintime,
    }).then((conversations) => {
      commit('UPDATE_NEW_IM_CONVERSATIONS', conversations);
      return conversations;
    });
  },
  updateConversation({ commit, dispatch }, { conversationType, to }) {
    return dispatch('getImConversationInfo', {
      conversationType, to,
    }).then((conversation) => {
      commit('UPDATE_IM_CONVERSATION', conversation);
      return conversation;
    });
  },
  addConversationSendingImageInfo({ commit }, info) {
    commit('ADD_IM_CONVERSATION_SENDING_IMAGE_INFO', info);
  },
  removeConversationSendingImageInfo({ commit }, info) {
    commit('REMOVE_IM_CONVERSATION_SENDING_IMAGE_INFO', info);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
