// eslint-disable-next-line
export const retry = (fn, times = 5, delay = 1e3) => {
  return new Promise((resolve, reject) => {
    const attempt = () => {
      fn().then(resolve).catch((error) => {
        if (times === 0) {
          reject(error);
        } else {
          times -= 1;
          setTimeout(() => { attempt(); }, delay);
        }
      });
    };
    attempt();
  });
};

export const asyncLoadComponent = (fn) => retry(fn).then((module) => module.default);
