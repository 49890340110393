import Axios from '@/utils/axios.ext';
import SessionStorage from '@/utils/session-storage';
import api from '@/api';

const state = {
  regCouponTip: '',
};

const getters = {
  regCouponTip: (_state) => _state.regCouponTip,
};
const mutations = {
  SET_REG_COUPON_TIP(_state, regCouponTip) {
    _state.regCouponTip = regCouponTip;
  },
};
const actions = {
  /**
   * 我的优惠券列表
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   * @param {number} query_type 查询类型，取值范围： ['inuse', 'used', 'expired']
   * @param {number} sort_by 排序
   */
  getCoupons(_, {
    pageNum, pageSize, queryType, sortBy, ...other
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCoupons(),
      params: {
        page_num: pageNum,
        page_size: pageSize,
        query_type: queryType,
        sort_by: sortBy,
        ...other,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 兑换优惠券
   * @param {string} exchangeKey 兑换码
   */
  postCouponExchange(_, { exchangeKey }) {
    return Axios({
      method: 'post',
      url: api.postCouponExchange(),
      data: {
        exchange_key: exchangeKey,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 下单时选择优惠券
   * @param {number} game 游戏ID
   * @param {number} sell_order_id 出售订单ID
   */
  getOrderCoupons(_, {
    game, sellOrderId, signal, ...other
  } = {}) {
    const config = {
      method: 'get',
      url: api.getOrderCoupons(),
      params: {
        game,
        sell_order_id: sellOrderId,
        ...other,
      },
    };
    if (signal) config.signal = signal;
    return Axios(config).then((resp) => resp.data);
  },
  /**
   * 领取PWA安装优惠券
   */
  postGetPwaCoupon() {
    return Axios({
      method: 'post',
      url: api.postGetPwaCoupon(),
    }).then((resp) => resp.data);
  },
  /**
   * 领取游戏优惠券
   */
  postGetGameCoupon(_, { game }) {
    return Axios({
      method: 'post',
      url: api.postGetGameCoupon(),
      data: { game },
    }).then((resp) => resp.data);
  },
  getCouponBuy(_, {
    game, sellOrderId, num, couponCode, signal,
  }) {
    const config = {
      method: 'get',
      url: api.getCouponBuy(),
      params: {
        game,
        sell_order_id: sellOrderId,
        num,
        exchange_key: couponCode,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    };
    if (signal) config.signal = signal;
    return Axios(config).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
