import debug from './debug';
import dialog from './dialog';
import toast from './toast';
import registration from './registration';
import guide from './guide';
import password from './password';
import cashier from './cashier';
import buy from './buy';
import verify from './verify';
import feedback from './feedback';
import external from './external';
import loggerPlugin from './logger';
import share from './share';
import fcm from './fcm';
import meta from './meta';
import pushGuide from './pushguide';
import tutorial from './tutorial';
import i18n from './i18n';
import clipboard from './clipboard';

const Plugins = {};

Plugins.install = (VueInstance) => {
  VueInstance.use(debug);
  VueInstance.use(dialog);
  VueInstance.use(toast);
  VueInstance.use(registration);
  VueInstance.use(guide);
  VueInstance.use(password);
  VueInstance.use(cashier);
  VueInstance.use(buy);
  VueInstance.use(verify);
  VueInstance.use(feedback);
  VueInstance.use(external);
  VueInstance.use(loggerPlugin);
  VueInstance.use(share);
  VueInstance.use(fcm);
  VueInstance.use(meta);
  VueInstance.use(pushGuide);
  VueInstance.use(i18n);
  VueInstance.use(tutorial);
  VueInstance.use(clipboard);
};

export default Plugins;
