import Vue from 'vue';
import store from '@/store';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import desktopRouter from '@/router/desktop';
import mobileRouter from '@/router/mobile';
import { asyncLoadComponent } from '@/plugins/utils';

let Buy = null;

const BuyInstance = async (preview) => {
  if (!Buy) {
    try {
      Buy = await asyncLoadComponent(() => import('./buy.vue'));
    } catch (error) {
      return Promise.reject();
    }
  }
  const BuyCtor = Vue.extend(Buy);
  const inMobile = isMobile();
  const instance = new BuyCtor({
    el: document.createElement('div'),
    store,
    i18n,
    router: inMobile ? mobileRouter : desktopRouter,
    propsData: {
      isMobile: inMobile,
    },
    data: { preview },
  });
  /** @preview
   * discounted_price：String, 商品售价
   * original_price: String, 商品原价
   * pay_methods: Array
   * pay_methods[0]: Object, 用户钱包信息
   * pay_methods[0].balance: String, 用户钱包余额
   * payment_tips: String, 提示文案
   * pay_confirm: Object, 支付前二次确认弹窗, message内容是payment_tips
   * -------- 以上信息来源接口业务过来
   * game: String, 游戏ID
   * id: String, sell_order_id 出售订单id
   * infos: Array, 用户购买表单内容，多语言用于收银台展示商品
      infos[N]: Object, 商品信息，用于收银展示商品
        infos[N].label: String, 表单名称
        infos[N].value: String, 表单信息
   * extra: Object,用户购买表单内容，提交给服务端
      extra[NAME]: String, 表单名称
      extra[VALUE]: String, 表单信息
   * num: Number,用户购买数据
   * goods: Objec，商品信息，用于展示商品
   * goods_type: String, 商品类型 (目前用于埋点，不可信)
   * -------- 用户传递信息
  */
  instance.promise = new Promise((resolve, reject) => {
    instance.$on('close', (payload = {}) => {
      instance.$destroy();
      const { status, data = {} } = payload;
      if (status) {
        resolve(data);
      }
      reject(data);
    });
  });

  return instance.promise;
};
BuyInstance.install = (VueInstance) => {
  VueInstance.prototype.$buy = BuyInstance;
};

export default BuyInstance;
