import creator from './creator';

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$password = {
    set: creator('set'),
    reset: creator('reset'),
    register: creator('register'),
  };
};

export default plugin;
