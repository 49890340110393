import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['allAppList', 'hotAppList']),
  },
  methods: {
    handleResolveGameUrl(game) {
      const serviceType = game.category_list[0].service_type;
      let routeName = '';

      switch (serviceType) {
        case 'recharge':
          routeName = 'Topup';
          break;
        case 'cdkey':
          routeName = 'List';
          break;
        case 'new_account':
          routeName = 'List';
          break;
        case 'coin_trade':
          routeName = 'Coin';
          break;
        case 'item_trade':
          routeName = 'Gameitem';
          break;
        default:
          break;
      }

      const params = { game: game.name };
      if (routeName === 'List') {
        switch (serviceType) {
          case 'cdkey':
            params.goodsType = 'cd-key';
            break;
          case 'new_account':
            params.goodsType = 'new-account';
            break;
          default:
            break;
        }
      }

      if (game.agg_page) {
        routeName = 'GamePage';
        params.game = game.agg_name;
      }

      const route = this.$router.resolve(
        this.$_localePath({
          name: routeName,
          params,
        }),
      );

      return route.href;
    },
  },
};
