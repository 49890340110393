import axios from 'axios';

const instance = axios.create();

instance.defaults.timeout = 30 * 1e3;
// instance.defaults.withCredentials = true;

instance.interceptors.response.use((res) => {
  const { data } = res;
  // data.code 为兼容旧接口
  const code = data.status;
  // 网络成功状态下的请求结果判断
  if (data && code === 'ok') {
    return data;
  }
  const errorData = {
    type: 'data_error',
    data,
    config: res.config,
  };
  return Promise.reject(errorData);
}, (error = {}) => {
  const { data, status, config } = error.response || {};
  const dataErrorStatus = [401, 409, 422];
  let errorData;
  if (dataErrorStatus.indexOf(status) !== -1) {
    errorData = {
      type: 'data_error',
      data,
      config,
    };
  } else {
    errorData = {
      type: error.code === 'ERR_CANCELED' ? 'canceled' : 'net_error',
      data: error,
    };
  }
  return Promise.reject(errorData);
});

export default instance;
