import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {
  tournament: null,
  orderTags: {},
};

const getters = {

};
const mutations = {
  SET_TOURNAMENT(_state, tournament) {
    const map = new Map();
    tournament.proplayer.forEach((item) => {
      map.set(item.value, item);
    });
    tournament.hot_players = tournament.hot_players.map((item) => map.get(item));
    _state.tournament = tournament;
  },
  SET_ORDER_TAGS(_state, { category, tags }) {
    _state[category] = tags;
  },
};
const actions = {
  /**
   * 获取市场商品详情
   * @param {GameType} game 游戏类型
   * @param {number} appId 商品id
   */
  getMarketItemDetail({ rootState }, params) {
    return Axios({
      method: 'get',
      url: api.getMarketItemDetail(),
      params: {
        game: rootState.game,
        ...params,
      },
    }).then((resp) => resp.data);
  },

  /* CSGO */
  /**
   * 获取市场货品箱子
   * @param {string} goodsId 货品id
   */
  getMarketGoodsContainer(_, { goodsId } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsContainer(),
      params: { goods_id: goodsId },
    }).then((resp) => resp.data);
  },

  /* CSGO */
  /**
   * 获取市场货品箱子详情
   * @param {string} container
   * @param {string} isContainer 是否为箱子
   * @param {string} containerType 箱子类型
   * @param {string} unusualOnly 是否返回特殊物品
   */
  getMarketContainer(_, {
    container,
    isContainer,
    containerType,
    unusualOnly,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketContainer(),
      params: {
        container,
        is_container: isContainer,
        container_type: containerType,
        unusual_only: unusualOnly,
      },
    }).then((resp) => resp.data);
  },
  /* CSGO */
  /**
   * 查询是否收藏
   * @param {Number} target_type 1: 文章 2: 商品 3: 出售订单（饰品关注）
   * @param {string} target_id 订单id sell_order_id
   */
  checkBookmark(_, { type, id, signal }) {
    const config = {
      method: 'get',
      url: api.checkBookmark(),
      params: {
        target_type: type,
        target_id: id,
      },
    };
    if (signal) config.signal = signal;
    return Axios(config).then((resp) => resp.data);
  },
  /**
   * 查询游戏中检视链接是否正确
   * @param {string} link 检视链接
   */
  // checkTryInGameLink(_, { link }) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getTryInGameLink(link),
  //   });
  // },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
