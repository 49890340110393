export default class ProfileErrorHandler {
  constructor(guidePopupCtor) {
    this.guidePopupCtor = guidePopupCtor; // 个人信息引导弹窗构造器

    this.current = null; // 当前显示中的引导弹窗
    this.typeQueue = []; // 当前&等待中的引导弹窗队列
    this.promiseQueue = []; // 对应的promise队列
  }

  run() {
    if (!this.typeQueue.length) {
      this.current = null;
      return;
    }
    const [type] = this.typeQueue;
    const [{ resolve, reject }] = this.promiseQueue;
    this.current = this.guidePopupCtor(type).then(resolve)
      .catch(reject).finally(() => {
        this.typeQueue.shift();
        this.promiseQueue.shift();
        this.run();
      });
  }

  handle(guideType) {
    let promise;
    // 当前不存在该类型的引导
    if (this.typeQueue.indexOf(guideType) === -1) {
      this.typeQueue.push(guideType);
      const obj = {};
      promise = new Promise((resolve, reject) => {
        obj.resolve = resolve;
        obj.reject = reject;
      });
      this.promiseQueue.push({ promise, ...obj });
    } else {
      const index = this.typeQueue.indexOf(guideType);
      promise = this.promiseQueue[index].promise;
    }
    if (!this.current) {
      this.run();
    }
    return promise;
  }
}
