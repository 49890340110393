import store from '../store';

export default function formatPriceSeparator(price) {
  const separator = store.getters.currencySeparator || ',';

  const splitPrice = price
    .toString()
    .replace(/\d(?=(\d{3})+(\.|$))/g, '$&,')
    .split('.');
  return splitPrice[0].replaceAll(',', separator);
}
