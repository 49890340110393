import directiveTooltips from './tooltips';
import directiveLazy from './lazy';

const directive = {};

directive.install = (VueInstance) => {
  VueInstance.directive('tooltips', directiveTooltips);
  VueInstance.directive('lazy', directiveLazy);
};

export default directive;
