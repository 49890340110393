<template>
  <div
    class="c-autocomplete"
    :class="{'is-transparent':transparent}">
    <w-input
      ref="input"
      class="c-autocomplete-input"
      :value="value"
      :placeholder="placeholder"
      :clearable="clearable"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @clear="handleClear">
      <slot slot="prefix" name="prefix"></slot>
    </w-input>
    <AutocompleteDropdown
      v-if="$route.name!=='Search'"
      v-show="!error && active && keyword"
      ref="popper"
      class="autocomplete-drop"
      style="width: 375px"
      :offset="[0, 8]"
      :z-index="zIndex"
      @mousedown.prevent>
      <w-scrollbar style="height: 550px" @scroll="handleScroll">
        <SearchResult ref="search" is-small />
      </w-scrollbar>
    </AutocompleteDropdown>
  </div>
</template>

<script>
import debounce from '@/utils/debounce';
import bus from '@/utils/bus';

import AutocompleteDropdown from '@/components/Autocomplete/AutocompleteDropdown.vue';
import SearchResult from '@/components/Nav/components/SearchResultList.vue';
// 引入搜索类
// import Search from './search';

// const search = new Search();

// let timer = null;
export default {
  components: {
    AutocompleteDropdown,
    SearchResult,
  },
  props: {
    zIndex: Number,
    transparent: Boolean,
    placeholder: String,
    clearable: Boolean,
    suggestions: Array,
    delay: Number, // 延迟展示dropdown
    error: Boolean,
    value: [String, Number],
  },
  data() {
    return {
      focusing: false,
      active: false,
      keyword: '',
    };
  },
  created() {
    this.input = debounce(this.handleInput, 300);
  },
  methods: {
    handleCloseDropdown() {
      this.active = false;
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    handleFocus() {
      this.focusing = true;
      this.$emit('focus');
      window.setTimeout(() => {
        if (!this.focusing) return;
        this.active = true;
        this.$nextTick(() => {
          const { popper } = this.$refs;
          if (popper) popper.updatePopper();
        });
      }, this.delay);
    },
    handleBlur() {
      this.focusing = false;
      this.handleCloseDropdown();
      this.$emit('blur');
    },
    handleClear(e) {
      this.$emit('clear', e);
    },
    async handleInput(val) {
      this.$emit('input', val);
      // 想组件发送搜索词的变更
      this.keyword = val;
      if (this.$route.name !== 'Search' && this.keyword !== '') bus.$emit('search.result', val);
    },
    handleAction(type, indexes) {
      this.$emit('action', { type, indexes });
    },
    handleSetValue(value) {
      this.blur();
      this.$emit('input', value);
      this.$emit('select', value);
    },
    handleScroll(e) {
      if (!this.$refs.search) return;
      this.$refs.search.handleScroll(e);
    },
  },
};
</script>

<style lang="less" scoped>
.c-autocomplete {
  display: inline-block;
  width: 280px;
  &.is-transparent &-input {
    background-color: fade(@tint_white, 5%);
    &:not(:focus-within, :hover) {
      border-color: transparent;
    }
    :deep(.w-input__prefix) {
      color: @label_light_4;
    }
    :deep(.w-input__clear) {
      color: @label_light_4;
      &:hover { opacity: 0.7; }
    }
    :deep(input) {
      color: @label_white;
      &::-webkit-input-placeholder { color: @label_light_4; }
      &:-moz-placeholder { color: @label_light_4; }
      &::-moz-placeholder { color: @label_light_4; }
      &:-ms-input-placeholder { color: @label_light_4; }
    }
  }
  &-input {
    width: 100%;
    height: 100%;
    font-size: inherit;
  }

}
.autocomplete-drop {
  background: @tint_white;
  max-height: 100%;
}
</style>
