import EventEmitter from '@/utils/event-emitter';

const UPDATE_TOKEN_DELAY = 60 * 60 * 1e3; // 1小时更新一次token

const STATUS = {
  UNINITED: 0,
  INITING: 1,
  INITED: 2,
  CLEARING: 3,
};

class FCM extends EventEmitter {
  constructor(conf = {}) {
    super();

    this.isSupported = 'serviceWorker' in navigator
      && 'PushManager' in window
      && 'Notification' in window;
    this.permission = this.isSupported ? Notification.permission : 'denied';

    this.conf = conf;

    this.status = STATUS.UNINITED;
    this.app = null;
    this.messaging = null;
    this.token = '';
    this.timer = null;

    this.vapidKey = process.env.VUE_APP_FCM_VAPID_KEY;
  }

  handleMessage(ev) {
    const { data, op } = ev.data;
    if (op === 'notification-clicked') {
      this.emit('notification-clicked', { data });
      console.log('notification-clicked', { data });
    } else if (op === 'background-message') {
      this.emit('background-message', { data });
      console.log('background-message', { data });
      this.emit('message', { data, type: 'background' });
      console.log('message', { data, type: 'background' });
    }
  }

  bindEvent() {
    navigator.serviceWorker.addEventListener('message', this.handleMessage.bind(this), false);
  }

  unbindEvent() {
    navigator.serviceWorker.removeEventListener('message', this.handleMessage.bind(this), false);
  }

  grantPermission() {
    if (this.permission !== 'default') {
      return Promise.resolve(this.permission);
    }
    console.log('Requesting permission...');
    return Notification.requestPermission().then((permission) => {
      console.log(permission, 'permission');
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
      this.permission = permission;
      return permission;
    });
  }

  getToken() {
    if (!this.isSupported) {
      return Promise.reject(new Error('Not supported'));
    }
    if (this.permission === 'denied') {
      return Promise.reject(new Error('Permission denied'));
    }
    if (!this.messaging) {
      return Promise.reject(new Error('Not initialized'));
    }
    return this.grantPermission()
      .then(() => import('firebase/messaging'))
      .then((firebaeMessaging) => firebaeMessaging.getToken(
        this.messaging, { vapidKey: this.vapidKey },
      ))
      .then((currentToken) => {
        if (currentToken) {
          console.log('currentToken', currentToken);
        } else {
          console.log('No registration token available. Request permission');
        }
        return currentToken;
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return Promise.reject(err);
      });
  }

  deleteToken() {
    if (!this.isSupported) {
      return Promise.reject(new Error('Not supported'));
    }
    if (!this.messaging) {
      return Promise.reject(new Error('Not initialized'));
    }
    return import('firebase/messaging')
      .then((firebaeMessaging) => firebaeMessaging.deleteToken(this.messaging))
      .then(() => {
        console.log('Token deleted.');
      }).catch((err) => {
        console.log('Unable to delete token. ', err);
        return Promise.reject(err);
      });
  }

  setUpdateTokenTimer() {
    this.timer = setInterval(() => {
      this.getToken().then((newToken) => {
        if (newToken !== this.token) {
          this.emit('updatetoken', newToken);
        }
      });
    }, UPDATE_TOKEN_DELAY);
  }

  clearUpdateTokenTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  async init() {
    if (this.status !== STATUS.UNINITED) return Promise.resolve();

    this.status = STATUS.INITING;

    if (!this.isSupported) {
      return Promise.reject(new Error('Not supported'));
    }
    if (this.permission === 'denied') {
      return Promise.reject(new Error('Permission denied'));
    }

    if (!this.app) {
      const [firebaseApp, firebaeMessaging] = await Promise.all([
        import('firebase/app'),
        import('firebase/messaging'),
      ]);
      this.app = firebaseApp.initializeApp(this.conf);
      this.messaging = firebaeMessaging.getMessaging(this.app);

      firebaeMessaging.onMessage(this.messaging, (payload) => {
        this.emit('foreground-message', { data: payload });
        console.log('foreground-message', { data: payload });
        this.emit('message', { data: payload, type: 'foreground' });
        console.log('message', { data: payload, type: 'foreground' });
      });
    }

    this.bindEvent();

    this.token = await this.getToken();

    this.emit('updatetoken', this.token);

    this.setUpdateTokenTimer();

    this.status = STATUS.INITED;

    return Promise.resolve();
  }

  clear() {
    this.unbindEvent();
    this.removeAllListeners();
    this.clearUpdateTokenTimer();
    this.token = '';
    this.status = STATUS.CLEARING;
    return this.deleteToken().finally(() => {
      this.status = STATUS.UNINITED;
    });
  }
}

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$fcm = new FCM({
    apiKey: process.env.VUE_APP_FCM_API_KEY,
    authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FCM_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FCM_APP_ID,
    measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID,
  });
};

export default plugin;
