import { DialogState } from '@/enums';

import creator from './creator';
import videoCreator from './videoCreator';

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$dialog = creator();
  VueInstance.prototype.$dialog.state = DialogState;

  VueInstance.prototype.$dialogVideo = videoCreator();
};

export default plugin;
