<template>
  <i class="c-avatar" :style="style"></i>
</template>

<script>
import AvatarDark from '@/assets/images/un_picture_topbar.png';
import AvatarLight from '@/assets/images/un_picture_white.png';

export default {
  inject: {
    theme: {
      default: 'light',
    },
  },
  props: {
    src: String,
    size: Number,
  },
  computed: {
    style() {
      const defaultAvatar = this.theme === 'light' ? AvatarLight : AvatarDark;
      const backgroundImage = this.src ? this.src : defaultAvatar;
      const size = this.size ? `${this.size}px` : '100%';
      return {
        width: size,
        height: size,
        backgroundImage: `url(${backgroundImage})`,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.c-avatar {
  display: inline-block;
  background: center/contain no-repeat;
}
</style>
