import { isObject } from '@/utils';

const map = new Map();

function lazyloadImage(el) {
  el.style.backgroundImage = `url(${el.dataset.src}`;
}

export default {
  inserted(el, binding) {
    let root;
    let cb = lazyloadImage;
    if (isObject(binding.value)) {
      ({ root, cb } = binding.value);
    } else {
      root = binding.value;
    }
    let observer;
    if (map.has(root)) {
      observer = map.get(root);
    } else {
      const options = {
        root,
        rootMargin: '0px',
        threshold: 0,
      };
      const callback = (entries, self) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cb(entry.target);
            self.unobserve(entry.target);
          }
        });
      };
      observer = new IntersectionObserver(callback, options);
      map.set(root, observer);
    }
    observer.observe(el);
  },
  unbind(el, binding) {
    const root = binding.value;
    if (!map.has(root)) return;
    const observer = map.get(root);
    observer.unobserve(el);
  },
};
