import Vue from 'vue';
import { i18n } from '@/utils/i18n';
import store from '@/store';
import { asyncLoadComponent } from '@/plugins/utils';

let PushGuide = null;

const PushGuideInstance = () => async () => {
  if (!PushGuide) {
    try {
      PushGuide = await asyncLoadComponent(() => import('./index.vue'));
    } catch (error) {
      return Promise.reject();
    }
  }
  const PushGuideCtor = Vue.extend(PushGuide);
  const instance = new PushGuideCtor({
    el: document.createElement('div'),
    i18n,
    store,
  });
  document.body.appendChild(instance.$el);
  document.body.style.overflow = 'hidden';
  const close = () => {
    instance.$destroy();
    document.body.removeChild(instance.$el);
    document.body.style.overflow = '';
  };

  instance.promise = new Promise((resolve) => {
    instance.$on('webpush.success', (state) => {
      resolve(state);
    });
    instance.$on('close', (state) => {
      close();
      resolve(state);
    });
  });
  return instance.promise;
};
export default PushGuideInstance;
