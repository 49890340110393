export function multiply(v1, v2) {
  let m = 0;
  const s1 = v1.toString();
  const s2 = v2.toString();

  try { m += s1.split('.')[1].length; } catch (e) { /* */ }
  try { m += s2.split('.')[1].length; } catch (e) { /* */ }

  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / (10 ** m);
}

export function add(v1, v2) {
  let n1;
  let n2;

  try { n1 = v1.toString().split('.')[1].length; } catch (e) { n1 = 0; }
  try { n2 = v2.toString().split('.')[1].length; } catch (e) { n2 = 0; }

  const m = 10 ** Math.max(n1, n2);
  return (multiply(v1, m) + multiply(v2, m)) / m;
}

export function subtract(v1, v2) {
  return add(v1, -v2);
}

export function divide(v1, v2) {
  let t1 = 0;
  let t2 = 0;
  const s1 = v1.toString();
  const s2 = v2.toString();

  try { t1 = s1.split('.')[1].length; } catch (e) { /* */ }
  try { t2 = s2.split('.')[1].length; } catch (e) { /* */ }

  const n1 = Number(s1.replace('.', ''));
  const n2 = Number(s2.replace('.', ''));

  return (n1 / n2) * (10 ** (t2 - t1));
}
