import { isInBuffClient } from '@/utils';
import native from '@/utils/native.bridge';

const inBuffClient = isInBuffClient();
const openExternal = ({
  url,
  target,
  features,
  onOpen = () => {},
  onClose = () => {},
  onFail = () => {},
}) => {
  // iOS客户端额外处理，避免系统阻塞非用户操作的window.open
  if (inBuffClient && url) {
    return native('window_open', {
      url,
    }, {
      timeout: -1, // 配置不需要超时检测
    }).then(() => {
      onClose();
    }, () => {
      onFail();
    });
  }
  const ref = window.open(url, target, features);
  if (!ref || target === '_self') { onFail(); return ref; }
  onOpen(ref);
  const timer = window.setInterval(() => {
    if (ref === null || ref.closed) {
      onClose();
      window.clearInterval(timer);
    }
  }, 500);
  return ref;
};

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$external = openExternal;
};

export default plugin;
