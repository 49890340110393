<template>
  <component :is="currentComponent" />
</template>

<script>
import desktopAppC2C from './App.desktop.c2c.vue';
// 荒野的不打在里面哈，让动态去加载吧
const desktopApp = () => import(/* webpackChunkName: "market-desktop-entry" */'./App.desktop.vue');

export default {
  components: {
    desktopApp,
    desktopAppC2C,
  },
  data() {
    return {
    };
  },
  computed: {
    currentComponent() {
      const { name } = this.$route;
      if (!name) {
        return '';
      }
      if (name.startsWith('Market.')) {
        return 'desktopApp';
      }
      return 'desktopAppC2C';
    },
  },
  created() {
  },
};
</script>
