import Axios from '@/utils/axios.ext';
import api from '@/api';
import localStorage from '@/utils/local-storage';
import SessionStorage from '@/utils/session-storage';

const state = {
  tournament: null,
  orderTags: {},
};

const getters = {

};
const mutations = {
  SET_TOURNAMENT(_state, tournament) {
    const map = new Map();
    tournament.proplayer.forEach((item) => {
      map.set(item.value, item);
    });
    tournament.hot_players = tournament.hot_players.map((item) => map.get(item));
    _state.tournament = tournament;
  },
  SET_ORDER_TAGS(_state, { category, tags }) {
    _state[category] = tags;
  },
};
const actions = {
  /**
   * 获取市场货品列表
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  getMarketGoods({ rootState, getters: _getters }, {
    search, sortBy, filters: _filters = {}, page, shortcut,
  } = {}) {
    const filters = {};
    const tags = {};
    const stateInfo = {};
    if (page.state === 0) {
      stateInfo.state = Number(page.state);
    }
    let hasTags = false;
    if (shortcut) {
      Object.keys(shortcut).forEach((key) => {
        tags[key] = shortcut[key].split(',');
        hasTags = true;
      });
    } else {
      Object.keys(_filters).forEach((key) => {
        if (rootState.availableTags.has(key)) {
          tags[key] = _filters[key].split(',');
          hasTags = true;
        } else {
          filters[key] = _filters[key];
        }
      });
    }
    return Axios({
      method: 'get',
      url: api.getMarketGoods(),
      params: {
        ...filters,
        tags: hasTags ? JSON.stringify(tags) : undefined,
        search,
        game: rootState.game,
        subgame_name: _getters.subgame,
        sort_by: sortBy,
        page_num: page.pageNum,
        page_size: page.pageSize,
        ...stateInfo,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取最全求购列表
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  // getMarketGoodsOrdersAll({ rootState }, {
  //   search, sortBy, filters, page,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketGoodsOrdersAll(),
  //     params: {
  //       ...filters,
  //       search,
  //       game: rootState.game,
  //       sort_by: sortBy,
  //       page_num: page.pageNum,
  //       page_size: page.pageSize,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 获取求购专区列表
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  // getMarketGoodsOrdersBuying({ rootState }, {
  //   search, sortBy, filters, page,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketGoodsOrdersBuying(),
  //     params: {
  //       ...filters,
  //       search,
  //       game: rootState.game,
  //       sort_by: sortBy,
  //       page_num: page.pageNum,
  //       page_size: page.pageSize,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * 获取市场货品列表
   * @param {string} category 印花分类
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  getMarketOrderTagsData({ rootState, commit }, {
    category, page, search, tagIds, addCategoryTag,
  }) {
    const params = {
      game: rootState.game,
    };
    if (category) params.category = category;
    if (search) params.search = search;
    if (tagIds) params.tag_ids = tagIds;
    if (page) {
      params.page_num = page.pageNum;
      params.page_size = page.pageSize;
    }
    if (addCategoryTag) {
      params.add_category_tag = addCategoryTag;
    }
    return Axios({
      method: 'get',
      url: api.getMarketOrderTags(),
      params,
    }).then((resp) => {
      const tags = resp.data;
      commit('SET_ORDER_TAGS', { category, tags });
      return tags;
    });
  },
  getTournamentData({ commit }) {
    return Axios({
      method: 'get',
      url: api.getTournamentData(),
    }).then((resp) => {
      const tournament = resp.data;
      commit('SET_TOURNAMENT', tournament);
      return tournament;
    });
  },
  /**
  * 获取市场公告信息
  * @param {GameType} game 游戏类型
  */
  getAnnouncement({ rootState }) {
    return Axios({
      method: 'get',
      url: api.getAnnouncement(),
      params: {
        game: rootState.game,
      },
    }).then((resp) => {
      const announcementInfo = localStorage.get('announcement-popup');
      const { announcement } = resp.data;
      if (announcement && announcementInfo?.id !== announcement?.id) {
        const nowDate = new Date().valueOf();
        if (nowDate >= announcement.start_time * 1000 && nowDate <= announcement.end_time * 1000) {
          localStorage.set('announcement-popup', announcement);
        }
      }
      return resp.data;
    });
  },

  /**
   * 获取市场货品信息
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  getMarketGoodsInfo({ rootState, getters: _getters }, { goodsId } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsInfo(),
      params: {
        game: rootState.game,
        subgame_name: _getters.subgame,
        goods_id: goodsId,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取市场货品 sell_order 列表
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  getMarketGoodsSellOrder({ rootState, getters: _getters }, { signal, ...params }) {
    // 传入state必须是1,2，其它值需要剔除掉
    if (params.state) {
      const pState = parseInt(params.state, 10);
      if (!(pState === 1 || pState === 2)) {
        delete params.state;
      } else {
        params.state = pState;
      }
    }
    // 可取消请求
    const config = {
      method: 'get',
      url: api.getMarketGoodsSellOrder(),
      params: {
        game: rootState.game,
        subgame_name: rootState.game ? _getters.subgame : undefined,
        ...params,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    };
    if (signal) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },
  /**
   * 查询之前购买某商品填写的额外信息
   * @param {GameType} game 游戏类型，必填
   * @param {Integer} goodsId 货品id，可选
   * @param {Integer} appid 游戏ID，可选
   * @param {String} assetid 道具ID，可选
   */
  getMarketBuyOrderExtra(_, {
    game, appid, goodsId, assetid,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketBuyOrderExtra(),
      params: {
        game,
        appid,
        goods_id: goodsId,
        assetid,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 设置到货提醒
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  setRemind({ rootState }, data) {
    return Axios({
      method: 'post',
      url: api.setRemind(),
      data: {
        game: rootState.game,
        ...data,
      },
    }).then((resp) => resp);
  },
  /**
   * 设置上架提醒
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  arrivalRemind({ rootState }, data) {
    return Axios({
      method: 'post',
      url: api.arrivalRemind(),
      data: {
        game: rootState.game,
        ...data,
      },
    }).then((resp) => resp);
  },
  /**
   * 关注或收藏饰品
   * @param {number} target_type 类型 1-文章 2-商品 3-订单 4-款式 5-特殊形式 6-店铺
   * @param {number} target_id 订单id/用户id
   */
  postMarketAddBookMark(_, { targetType, targetId } = {}) {
    return Axios({
      method: 'post',
      url: api.postMarketAddBookMark(),
      data: {
        target_type: targetType,
        target_id: targetId,
      },
    }).then((resp) => resp);
  },
  /**
   * 移除关注或收藏饰品
   * @param {number} target_type 类型 1-文章 2-商品 3-订单 4-款式 5-特殊形式 6-店铺
   * @param {number} target_id 订单id/用户id
   */
  deleteMarketBookMark(_, { targetType, targetId } = {}) {
    return Axios({
      method: 'delete',
      url: api.deleteMarketBookMark(),
      data: {
        target_type: targetType,
        target_id: targetId,
      },
    }).then((resp) => resp);
  },
  /**
   * 置顶关注或收藏饰品
   * @param {number} target_type 类型 1-文章 2-商品 3-订单 4-款式 5-特殊形式 6-店铺
   * @param {number} target_id 订单id/用户id
   */
  makeThingToTop(_, { targetType, targetId } = {}) {
    return Axios({
      method: 'post',
      url: api.makeThingToTop(),
      data: {
        target_type: targetType,
        target_id: targetId,
      },
    }).then((resp) => resp);
  },
  // 清除下架
  cleanSoldOut(_, { game, appid } = {}) {
    return Axios({
      method: 'post',
      url: api.cleanSoldOut(),
      data: {
        game,
        appid,
      },
    }).then((resp) => resp);
  },
  /**
   * 获取市场货品 buy_order 列表
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  // getMarketGoodsBuyOrder({ rootState }, params) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketGoodsBuyOrder(),
  //     params: {
  //       game: rootState.game,
  //       ...params,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 获取市场货品 trade_record 列表
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  getMarketGoodsBillOrder({ rootState, getters: _getters }, params) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsBillOrder(),
      params: {
        game: rootState.game,
        subgame_name: _getters.subgame,
        ...params,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取市场货品 price_changes 数据
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  getMarketGoodsPrice(_, { game, goodsId } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsPrice(),
      params: {
        game, goodsId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取市场货品 float_rank 列表
   * @param {GameType} game 游戏类型
   * @param {string} goodsId 货品id
   */
  // getMarketPaintwearRank({ rootState }, { goodsId, pageNum, pageSize } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketPaintwearRank(),
  //     params: {
  //       game: rootState.game,
  //       goods_id: goodsId,
  //       page_num: pageNum,
  //       page_size: pageSize,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * check 3D检视图状态
   * @param {string} assetId 货品id
   */
  // getMarketGoods3dInspectStatus(_, { assetid } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getMarketGoods3dInspectStatus(),
  //     params: { assetid },
  //   }).then((resp) => resp.data);
  // },

  /**
   *  更新steam_asset_state
   * @param {string} contextid
   * @param {string} assetid 货品id
   */
  // updateMarketSteamAssetState({ rootState }, {
  //   type, contextid, assetid,
  // } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.updateMarketSteamAssetState(type),
  //     data: {
  //       game: rootState.game,
  //       contextid,
  //       assetid,
  //     },
  //   }).then((resp) => resp);
  // },

  /**
   * 获取价格历史
   * @param {number} channel 市场类型
   * @param {object} params 筛选参数
   */
  getMarketGoodsPriceHistory({ rootState }, { channel, params } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsPriceHistory(channel),
      params: {
        game: rootState.game,
        ...params,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取价区间
   * @param {number} channel 市场类型
   * @param {string} game
   * @param {string} goods_id
   */
  getMarketGoodsPriceDuration({ rootState }, { channel, id } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsPriceDuration(channel),
      params: {
        game: rootState.game,
        goods_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取商品模型信息
   * @param {string} game
   * @param {string} goodsId
   * @param {string} assetid
   * @param {number} inspectTagType
   */
  getMarketGoodsViewInfo({ rootState }, { goodsId, assetId, inspectTagType }) {
    return Axios({
      method: 'get',
      url: api.getMarketGoodsInspect(),
      params: {
        game: rootState.game,
        goods_id: goodsId,
        asset_id: assetId,
        inspect_tag_type: inspectTagType,
      },
    });
  },
  /**
   * 获取磨损
   */
  // postAssetCreate({ rootState }, { assetid } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postAssetCreate(),
  //     data: {
  //       game: rootState.game,
  //       assetid,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 刷新印花
   */
  // postRefreshPaintwear({ rootState }, { contextid, assetid } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postRefreshPaintwear(),
  //     data: {
  //       game: rootState.game,
  //       assetid,
  //       contextid,
  //       appid: 730,
  //     },
  //   }).then((resp) => resp.data);
  // },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
