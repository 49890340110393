const debug = (ob, option, c) => {
  let content = ob;
  const obShowType = !!option;
  const curColor = c || '#fff';

  if (typeof (ob) === 'object' && obShowType === true) {
    content = JSON.stringify(ob, null, '\t').replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  } else if (typeof (ob) === 'object' && obShowType === false) {
    content = JSON.stringify(ob);
  } else {
    content = ob;
  }

  let eleDebug = document.getElementById('#j_debug');
  if (!eleDebug) {
    eleDebug = document.createElement('div');
    eleDebug.id = '#j_debug';
    eleDebug.style = 'position:fixed;width:100%;padding:12px 0;color:#fff;background-color: rgba(0,0,0,0.6);top:0;left:0;word-wrap: break-word;word-break: normal; z-index:10000;';
    eleDebug.innerHTML = "<span style='color:#ff0;font-weight:bold;'>DEBUG INFO:</span>";
    document.body.appendChild(eleDebug);
  }
  eleDebug.innerHTML = `${eleDebug.innerHTML}<p style='border-top:1px dashed #fff;color:${curColor}'>${content}</p>`;
};

const DebugPlugins = {};

DebugPlugins.install = (VueInstance) => {
  VueInstance.$debug = debug;
};

export default DebugPlugins;
