<template>
  <div class="coin-widget-box">
    <div v-if="isShowWidgetIm" class="coin-widget"
      :class="{'is-open-chat': isShowMessageChat && !isMobile,'is-hidden': isHidden && isMobile}"
      :style="{zIndex: zIndex}"
      @click="onCallIm">
      <p v-if="!isShowMessageChat && !isMobile" class="font14">{{ $t('lootBar.support') }}</p>
      <i class="coin-widget-icon" :class="{'is-chat': isShowMessageChat && !isMobile}"></i>
      <!-- <w-modal
        :show="isShowMessageChat"
        @close="isShowMessageChat = false"> -->
      <!-- </w-modal> -->
      <div v-if="unreads" class="message-tip">{{ unreads > 99 ? '99+' : unreads }}</div>
    </div>
    <MessageChatDialog
      :to-seller-id="sellerId"
      :is-to-chat="isToChat"
      :show="isShowMessageChat"
      :extra-info="extraInfo"
      :send-text="sendText"
      :is-open="isOpen"
      @closeIsChat="handleCloseIsChat"
      @closeMessage="handleCloseMessage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import { LoginStatus } from '@/enums';
import { isMobile } from '@/utils';
import MessageChatDialog from '@/views/components/MessageChat/MessageChatDialog.vue';
import PopupManager from '@/packages/popup-manager';

const WIDGET_HIDDEN_DELAY = 10e3;

export default {
  components: {
    MessageChatDialog,
  },
  props: {
    isShowWidgetIm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMobile: isMobile(),
      isShowMessageChat: false,
      zIndex: PopupManager.nextZIndex(),

      isHidden: false, // 移动端是否收起
      sellerId: null, // 卖家ID
      isOpen: false, // 是否打开弹窗
      isToChat: false, // 是否定位到聊天
      extraInfo: {}, // 携带参数
      sendText: '', // 发送内容
      imLogInfo: {}, // 日志信息
    };
  },
  computed: {
    ...mapGetters(['loginStatus', 'notifications', 'userInfo', 'sightseerMsgNum']),
    unreads() {
      let imNum = 0;
      if (this.loginStatus === LoginStatus.logined) {
        imNum = this.notifications?.unread_im_message?.total ?? 0;
      } else {
        imNum = this.sightseerMsgNum?.num;
      }
      return imNum;
    },
  },
  watch: {
    isOpen(val) {
      this.isShowMessageChat = val;
      // 打开弹窗时，置顶IM弹窗
      if (val) {
        this.zIndex = PopupManager.nextZIndex();
      } else {
        this.zIndex = null;
      }
    },
    $route: {
      handler() {
        if (this.isMobile) {
          this.handleCloseMessage();
          this.handleShowWidget();
        }
      },
      immediate: true,
    },
    unreads(num) {
      if (!num) {
        this.handleShowWidget();
      } else {
        this.isHidden = false;
      }
    },
  },
  mounted() {
    this.setSellerId = (sellerId, isToChat = false, extraInfo = {}, sendText = '') => {
      this.sellerId = sellerId;
      this.isToChat = isToChat;
      this.extraInfo = extraInfo;
      this.sendText = sendText;
    };
    bus.$on('setSellerId', this.setSellerId);
    this.openMessage = (isOpen = true) => {
      this.isOpen = isOpen;
    };
    bus.$on('openMessage', this.openMessage);
    this.clearSendText = () => {
      this.sendText = '';
    };
    bus.$on('clearSendText', this.clearSendText);
    this.setIMLogInfo = (info = {}) => {
      this.imLogInfo = info;
    };
    bus.$on('setIMLogInfo', this.setIMLogInfo);
  },
  beforeDestroy() {
    bus.$off('setSellerId', this.setSellerId);
    bus.$off('openMessage', this.openMessage);
    bus.$off('clearSendText', this.clearSendText);
    bus.$off('setIMLogInfo', this.setIMLogInfo);
  },
  methods: {
    handleShowWidget() {
      if (this.unreads) {
        this.isHidden = false;
        return;
      }
      if (this.timerHidden) window.clearTimeout(this.timerHidden);
      this.isHidden = false;
      this.timerHidden = window.setTimeout(() => {
        this.isHidden = true;
      }, WIDGET_HIDDEN_DELAY);
    },
    handleCloseMessage() {
      this.isShowMessageChat = false;
      this.isOpen = false;
    },
    handleCloseIsChat() {
      this.isToChat = false;
    },
    onCallIm() {
      bus.$emit('setCoinLog', { name: 'c2c_gamecoin_chat_click' });
      if (this?.imLogInfo?.name) {
        this.$logger(this?.imLogInfo?.name, this?.imLogInfo?.params);
      }
      if (this.isMobile) {
        this.$logger('imballmobileclick');
      }
      this.goIm();
    },
    goIm() {
      this.isShowMessageChat = !this.isShowMessageChat;
      if (!this.isShowMessageChat) {
        this.handleCloseMessage();
      }
      if (this.isMobile && this.isShowMessageChat) {
        this.$logger('imballmobileview');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.coin-widget-box {
  background: var(--bg-bg1);
  border-radius: 32px;
}
.coin-widget {
  position: fixed;
  right: 32px;
  bottom: 80px;
  display: flex;
  align-items: center;
  margin-bottom: calc(env(safe-area-inset-bottom));
  padding: 8px 8px 8px 16px;
  height: 64px;
  border-radius: 32px;
  background: var(--bg-bg1);
  border: 1px solid #edeef2;
  box-shadow: var(--shadow-3);
  cursor: pointer;
  max-width: 160px;
  z-index: 9;
  transform: translate(0, 0);
  opacity: 1;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  &.is-open-chat {
    width: 64px;
    padding: 0;
    border-radius: 50%;
  }
  &.is-hidden {
    transform: translate(50%);
    opacity: 0.5;
  }
  &:hover { opacity: 0.7; }
  &:active { opacity: 0.3; }
  @media(max-width: 750px) {
    right: 0;
    bottom: 140px;
    padding: 8px;
    border-radius: 32px 0 0 32px;
  }
  .message-tip {
    position: absolute;
    top: -8px;
    right: 6px;
    min-width: 16px;
    justify-content: center;
    align-items: center;
    height: 16px;
    background: var(--fu-red1);
    border-radius: 16px;
    padding: 0% 4px;
    z-index: 998;
    color: var(--fu-white1);
    font-size: 14px;
    line-height: 16px;
  }
  p {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    .fontBold();
  }

  &-icon {
    flex: 0 0 auto;
    margin-left: 4px;
    width: 48px; height: 48px;
    background: center/contain url("~@/assets/images/icon_support.png") no-repeat;
    &.is-chat {
      width: 64px; height: 64px;
      margin-left: 0;
      background: center/contain url("~@/assets/images/im_chat_close.png") no-repeat;
    }
    @media(max-width: 750px) {
      margin-left: 0;
    }
  }
}
</style>
