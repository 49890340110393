import store from '@/store';
import LocalStorage from '@/utils/local-storage';
import Axios from '@/utils/axios.ext';
import api from '@/api';
import SessionStorage from '@/utils/session-storage';
import { loadScriptWithRetr } from '@/utils';

const clientId = process.env.VUE_APP_GOOGLE_ID;
const src = 'https://accounts.google.com/gsi/client';

// function fetchSDK() {
//   return new Promise((resolve) => {
//     if (window.google) {
//       resolve();
//     } else {
//       const script = document.createElement('script');
//       script.src = src;
//       script.async = true;
//       document.head.appendChild(script);
//       script.onload = () => resolve();
//     }
//   });
// }

export async function googleSignIn(callback) {
  try {
    await loadScriptWithRetr(src);
  } catch (error) {
    callback({
      eventName: undefined,
      status: 'rejected',
    });
    return;
  }
  const handleGoogleSignIn = (res) => {
    const data = {
      id_token: res.credential,
      share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
      ...(SessionStorage.get('UTM_PARAMS') || {}),
    };
    Axios({
      method: 'post',
      url: api.postGoogleLogin(),
      data,
    })
      .then(async (resp) => {
        LocalStorage.set('ps-token', resp.data.token);
        // fetchLoginStatus检查用户登录状态、登录状态存到store => fetchUserInfo获取用户信息、用户信息存到store、响应式更新页面登录态
        await store.dispatch('fetchLoginStatus');
        await store.dispatch('fetchUserInfo');
        if (callback) {
          callback({
            eventName: 'google_login_confirm_click',
            status: 'resolved',
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  window.google.accounts.id.initialize({
    client_id: clientId,
    cancel_on_tap_outside: true, // 控制是否在提示之外进行点击时取消提示(关闭一键登录弹窗)，默认true
    auto_select: true, // 控制是否开启自动登录功能，默认false
    itp_support: false, // 是否应在支持智能反跟踪 (ITP) 的浏览器上启用 升级后的一键式用户体验
    callback: handleGoogleSignIn, // 验证成功回调
    // cancel: handleGoogleSignInCancel, // 取消登录回调
  });
  // 启用一键登录提示(弹窗)功能
  window.google.accounts.id.prompt((notification) => {
    const { l } = notification;
    const params = {
      eventName: undefined,
      status: 'rejected',
    };
    /**
     * isDisplayed 显示
     * isNotDisplayed 未显示
     * isSkippedMoment 跳过时刻：即当一键式提示因自动取消或手动取消而被关闭时
     */
    // 弹窗显示
    if (notification.isDisplayed()) {
      params.eventName = 'google_login_view';
      params.status = 'pending';
      if (callback) callback(params);
      return;
    }
    // 点击弹窗外自动隐藏
    if (notification.isSkippedMoment() && l === 'tap_outside') {
      params.eventName = 'google_login_tap_outside_click';
    }
    // 用户手动点击右上角叉号关闭弹窗
    if (notification.isSkippedMoment() && l === 'user_cancel') {
      params.eventName = 'google_login_user_cancel_click';
    }
    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      if (callback) callback(params);
    }
  });
}

export async function disableAutoSelect() {
  await loadScriptWithRetr(src);
  window.google.accounts.id.disableAutoSelect();
}
