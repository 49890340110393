<template>
  <div class="c-empty" :class="{'is-c2c': type === 'C2CNoData'}">
    <div class="c-empty-fig" :style="{ backgroundImage }"></div>
    <div class="c-empty-tips">{{ tips }}</div>
    <w-button
      v-if="buttonText"
      class="c-empty-button"
      type="primary" plain
      @click="handleClick">{{ buttonText }}</w-button>
    <slot></slot>
  </div>
</template>

<script>
// 防止断网的情况下显示不出该图标
import Network from '@/assets/images/img_network_abnormal.png';
import NoData from '@/assets/images/img_no_data.png';
import NoItems from '@/assets/images/img_no_items.png';
import NoResult from '@/assets/images/img_no_result.png';
import NoChatResult from '@/assets/images/img_no_chat_result.png';
import SignIn from '@/assets/images/img_signin.png';
import C2CNoData from '@/assets/images/img_c2c_no_data.png';
import Nolist from '@/assets/images/img_empty_nolist.png';
import NoOrder from '@/assets/images/img_no_order.png';

const ImageEnum = {
  Network, NoData, NoItems, NoResult, SignIn, C2CNoData, NoChatResult, Nolist, NoOrder,
};
export default {
  props: {
    type: {
      type: String,
      validator(value) {
        return Object.keys(ImageEnum).indexOf(value) !== -1;
      },
    },
    tips: String,
    buttonText: String,
  },
  computed: {
    backgroundImage() {
      return `url('${ImageEnum[this.type]}')`;
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('buttonClick', e);
    },
  },
};
</script>

<style lang="less" scoped>
.c-empty {
  .font16;
  text-align: center;
  color: @tint_light_3;
  &-fig {
    margin: 0 auto;
    width: 154px;
    height: 211px;
    background: center/contain no-repeat;
    opacity: 0.48;
  }
  &.is-c2c &-fig {
    width: 128px; height: 128px;
    opacity: 1;
  }
  &-tips {
    margin-top: 32px;
  }
  &-button {
    .font18;
    margin-top: 24px;
    min-width: 160px;
    height: 48px;
  }
}
</style>
