import Vue from 'vue';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import desktopRouter from '@/router/desktop';
import mobileRouter from '@/router/mobile';

import Tutorial from './Tutorial.vue';

const TutorialCtor = Vue.extend(Tutorial);

const emptyFn = () => {};

const tutorialSet = new Set();

const TutorialCreator = (store) => async (payload = {}) => {
  const {
    id,
    content = '',
    title = '',
    onShow = emptyFn,
    onClose = emptyFn,
  } = payload || {};
  // 教程ID必填
  if (!id) throw new Error('Tutorial ID Needed');
  // 防止重复弹同一个教程
  if (tutorialSet.has(id)) return;
  tutorialSet.add(id);
  const instance = new TutorialCtor({
    el: document.createElement('div'),
    propsData: { id, title, content },
    store,
    i18n,
    router: isMobile() ? mobileRouter : desktopRouter,
  });
  instance.$on('show', () => {
    onShow();
  });
  instance.$on('close', () => {
    tutorialSet.delete(id);
    onClose();
  });
};

export default TutorialCreator;
