<template>
  <div class="c-global-notice">
    <div ref="wrap" class="c-global-notice-content"
      :class="{'is-interactive': interactive}"
      @click="handleContentClick">
      <div
        :style="{transform:`translate(${translateX}px, 0)`}"
        @mouseover="pauseAnimation"
        @mouseleave="startAnimation">
        <div ref="content" v-html="content"></div>
        <div v-if="isOverflow" style="margin-left: 96px;" v-html="content"></div>
      </div>
    </div>
    <div class="c-global-notice-close">
      <w-button icon name="icon_close"
        class="c-global-notice-close-button"
        type="primary" size="m"
        @click="handleClose" />
    </div>
  </div>
</template>

<script>
const PADDING_LEFT = 32;
const GUTTER_BETWEEN = 96;
let delayTimer;
let timer;

export default {
  props: {
    content: String,
    interactive: Boolean,
  },
  data() {
    return {
      contentWidth: 0,
      wrapWidth: 0,

      state: 'delay',
      translateX: 0,
    };
  },
  computed: {
    isOverflow() {
      return this.wrapWidth < this.contentWidth;
    },
  },
  watch: {
    content(val) {
      if (val) {
        this.$nextTick(() => {
          this.updateContentWidth();
        });
      } else {
        this.contentWidth = 0;
      }
    },
    isOverflow(val) {
      if (val) {
        this.startScroll();
      } else {
        this.stopScroll();
      }
    },
  },
  mounted() {
    this.updateContentWidth();
    this.updateWrapWidth();
    window.addEventListener('resize', this.updateWrapWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWrapWidth);
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleContentClick() {
      if (!this.interactive) return;
      this.$emit('content-click');
    },
    updateContentWidth() {
      const { content } = this.$refs;
      this.contentWidth = content.clientWidth;
    },
    updateWrapWidth() {
      const { wrap } = this.$refs;
      this.wrapWidth = wrap.clientWidth - PADDING_LEFT; // 32为内容左padding
    },
    startScroll() {
      delayTimer = window.setTimeout(() => {
        this.state = 'animate';
        this.startAnimation();
      }, 3 * 1e3);
    },
    stopScroll() {
      if (delayTimer) window.clearTimeout();
      this.stopAnimation();
    },
    runAnimation() {
      this.translateX -= 1;
      if (-this.translateX > this.contentWidth + GUTTER_BETWEEN) {
        this.stopAnimation();
        this.startScroll();
        this.state = 'delay';
      }
    },
    startAnimation() {
      if (!this.isOverflow || this.state === 'delay' || timer) return;
      timer = window.setInterval(this.runAnimation, 16.67);
    },
    pauseAnimation() {
      if (!this.isOverflow || this.state === 'delay') return;
      if (timer) window.clearInterval(timer);
      timer = null;
    },
    stopAnimation() {
      if (timer) window.clearInterval(timer);
      timer = null;
      this.translateX = 0;
    },
  },
};
</script>

<style lang="less">
.c-global-notice {
  .font16;
  font-weight: bold;
  height: 48px;
  border-radius: 4px;
  background-color: @background_dark_2;
  color: rgba(0, 0, 0, 0.6);
  z-index: 9;
  overflow: hidden;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    bottom: 0;
    background: linear-gradient(
      to right,
      @background_dark_2,
      rgba(@background_dark_2, 0)
    );
    z-index: 9;
  }
  &-content {
    flex: 1 1 0;
    padding-left: 32px;
    overflow: hidden;
    white-space: nowrap;
    &.is-interactive {
      cursor: pointer;
      // &:hover {
      //   color: @label_primary_1;
      // }
    }
    div {
      display: inline-block;
      white-space: nowrap;
    }
  }
  &-close {
    flex: 0 0 auto;
    margin: 0 24px;
    line-height: 0;
    &-button {
      color: @tint_dark_8 !important;
    }
  }
  @media (max-width: 749px) {
    &::before {
      width: 16px;
    }
    &-content {
      padding-left: 16px;
    }
    &-close {
      margin: 0 8px;
    }
  }
}
</style>
