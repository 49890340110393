import ResizeObserver from 'resize-observer-polyfill';

const isServer = typeof window === 'undefined';

/* istanbul ignore next */
const resizeHandler = (entries) => {
  Object.keys(entries).forEach((entry) => {
    const listeners = entries[entry].target.resizeListeners || [];
    if (listeners.length) {
      listeners.forEach((fn) => {
        fn();
      });
    }
  });
};

/* istanbul ignore next */
export const addResizeListener = (element, fn) => {
  if (isServer) return;
  if (!element.resizeListeners) {
    element.resizeListeners = [];
    element.ro = new ResizeObserver(resizeHandler);
    element.ro.observe(element);
  }
  element.resizeListeners.push(fn);
};

/* istanbul ignore next */
export const removeResizeListener = (element, fn) => {
  if (!element || !element.resizeListeners) return;
  element.resizeListeners.splice(element.resizeListeners.indexOf(fn), 1);
  if (!element.resizeListeners.length) {
    element.ro.disconnect();
  }
};
