import EventEmitter from './event-emitter';

export default class ABTest extends EventEmitter {
  constructor() {
    super();
    this._init = false;
    this.experiments = {};
    this.uid = '';
    this.did = '';
    this.storage = localStorage;
    this.storageKey = 'abtest_variants';
  }

  init(options = {}) {
    this.experiments = options.experiments || {};
    this.uid = options.uid || '';
    this.did = options.did || '';
    this.storage = options.storage || localStorage;
    this.storageKey = options.storageKey || 'abtest_variants';

    if (typeof this.experiments === 'string') {
      this.fetchExperimentsConfig(this.experiments);
    } else {
      this._init = true;
      this.emit('init');
    }
  }

  static LAYER = {
    UID: 'uid',
    DID: 'did',
  };

  static generateRandomId() {
    return Math.random().toString(36).substring(2, 15);
  }

  static selectVariantByRatio(variants, hash) {
    const totalRatio = variants.reduce((sum, variant) => sum + (variant.ratio || 1), 0);
    const randomValue = hash % totalRatio;
    let accumulatedRatio = 0;

    const selectedVariant = variants.find((variant) => {
      accumulatedRatio += variant.ratio || 1;
      return randomValue < accumulatedRatio;
    });

    return selectedVariant ? selectedVariant.name : variants[variants.length - 1].name;
  }

  static hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
      const char = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash) + char;
      // eslint-disable-next-line no-bitwise
      hash &= hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

  fetchExperimentsConfig(url) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.experiments = JSON.parse(xhr.responseText);
      } else {
        console.error('Failed to fetch experiments config');
      }
      this._init = true;
      this.emit('init');
    };
    xhr.send();
  }

  getExperiments(experiments) {
    this.experiments = experiments;
  }

  setLayer(layer, value) {
    if (!Object.values(ABTest.LAYER).includes(layer)) {
      throw new Error(`Layer "${layer}" is not supported`);
    }
    this[layer] = value;
  }

  getExperimentLayer(experimentName) {
    const experiment = this.experiments[experimentName];
    if (!experiment) {
      throw new Error(`Experiment "${experimentName}" not found`);
    }

    const experimentLayer = experiment.layer || ABTest.LAYER.DID;

    if (!Object.values(ABTest.LAYER).includes(experimentLayer)) {
      throw new Error(`Experiment "${experimentName}" does not support layer "${experimentLayer}"`);
    }

    return this[experimentLayer];
  }

  getVariant(experimentName) {
    const experiment = this.experiments[experimentName];
    if (!experiment) {
      throw new Error(`Experiment "${experimentName}" not found`);
    }

    const storedVariants = this.getStoredVariants();
    const storedVariant = storedVariants[experimentName];
    if (storedVariant && experiment.variants.find(({ name }) => name === storedVariant)) {
      return storedVariant;
    }

    const layer = this.getExperimentLayer(experimentName);
    if (!layer) return '';

    const hash = ABTest.hashString(`${layer}:${experimentName}`);
    const selectedVariant = ABTest.selectVariantByRatio(experiment.variants, hash);

    this.storeVariant(experimentName, selectedVariant);
    return selectedVariant;
  }

  getStoredVariants() {
    const storedData = this.storage.getItem(this.storageKey);
    return storedData ? JSON.parse(storedData) : {};
  }

  storeVariant(experimentName, variant) {
    const storedVariants = this.getStoredVariants();
    storedVariants[experimentName] = variant;
    this.storage.setItem(this.storageKey, JSON.stringify(storedVariants));
  }

  clearStoredVariants() {
    this.storage.removeItem(this.storageKey);
  }
}
