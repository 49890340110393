import { getUnitPrice } from '@/utils';
import { multiply } from '@/utils/math';
import store from '../store';

export default function formatPrice(price, currency, { rated = false, floor = false } = {}) {
  let _price = parseFloat(price, 10);
  const rate = currency ? currency.rate : store.getters.currencyRate;
  const precision = currency ? currency.precision : store.getters.currencyPrecision;
  const separator = (currency ? currency.separator : store.getters.currencySeparator) || ',';
  if (!rated) _price *= rate;
  const fn = floor ? Math.floor : Math.ceil;
  let strPrice = (fn(multiply(_price, 10 ** precision))
    / (10 ** precision)).toFixed(precision);
  const zeroPrice = (0).toFixed(precision);
  if (Number(price) > 0 && strPrice === zeroPrice) strPrice = getUnitPrice(precision);
  else if (Number.isNaN(_price)) strPrice = zeroPrice;
  const splitPrice = strPrice.replace(/\d(?=(\d{3})+(\.|$))/g, '$&,').split('.');
  const decSeparator = separator === '.' ? ',' : '.';
  return splitPrice[0].replaceAll(',', separator)
    + (splitPrice[1] ? decSeparator + splitPrice[1] : '');
}
