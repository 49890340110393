import store from '../store';

export default function formatUsdDec(price) {
  let _price = parseFloat(price, 10);
  const separator = store.getters.currencySeparator || ',';
  if (Number.isNaN(_price)) _price = 0;
  const splitPrice = _price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.');
  const decSeparator = separator === '.' ? ',' : '.';
  return splitPrice[1] ? `${decSeparator}${splitPrice[1]}` : '';
}
