import Vue from 'vue';
import store from '@/store';
import localStorage from '@/utils/local-storage';
import { i18n } from '@/utils/i18n';
import { logger } from '@/plugins/logger';
import Toast from '@/plugins/toast';
import { asyncLoadComponent } from '@/plugins/utils';
import { ProfileGuideEnum } from '@/enums';

let Registration = null;

let ref = null;
let promise = null;

const RegistrationInstance = () => (channel, params = {}) => {
  const { retrieveToken, stayLogin } = params;
  if (promise && ref) {
    if (ref) ref.focus();
    return promise;
  }
  promise = new Promise((resolve) => {
    async function generateRegistrationDialog(userInfo) {
      if (!Registration) {
        try {
          Registration = await asyncLoadComponent(() => import('./registration.vue'));
        } catch (err) {
          return Promise.reject();
        }
      }
      const RegistrationCtor = Vue.extend(Registration);
      const instance = new RegistrationCtor({
        el: document.createElement('div'),
        propsData: { steamUserInfo: userInfo },
        store,
        i18n,
      });
      instance.show = true;
      // window.gtag('event', 'registration_privacy');
      instance.promise = new Promise((_resolve) => {
        instance.$on('close', ({ isConfirmed }) => {
          instance.show = false;
          _resolve(isConfirmed);
        });
      });
      return instance.promise;
    }

    async function handleCheckSignIn() {
      const { state, user } = await store.dispatch('fetchLoginStatus');
      if (state === 0) { // 未登录
      } else if (state === 1) { // 已登录未注册
        const isConfirmed = await generateRegistrationDialog(user);
        if (isConfirmed) {
          await store.dispatch('fetchLoginStatus');
          await store.dispatch('fetchUserInfo');
        }
      } else if (state === 2) { // 已登录已注册
        await store.dispatch('fetchUserInfo');
      }
      if (state !== 0) {
        localStorage.set('login-channel', channel);
      }
      return state;
    }
    if (!channel) {
      channel = localStorage.get('login-channel');
    }
    if (channel === 'apple') {
      window.AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLE_ID,
        scope: 'name email',
        redirectURI: `${window.location.origin}/authorization/apple/verify`,
        state: '',
        nonce: '',
        usePopup: true,
      });
      window.AppleID.auth.signIn().then((data) => {
        const { authorization: { id_token: idToken } } = data;
        const name = data.user ? `${data.user.name.firstName} ${data.user.name.lastName}` : 'Apple User';
        const payload = { idToken, name, retrieveToken };
        if (stayLogin) payload.stayLogin = stayLogin;
        store.dispatch('postAppleLogin', payload)
          .then(handleCheckSignIn)
          .then((state) => {
            promise = null;
            if (retrieveToken) {
              Toast()(i18n.t('account.change_bind_success'));
            }
            resolve(state);
          }).catch((error) => {
            promise = null;
            resolve(0);
            Toast('error')(error.data.error || error.data.msg);
          });
      }).catch((error) => {
        console.log(error);
        resolve(0);
      });
    } else {
      const query = [];
      if (stayLogin) query.push('stay_login=1');
      if (retrieveToken) query.push(`retrieve_token=${retrieveToken}`);
      const querystring = query.length ? `?${query.join('&')}` : '';
      const url = `/authorization/${channel}${querystring}`;
      console.log(url);
      ref = window.open(url, '_blank', 'width=800,height=800');
      const handleLoginResp = (e) => {
        if (e.origin !== window.location.origin) return;
        const { error, data } = e.data;
        if (error) Toast('error')(error.error || error.msg);
        else if (data) {
          // 每五天显示一次
          if (!localStorage.get('FACEBOOK_LOGIN_EMAIL') && channel.toLocaleUpperCase() === 'FACEBOOK' && !data.has_email) {
            localStorage.set('FACEBOOK_LOGIN_EMAIL', 1, 5 * 24 * 60 * 60 * 1000);
            Toast()(i18n.t('global.success'));
            logger.log('page_profile_email_afterlogin_send_view');
            Vue.prototype.$guide(ProfileGuideEnum.email).then(() => {
              Vue.prototype.$Message.success(i18n.t('global.success'));
              logger.log('page_profile_email_afterlogin_success');
            }).catch(() => {
              logger.log('page_profile_email_afterlogin_unbind');
            });
          }

          if (retrieveToken) {
            Toast()(i18n.t('account.change_bind_success'));
          }
        }
      };
      window.addEventListener('message', handleLoginResp, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', handleLoginResp, false);
          window.clearInterval(timer);
          ref = null;
          handleCheckSignIn().then((state) => {
            promise = null;
            resolve(state);
          });
        }
      }, 1e3);
    }
  });
  return promise;
};
RegistrationInstance.install = (VueInstance) => {
  VueInstance.prototype.$reg = RegistrationInstance();
};

export default RegistrationInstance;
