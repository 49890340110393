// 获取url中的query参数
export const urlSearchQuerys = (url = '') => {
  const args = {};
  const query = (url && url.split('?')[1]) || window.location.search.substring(1);
  const pairs = query.split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pos = pairs[i].indexOf('=');
    if (pos > -1) {
      const name = pairs[i].substring(0, pos);
      let value = pairs[i].substring(pos + 1);
      value = decodeURIComponent(value);
      args[name] = value;
    }
  }
  return args;
};

// 生成query参数
function querystringify(data) {
  if (!data || typeof (data) !== 'object') {
    return '';
  }
  const temp = [];
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value == null || typeof (value) === 'undefined') {
      return;
    }
    temp[temp.length] = `${key}=${value}`;
  });
  return temp.join('&');
}
export const setSearchQueryString = (rawurl, params = {}) => {
  let connector = '?';
  const reg = /(#\w*)$/gi;
  if (rawurl.indexOf('?') > 0) {
    connector = '&';
  }
  if (reg.test(rawurl)) {
    rawurl = rawurl.replace(reg, (_match, $1) => connector + querystringify(params) + $1);
  } else {
    rawurl += connector + querystringify(params);
  }
  return rawurl.length === 1 ? '' : rawurl;
};

// history删除search参数
export const historyStateRemoveSearch = (keys = []) => {
  const params = urlSearchQuerys();
  let haRemove = false;
  keys.map((key) => {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      haRemove = true;
      delete params[key];
    }
    return params;
  });
  if (haRemove) {
    const searchStr = setSearchQueryString('', params);
    window.history.replaceState({}, '', searchStr);
  }
};

// history添加search参数
export const historyStateAddSearch = (params = {}) => {
  const searchStr = setSearchQueryString('', params);
  window.history.replaceState({}, '', searchStr);
};

// 过滤utm信息，渠道信息显示不同推广
export const filterUtm = function filterUtm(obj = {}) {
  const keys = Object.keys(obj);
  const extra = {};
  const utmEnums = ['utm_source', 'utm_medium', 'utm_campaign'];
  keys.map((key) => {
    if (utmEnums.includes(key) && obj[key] !== '') {
      extra[key] = obj[key];
    }
    return true;
  });
  return extra;
};

function capitalizeWords(str) {
  return str.split(/[-_]/).map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('-');
}

// header信息过滤渠道信息
export const filterUtmHeader = function filterUtmHeader(obj = {}) {
  return Object.keys(obj).reduce((acc, key) => {
    const utmKey = capitalizeWords(`X-${key.replace('_', '-')}`);
    acc[utmKey] = obj[key];
    return acc;
  }, {});
};
