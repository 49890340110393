<template>
  <transition name="slidein-bottom">
    <div v-show="isShow" class="c-app-bar" @touchmove.prevent>
      <div v-if="!hideLogin" class="c-app-bar-login">
        <w-button plain type="primary" size="l"
          @click="handleHideLogin">{{ $t('global.later') }}</w-button>
        <w-button type="primary" size="l"
          @click="handleLogin">{{ $t('global.login_sign_up') }}</w-button>
      </div>
      <div class="c-app-bar-navs">
        <div
          v-for="route in routes" :key="route.name"
          class="c-app-bar-item"
          :class="{'is-active': $route.name === route.name}"
          @click="handleNavigateTo(route.name)">
          <div class="item--icon-wrap">
            <w-icon v-show="$route.name !== route.name"
              class="item--icon" :name="`icon_nav_${route.icon}`" :size="24" />
            <w-icon v-show="$route.name === route.name"
              class="item--icon" :name="`icon_nav_${route.icon}_selected`" :size="24" />
            <template v-if="route.name === 'Messages'">
              <div v-if="unreads.trade || unreads.system || unreads.im" class="item--badge"
                :class="{'item--badge-number':unreads.trade || unreads.im}">
                <template v-if="unreads.trade || unreads.im">
                  {{ (unreads.trade + unreads.im) > 99
                    ? '99+' : (unreads.trade + unreads.im) }}</template>
              </div>
            </template>
            <template v-if="route.name === 'My'">
              <div v-if="unreads.my" class="item--badge item--badge-number">
                {{ unreads.my }}</div>
            </template>
          </div>
          <span class="font12 item--title">{{ route.label }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { LoginStatus, ProfileGuideEnum } from '@/enums';
import LocalStorage from '@/utils/local-storage';
import { isInBuffClient } from '@/utils';

const DELAY = 24 * 60 * 60 * 1e3;

const SHOW_ROUTES = ['Index', 'Topup', 'List', 'Detail', 'Coin', 'Gameitem',
  'Messages', 'Messages.Trade', 'Messages.System',
  'Account.MyBalance.Deposit', 'Account.MyBalance.Withdraw', 'Account.MyBalance.History',
  'Account.Buy', 'Account.Coupon',
  'Account.AccountSet', 'Account.AccountSet.Account', 'Account.AccountSet.Game',
  'Account.Prefer', 'Account.Aboutus', 'Account.Feedback', 'Faq',
  'My'];

export default {
  data() {
    return {
      isScrolling: false,
      hideLoginTimestamp: LocalStorage.get('login-hide') || 0,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'loginStatus', 'notifications', 'feedbackUnread', 'submitInfoCount']),
    unreads() {
      const feedbback = this.feedbackUnread ?? 0;
      const submitInfo = this.submitInfoCount ?? 0;
      const coupon = this.notifications?.grant_coupon?.total ?? 0;
      return {
        trade: this.notifications?.unread_message?.total ?? 0,
        system: this.notifications?.unread_system_message?.total ?? 0,
        im: this.userInfo?.user_type === 2 ? 0
          : this.notifications?.unread_im_message?.total ?? 0,
        my: feedbback + submitInfo + coupon,
      };
    },
    hideLogin() {
      const isHide = this.isLogined
        || this.$route.name !== 'Index'
        || new Date().getTime() - DELAY < this.hideLoginTimestamp;
      if (!isHide) this.$logger('home_c2c_market_login_view');
      return isHide;
    },
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    isShow() {
      // APP内不显示底栏
      if (isInBuffClient()) return false;
      // 首页固定显示底栏
      if (this.$route.name === 'Index') return true;
      // 未登录情况只在首页显示底栏
      if (!this.isLogined) return false;
      // 滚动时不显示底栏
      if (this.isScrolling) return false;
      // 登录后在指定的页面显示底栏
      return SHOW_ROUTES.includes(this.$route.name);
    },
    routes() {
      return [
        { icon: 'home', name: 'Index', label: this.$t('lootBar.appbar_home') },
        { icon: 'messages', name: 'Messages', label: this.$t('lootBar.appbar_messages') },
        { icon: 'me', name: 'My', label: this.$t('lootBar.appbar_me') },
      ];
    },
  },
  watch: {
    isShow: {
      handler(val) {
        const height = val ? 48 : 0;
        document.documentElement.style.setProperty('--appbar-height', `${height}px`);
      },
      immediate: true,
    },
    isLogined: {
      handler(val) {
        const height = val ? 48 : 0;
        document.documentElement.style.setProperty('--appbar-fix-height', `${height}px`);
      },
      immediate: true,
    },
  },
  mounted() {
    this.handleScroll = () => {
      this.isScrolling = true;
      if (this.timer) window.clearTimeout(this.timer);
      this.timer = window.setTimeout(() => { this.isScrolling = false; }, 500);
    };
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    if (this.timer) window.clearTimeout(this.timer);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleHideLogin() {
      this.$logger('home_c2c_market_login_later_click');
      const cur = new Date().getTime();
      LocalStorage.set('login-hide', cur);
      this.$store.commit('SET_LOGIN_HIDE', cur);
      this.hideLoginTimestamp = cur;
    },
    handleLogin() {
      this.$logger('home_c2c_market_login_click');
      this.$guide('signin').catch(() => {});
    },
    handleNavigateTo(routeName) {
      const event = {
        Index: 'home_c2c_market_bottom_market_click',
        Messages: 'home_c2c_market_bottom_message_click',
        My: 'home_c2c_market_bottom_me_click',
      }[routeName];
      this.$logger(event);
      if (routeName !== 'Index') {
        try {
          this.$_waitCompleteProfile(ProfileGuideEnum.signin);
        } catch (err) {
          return;
        }
      }
      this.$router.push(this.$_localePath({ name: routeName }));
    },
  },
};
</script>

<style lang="less" scoped>
.c-app-bar {
  position: fixed;
  z-index: 999;
  bottom: 0; left: 0; right: 0;
  &-navs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: @tint_white;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.10),
      0 0 5px 0 rgba(0, 0, 0, 0.08),
      0 0 10px 0 rgba(0, 0, 0, 0.05);
    transition: padding-bottom 0.2s;
  }
  &-login {
    padding: 16px;
    background: fade(#000, 80%);
    display: flex;
    & > * {
      flex: 1 1 0;
      margin-right: 16px;
      height: 48px;
      &:last-child {
        margin-right: 0
      }
    }
  }
  &-item {
    display: flex;
    height: 48px;
    padding-top: 25px;
    position: relative;
    width: 33.3%;
    .item--icon-wrap {
      display: inline-block;
      position: absolute;
      left: 50%; top: -8px;
      transform: translate(-50%, 0);
      width: 40px; height: 40px;
      border-radius: 20px;
      z-index: 0;
      padding: 8px;
    }
    .item--icon {
      position: absolute;
    }
    .item--title {
      position: relative;
      z-index: 1;
      width: 100%;
      text-align: center;
    }
    .item--badge {
      position: absolute;
      background-color: @tint_error;
      width: 8px; height: 8px;
      border-radius: 4px;
      top: 4px; right: 4px;
      &.item--badge-number {
        min-width: 16px;
        width: auto; height: 16px;
        padding: 0 4px;
        border-radius: 8px;
        top: 4px; right: 12px;
        transform: translate(100%, 0);
        color: @label_dark_1;
        font-size: 12px; line-height: 16px;
        text-align: center;
        white-space: nowrap;
      }
    }
    &.is-active .item--icon-wrap {
      background-color: @tint_white;
      transform: translate(-50%, -4px);
      &::before {
        content: ' ';
        position: absolute;
        width: 32px; height: 32px;
        border-radius: 16px;
        background-color: #f7ce55;
        left: 4px; top: 4px;
        z-index: 0;
      }
    }
  }
}
.icon {
  display: inline-block;
}
</style>
