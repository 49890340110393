<template>
  <div class="cookie-agree" :class="containerClass">
    <div class="cookie-agree-hd display-space-between">
      <w-icon
        id="cookie_agree_close"
        name="icon_close"
        :size="20"
        class="cookie-pop-close"
        @click="handleClose" />
    </div>
    <div class="cookie-agree-bd">
      <p v-html="$t('global.agree_tips')"></p>
      <div class="cookie-agree-buttons">
        <div>
          <w-button
            id="btn_agree_cookie"
            class="btn-agree-cookie cookie-btn"
            type="primary"
            @click="handleClose">{{ $t("bargain.accept") }}</w-button>
          <w-button
            id="btn_reject_cookie"
            class="btn-agree-cookie cookie-btn"
            text
            @click="handleClose">{{ $t("bargain.refuse") }}</w-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalStorage from '@/utils/local-storage';

export default {
  name: 'cookie-policy',
  props: {
    handle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    containerClass() {
      return {
        show: !LocalStorage.get('COOKIE_POLICY') && this.show,
      };
    },
  },
  methods: {
    handleClose() {
      LocalStorage.set('COOKIE_POLICY', 1);
      this.show = false;

      if (this.handle) this.handle();
    },
  },
};
</script>

<style lang="less">
.cookie-agree {
  position: fixed;
  padding: 24px 32px;
  background: var(--bg-bg1);
  z-index: 999;
  bottom: 24px;
  left: 50%;
  right: 0;
  display: none;
  opacity: 0;
  transition: ease 0.3s opacity;
  color: var(--el-gf1);
  border-radius: 12px;
  max-width: 1200px;
  box-sizing: border-box;
  transform: translateX(-50%);
  width: 96%;
  max-height: 90vh;
  flex-direction: column;
  box-shadow: var(--shadow-3);

  &.inMobile {
    bottom: calc(48px + 24px + constant(safe-area-inset-bottom));
    bottom: calc(48px + 24px + env(safe-area-inset-bottom));
    border-radius: 12px;
  }

  &.show {
    display: flex;
    opacity: 1;
  }

  .cookie-agree-hd {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 16px;

    .cookie-pop-close {
      cursor: pointer;
    }
  }

  .cookie-agree-bd {
    justify-content: center;
    //flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
      color: transparent;
    }
  }

  .cookie-agree-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 32px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    word-break: break-word;
  }

  .btn-agree-cookie {
    display: inline-block;
    height: 34px;
    padding: 0 16px;
    line-height: 34px;
    margin-left: 12px;
  }
}
</style>
