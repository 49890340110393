import { i18n } from '@/utils/i18n';
import Axios from '@/utils/axios.ext';
import api from '@/api';

const EXTERNAL_PREFIX = 'external';

const plugin = {};

const cache = new Set();
const requests = new Set();
let timer = null;

const fetchI18nKeys = (keys) => {
  const lang = i18n.locale;
  return Axios({
    url: api.getI18n(),
    method: 'post',
    data: {
      i18n_keys: keys,
    },
  }).then((resp) => {
    const trans = resp.data.items;
    const messages = {};
    trans.forEach((item) => {
      messages[`${EXTERNAL_PREFIX}.${item.key}`] = item.value === '' ? ' ' : item.value;
      requests.delete(item.key);
    });
    i18n.mergeLocaleMessage(lang, messages);
    return trans;
  }).catch((err) => {
    keys.forEach((key) => {
      requests.delete(key);
    });
    return Promise.reject(err);
  });
};

const fetchI18nKeysFromCache = () => {
  const _cache = Array.from(cache);
  _cache.forEach((key) => {
    requests.add(key);
  });
  fetchI18nKeys(Array.from(_cache)).catch((err) => {
    console.error(err);
  });
};

const fetchI18n = (key) => {
  const _key = `${EXTERNAL_PREFIX}.${key}`;
  if (cache.has(key) || requests.has(key)) return;
  cache.add(key);
  const isExist = i18n.te(_key, 'en');
  // 没有加载英文翻译时避免漏出key
  if (!isExist) i18n.mergeLocaleMessage('en', { [_key]: '' });
  if (!timer) {
    timer = setTimeout(() => {
      fetchI18nKeysFromCache();
      cache.clear();
      timer = null;
    });
  }
};

plugin.install = (VueInstance) => {
  VueInstance.prototype.$et = (key, immediate) => {
    const _key = `${EXTERNAL_PREFIX}.${key}`;
    const isExist = i18n.te(_key);
    if (immediate) {
      if (isExist) return Promise.resolve(i18n.t(_key));
      return fetchI18nKeys([key]).then((trans) => {
        const matched = trans.find((item) => item.key === key);
        return matched ? matched.value : '';
      });
    }
    if (!isExist) fetchI18n(key);
    return i18n.t(_key);
  };
};

export default plugin;
