<template>
  <w-modal :show="show">
    <div class="dialog-coupon" :class="{[`dialog-coupon--type${color}`]: color}">
      <w-button
        class="dialog-coupon-close"
        flat icon name="icon_close" type="bg1"
        :icon-size="24"
        @click="handleClose" />
      <div v-if="type === 'type1'">
        <i class="dialog-coupon-left-ribbon" :style="{
          backgroundImage: extra.left_ribbon_url ? `url(${extra.left_ribbon_url})` : '' }"></i>
        <i class="dialog-coupon-right-ribbon" :style="{
          backgroundImage: extra.right_ribbon_url ? `url(${extra.right_ribbon_url})` : '' }"></i>
        <div class="dialog-coupon-header" :style="{
          backgroundColor: extra.header_bg_color,
          color: extra.header_text_color,
        }">
          <div class="dialog-coupon-box">
            <CouponList
              v-for="(item, index) in coupons" :key="index"
              :coupon="item" :bg="couponBg" :color="extra.coupon_text_color" />
          </div>
        </div>
        <div class="dialog-coupon-contbg" :class="{
          'is-countdown': !bonusText && remainTime,
          'is-empty': !bonusText && !remainTime,
        }" :style="{
          background: extra.header_bg_url
            ? `linear-gradient(180deg, ${extra.header_bg_color} 0%, #ffffff 100%)`
            : '',
        }">
          <i class="dialog-coupon-header-bg"
            :class="{ 'is-customized': extra.header_bg_url || extra.header_bg_url_m }"
            :style="{
              '--bg-url': extra.header_bg_url ? `url(${extra.header_bg_url})` : '',
              '--bg-url-m': extra.header_bg_url_m ? `url(${extra.header_bg_url_m})` : '',
            }"></i>
          <div v-if="bonusText" class="dialog-coupon-bonus font14r">
            <span class="title font14b" style="color: var(--fu-red1);">
              {{ $t('new_coupon.bonus') }}:
            </span>
            <span class="bonus-content" :style="{
              color: extra.header_text_color,
            }" v-html="bonusText"></span>
          </div>
          <countdown v-else-if="remainTime" :time-info="timeInfo" />
        </div>
        <div class="dialog-coupon-main">
          <div class="discount-title font15r">{{ desc }}</div>
          <div class="dialog-coupon-btn font16b"
            :style="{
              backgroundColor: extra.btn_bg_color,
              color: extra.btn_text_color,
            }"
            @click="handleConfirm">
            {{ confirmBtnText }}
            <img v-if="handShow" class="dialog-coupon-hand"
              src="@/assets/images/img_but_hand.png" alt="" />
          </div>
        </div>
      </div>
      <div v-else-if="type === 'type2'" class="dialog-coupon-invite">
        <div class="dialog-coupon-info">
          <div class="coupon-money" :class="{ long: isLongSumCouponText }">
            <span class="font24b">{{ currencySymbol }}</span>{{ sumCoupon | formatPrice }}
          </div>
          <div class="coupon-num font20b">{{ $t('$buy.coupon') }} * {{ coupons.length }}</div>
        </div>
        <div class="save-info">
          <div class="save-info-desc font15r">{{ desc }}</div>
          <div class="dialog-coupon-btn font16b" @click="handleConfirm">
            {{ confirmBtnText }}
            <img v-if="handShow" class="dialog-coupon-hand"
              src="@/assets/images/img_but_hand.png" alt="" />
          </div>
          <div class="give-up-btn font15r" @click="handleCancel">
            {{ cancelBtnText }}
            <w-icon name="icon_arrow_right" :size="16" />
          </div>
        </div>
      </div>
    </div>

  </w-modal>
</template>

<script>
import CouponList from './CouponList.vue';
import countdown from './countdown.vue';

export default {
  components: {
    CouponList,
    countdown,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'type1',
    },
    color: {
      type: String,
      default: '',
    },
    coupons: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    couponBg: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    confirmBtnText: {
      type: String,
      default: '',
      required: true,
    },
    cancelBtnText: {
      type: String,
      default: '',
    },
    needCancelBtn: {
      type: Boolean,
      default: false,
    },
    bonusText: {
      type: String,
      default: '',
    },
    remainTime: {
      type: Number,
      default: 0,
    },
    handShow: {
      type: Boolean,
      default: false,
    },
    /**
     * @left_ribbon_url
     * @right_ribbon_url
     * @header_bg_url
     * @header_bg_url_m
     * @header_bg_color
     * @header_text_color
     * @btn_bg_color
     * @btn_text_color
     */
    extra: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timeInfo: {
        hour: 0,
        minute: 0,
        second: 0,
      }, // 倒计时
    };
  },
  computed: {
    couponBoxHeight() {
      // 120 固定显示高度 124 每多一张增加的高度 36 有bonus_text文案时增加的高度
      const addHeight = this.inPc ? 124 : 106;
      const bonusHeight = this.inPc ? 30 : 20;
      const fixedHeight = this.inPc ? 120 : 100;
      const height = fixedHeight + addHeight * (this.coupons?.length - 1);
      if (this.bonusText) {
        return height + bonusHeight;
      }
      return height;
    },
    currencySymbol() {
      return this.$store.getters.currencySymbol;
    },
    isLongSumCouponText() {
      const amount = this.sumCoupon || 0;
      const { formatPrice } = this.$options.filters;
      const money = this.currencySymbol + formatPrice((amount));
      return money.length > 6;
    },
    sumCoupon() {
      let sum = 0;
      this.coupons.forEach((item) => {
        if (item.coupon_type === 1) {
          sum += item.coupon_setting?.max / 100;
        } else if (item.coupon_type === 2) {
          sum += item.coupon_setting?.off / 100;
        }
      });
      return sum;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.countdownTime();
      }
    },
  },
  mounted() {
    if (this.show) {
      this.countdownTime();
    }
  },
  methods: {
    countdownTime() {
      // 计算剩余时间
      let { remainTime } = this;
      this.countdown(remainTime);
      // 进行倒计时
      this.countdownInterval = setInterval(() => {
        this.countdown(remainTime);
        if (remainTime < 0) {
          clearInterval(this.countdownInterval);
        }
        remainTime -= 1;
      }, 1000);
    },
    countdown(remainTime) {
      // 将剩余时间转换为时分秒格式
      const remainingHours = Math.floor(remainTime / 3600);
      const remainingMinutes = Math.floor((remainTime % 3600) / 60);
      const remainingSeconds = remainTime % 60;
      this.timeInfo.hour = remainingHours >= 10 ? remainingHours : `0${remainingHours}`;
      this.timeInfo.minute = remainingMinutes >= 10 ? remainingMinutes : `0${remainingMinutes}`;
      this.timeInfo.second = remainingSeconds >= 10 ? remainingSeconds : `0${remainingSeconds}`;
    },
    handleClose() {
      this.$emit('close');
    },
    handleConfirm() {
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-coupon {
  width: 448px;
  // background-color: #fff;
  border-radius: 4px;
  position: relative;
  @media (max-width: 750px) {
    width: 336px;
    .dialog-coupon-left-ribbon,
    .dialog-coupon-right-ribbon {
      display: none;
    }
  }
  .dialog-coupon-left-ribbon {
    position: absolute;
    top: 82px;
    left: -42px;
    width: 50px;
    height: 140px;
    background: center/contain url("~@/assets/images/left_ribbon.png") no-repeat;
  }
  .dialog-coupon-right-ribbon {
    position: absolute;
    top: 50px;
    right: 0;
    width: 50px;
    height: 140px;
    transform: translateX(100%);
    background: center/contain url("~@/assets/images/right_ribbon.png") no-repeat;
  }
  &-close {
    width: 40px !important;
    height: 40px !important;
    border-radius: 20px;
    position: absolute;
    right: -56px;
    top: -3px;
    @media (max-width: 750px) {
      top: auto;
      bottom: -56px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    }
  }
  &-header {
    position: relative;
    width: 416px;
    background: #ffcc32;
    border-radius: 4px 4px 0 0;
    margin: 0 auto;
    .dialog-coupon-box {
      position: relative;
      width: 384px;
      margin: 0 auto;
      padding-top: 16px;
      padding-bottom: 10px;
      z-index: 2;
    }
    @media (max-width: 750px) {
      width: 318px;
      .dialog-coupon-box {
        width: 318px;
        padding-top: 16px;
      }
    }
  }
  &-contbg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 448px;
    height: 86px;
    border-radius: 32px 32px 0 0;
    background: linear-gradient(180deg, #ffcc32 0%, #ffffff 100%);
    position: relative;
    z-index: 1;
    &.is-countdown { margin-top: -36px; }
    &.is-empty { margin-top: -60px; }
    .dialog-coupon-header-bg {
      content: "";
      position: absolute; top: 0; left: 0;
      z-index: -1;
      width: 100%; height: 100%;
      background: center/cover no-repeat;
      background-image: url('~@/assets/images/new_reg_coupon.png');
      &.is-customized {
        background-image: var(--bg-url);
      }
    }
    @media (max-width: 750px) {
      &.is-countdown { margin-top: -30px; }
      &.is-empty { margin-top: -48px; }
    }
    .dialog-coupon-bonus {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      color: var(--el-gf1);
      padding: 0 32px;
      text-align: center;
      line-height: 20px;
      padding-bottom: 10px;
      .title {
        display: inline-flex;
        margin-right: 4px;
        margin-bottom: 2px;
      }
      .bonus-content {
        flex-wrap: wrap;
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 显示两行文本 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    @media (max-width: 750px) {
      position: relative;
      width: 336px;
      height: 64px;
      background: linear-gradient(180deg, #ffcc32 0%, #ffffff 100%);
      border-radius: 18px 18px 0 0;
      .dialog-coupon-header-bg {
        background: center/cover no-repeat;
        background-image: url('~@/assets/images/new_reg_coupon_m.png');
        &.is-customized {
          background-image: var(--bg-url-m);
        }
      }
    }
  }
  &-main {
    background: var(--fu-white1);
    border-radius: 0 0 4px 4px;
    padding: 16px;
    .discount-title {
      margin: 0 0 20px;
      color: var(--el-gf1);
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 显示两行文本 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dialog-coupon-btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 4px;
      background: var(--el-bc0);
      color: var(--fu-black1);
      cursor: pointer;
      &:hover {opacity: 0.7;}
      &:active {opacity: 0.3;}
      .dialog-coupon-hand {
        position: absolute;
        right: 20%;
        bottom: -15px;
        width: 46px;
        height: 46px;
        animation: handScale 1.5s ease-in-out infinite;
      }
    }
  }
  .dialog-coupon-invite {
    width: 448px;
    @media (max-width: 750px) {
      width: 336px;
    }
    .dialog-coupon-info {
      position: relative;
      height: 200px;
      background: url('~@/assets/images/reg_coupon_invite.png') no-repeat;
      background-size: 100% 100%;
      @media (max-width: 750px) {
        height: 182px;
      }
      .coupon-money {
        position: absolute;
        top: 30px;
        left: 50%;
        width: 195px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        transform: translateX(-50%);
        color: var(--fu-red1);
        font-size: 48px;
        font-weight: 600;
        &.long {
          font-size: 24px;
          span {
            font-size: 12px;
          }
        }
        @media (max-width: 750px) {
          top: 27px;
          left: 50%;
          width: 142px;
          height: 74px;
          font-size: 36px;
          span {
            font-size: 18px;
          }
        }
      }
      .coupon-num {
        position: absolute;
        top: 110px;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 195px;
        height: 50px;
        transform: translateX(-50%);
        color: var(--el-gf2);
        @media (max-width: 750px) {
          top: 105px;
          width: 142px;
          height: 40px;
          font-size: 15px;
        }
      }
    }
    .save-info {
      background: var(--fu-white1);
      border-radius: 0 0 4px 4px;
      padding: 0 16px 16px;
      color: var(--el-gf1);
      text-align: center;
      .save-info-desc {
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 显示两行文本 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .dialog-coupon-btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        background: var(--el-bc0);
        color: var(--fu-black1);
        border-radius: 4px;
        margin-top: 16px;
        cursor: pointer;
        &:hover {opacity: 0.7;}
        &:active {opacity: 0.3;}
        .dialog-coupon-hand {
          position: absolute;
          right: 20%;
          bottom: -15px;
          width: 46px;
          height: 46px;
          animation: handScale 1.5s ease-in-out infinite;
        }
      }
      .give-up-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        margin-top: 8px;
        color: var(--fu-blue1);
        padding-left: 16px;
        cursor: pointer;
        &:active {opacity: 0.3;}
      }
    }
  }
  // &-header {
  //   width: 100%;
  //   height: 180px;
  //   margin-top: -30px;
  //   padding: 56px 40px 0;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  //   white-space: pre-wrap;
  //   background: top/cover url('~@/assets/images/install_coupon_dialog_bg.png') no-repeat;
  //   :deep(b) {
  //     color: @tint_error;
  //     font-weight: 500;
  //   }
  //   p {
  //     .fontBold();
  //     :deep(span) {
  //       color:#EB4B4B;
  //     }
  //     &.inLast{
  //       color:rgba(31, 33, 40, 0.6);
  //     }
  //   }
  // }
  // &-content {
  //   margin: 20px 16px 12px;
  //   color: rgba(#1f2128, 0.6);
  //   text-align: center;
  // }
  &-action {
    padding: 8px 16px 12px;
    &>* {
      width: 100%;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }
  }
}
</style>
