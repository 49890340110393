/* eslint-disable */

import ua from './ua';

if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  };
}

HTMLElement.prototype.includeNode = function(node){
  var flag = false;
  var currentNode = node
  while (currentNode !== document.body) {
    if (currentNode === this) {
      flag = true;
      break;
    }
    currentNode = currentNode.parentNode;
  }
  return flag;
};

if (ua.os.android) {
  // 修复虚拟键盘弹出后不聚焦输入框
  window.addEventListener('resize', () => {
    if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
      window.setTimeout(() => {
        document.activeElement.scrollIntoViewIfNeeded();
      }, 0);
    }
  });
}

if (ua.os.ios) {
  // 修复失焦输入框后页面不回弹
  window.addEventListener('focusout', function () {
    setTimeout(()=>{
      window.scrollTo(0 ,document.documentElement.scrollTop || document.body.scrollTop);
    })
  });
}

if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (callback) {
    return this.then(
      value => Promise.resolve(callback()).then(() => value),
      reason => Promise.resolve(callback()).then(() => { throw reason })
    );
  };
}
