const { sessionStorage } = window;
let hasSessionStorage;
let _storage = {};

try {
  hasSessionStorage = ('sessionStorage' in window) && sessionStorage !== null;
  sessionStorage.setItem('test', 'test');
  sessionStorage.getItem('test');
  sessionStorage.removeItem('test');
} catch (e) {
  hasSessionStorage = false;
}

const SessionStorage = {

  get(key) {
    let obj;
    let value;
    if (hasSessionStorage) {
      value = sessionStorage.getItem(key);
    } else {
      value = _storage[key];
    }
    if (value) {
      try {
        obj = JSON.parse(value);
      } catch (e) { /* */ }
    }
    if (obj && obj.data !== undefined) {
      if (!('expire' in obj) || obj.expire > new Date()) {
        return obj.data;
      }
      this.remove(key);
    }
    return null;
  },

  set(key, value, expire) {
    const obj = { data: value };
    if (expire > 0) {
      obj.expire = +new Date() + expire;
    }
    const str = JSON.stringify(obj);
    if (hasSessionStorage) {
      sessionStorage.setItem(key, str);
    } else {
      _storage[key] = str;
    }
    return value;
  },

  remove(key) {
    if (hasSessionStorage) {
      sessionStorage.removeItem(key);
    } else {
      delete _storage[key];
    }
  },

  removeExpired() {
    if (hasSessionStorage) {
      Object.keys(sessionStorage).forEach((key) => {
        this.get(key);
      });
    } else {
      Object.keys(_storage).forEach((key) => {
        this.get(key);
      });
    }
  },

  clear() {
    if (hasSessionStorage) {
      sessionStorage.clear();
    } else {
      _storage = {};
    }
  },
};

if (hasSessionStorage) {
  SessionStorage.removeExpired();
}

export default SessionStorage;
