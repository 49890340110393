<template>
  <div class="c-autocomplete-dropdown"
    :style="{ width: `${width}px` }">
    <slot></slot>
  </div>
</template>

<script>
import Popper from '@/packages/popper';

export default {
  mixins: [Popper],
  data() {
    return {
      width: 0,
    };
  },
  mounted() {
    this.referenceElm = this.$parent.$refs.input.$el;
    // eslint-disable-next-line
    this.$parent.popperElm = this.popperElm = this.$el;
    this.currentPlacement = 'bottom-start';
    this.currentPopperModifiers = {
      // flip: {
      //   fallbackPlacements: ['top'],
      //   boundary: document.querySelector('#app'),
      // },
      preventOverflow: {
        altAxis: true,
        boundary: document.querySelector('#app'),
      },
    };
  },
};
</script>

<style lang="less" scoped>
.c-autocomplete-dropdown {
  box-shadow: var(--shadow-1);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99999;
  border-radius: 4px;
  overflow: hidden;
}
</style>
