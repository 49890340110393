<template>
  <component :is="selectedVariant" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import abtestcore from '@/utils/abtest/core';

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'ABTestComponent',
  props: {
    experimentName: {
      type: String,
      required: true,
    },
    variants: {
      type: Object,
      required: true,
    },
    uid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedVariant: null,
    };
  },
  watch: {
    uid: {
      handler(val) {
        abtestcore.setLayer('uid', val);
        let variant;
        try {
          variant = abtestcore.getVariant(this.experimentName);
        } catch (err) { /**  */ }
        this.selectedVariant = this.variants[variant] || this.variants.default || null;
      },
      immediate: true,
    },
  },
};
</script>
