import Axios from '@/utils/axios.feedback';
import api from '@/api';

const FB_PRODUCT_TAG = process.env.VUE_APP_FEEDBACK_PRODUCT;

Axios.interceptors.request.use((req) => {
  req.headers['FB-ProductTag'] = FB_PRODUCT_TAG;
  return req;
}, (err) => Promise.reject(err));

const state = {
  feedbackToken: '',
  feedbackLang: '',
  feedbackUnread: 0,
};

const getters = {
  feedbackUnread: (_state) => _state.feedbackUnread,
};
const mutations = {
  SET_FEEDBACK_TOKEN(_state, token) {
    _state.feedbackToken = token;
  },
  SET_FEEDBACK_LANG(_state, lang) {
    _state.feedbackLang = lang;
  },
  SET_FEEDBACK_UNREAD(_state, feedbackUnread) {
    _state.feedbackUnread = feedbackUnread;
  },
};
const actions = {
  initFeedbackLang({ commit }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackLangConf(),
    }).then((resp) => {
      const { lang } = resp.data;
      const matched = lang.langs.find((item) => item === navigator.language);
      const selectedLang = matched || lang.default;
      commit('SET_FEEDBACK_LANG', selectedLang);
      return resp.data;
    });
  },
  /**
   * 获取通用帮助的热门问题、问题类别和列表
   * @param {Number} page_num 页数
   */
  getFeedbackHelp(_, payload) {
    return Axios({
      method: 'get',
      url: api.getFeedbackHelp(),
      params: {
        product_tag: FB_PRODUCT_TAG,
        ...payload,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取通用帮助的问题详情
   * @param {Number} helpId 页数
   */
  getFeedbackHelpDetail(_, { helpId, searchId }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackHelpDetail(helpId),
      params: {
        product_tag: FB_PRODUCT_TAG,
        help_search_id: searchId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 搜索通用帮助的问题
   * @param {Number} q 搜索文字
   */
  getFeedbackHelpSearch(_, { q, cond }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackHelpSearch(),
      params: {
        product_tag: FB_PRODUCT_TAG,
        search_words: q,
        limit: 5,
        category_cond: cond,
      },
    }).then((resp) => resp.data);
  },
  getFeedbackProductCustomQuestions() {
    return Axios({
      method: 'get',
      url: api.getFeedbackProductCustomQuestions(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取用户反馈列表
   * @param {Number} page_num 页数
   */
  getFeedbackList({ commit }, { cursor, limit }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackList(),
      params: { cursor, limit, clear_all_unread: false },
    }).then((resp) => {
      commit('UPDATE_NOTIFICATION', { unread_feedback_replay: 0 });
      return resp.data;
    });
  },
  /**
   * 获取用户反馈详情
   * @param {String} id 反馈id
   */
  getFeedbackDetail({ dispatch }, { id }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackDetail(id),
      params: {
        clear_unread: true,
      },
    }).then((resp) => {
      dispatch('getFeedbackUnread');
      return resp.data;
    });
  },
  /**
   * 获取用户反馈未读列表
   */
  getFeedbackUnread({ commit }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackUnread(),
    }).then((resp) => {
      const feedbackUnread = resp.data.unread_list.length;
      commit('SET_FEEDBACK_UNREAD', feedbackUnread);
      return resp.data;
    });
  },
  /**
   * 用户提交反馈
   * @param {String} email 用户邮箱
   * @param {string} content 反馈内容
   * @param {string} file_url 反馈截图
   */
  postFeedbackAdd(_, { content, imgUrl, ...extra }) {
    const data = {
      content,
      'file_url[]': imgUrl,
      extra_questions: JSON.stringify(extra),
    };
    if (process.env.RELEASE_VERSION) {
      data.client_extra_info = JSON.stringify({
        'Fb-Extra-Info-Release-Version': process.env.RELEASE_VERSION,
      });
    }
    return Axios({
      method: 'post',
      url: api.postFeedbackAdd(),
      data,
    }).then((resp) => resp.data);
  },
  /**
   * 用户补充反馈
   * @param {String} id 反馈id
   * @param {string} content 反馈内容
   * @param {string} file_url 反馈截图
   */
  postFeedbackReply(_, { id, content, imgUrl }) {
    return Axios({
      method: 'post',
      url: api.postFeedbackReply(),
      data: { feedback_id: id, content, 'file_url[]': imgUrl },
    }).then((resp) => resp.data);
  },
  /**
   * 用户关闭反馈
   * @param {String} id 反馈id
   */
  postFeedbackClose(_, { id }) {
    return Axios({
      method: 'get',
      url: api.postFeedbackClose(id),
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
