<template>
  <div class="c-nav">
    <div class="c-nav-inner">
      <NavSlide v-if="isNavGamesInModile" class="c-nav-slide" />
      <router-link
        class="c-nav-logo-wrap"
        :to="$_localePath({ name: 'Index' })"
        @click.native="handleGoIndexClick">
        <i class="w-icon icon-logo is-interactive"></i>
      </router-link>
      <transition name="fadein">
        <div
          v-if="showAddBookmark && !deferredInstallPrompt"
          class="w-tooltips font16 c-nav-bookmark"
          data-popper-placement="bottom-start">
          <w-icon name="icon_desktop_bookmark" :size="16" />
          <span>{{ $t('bookmark.add_bookmark_shortcut', [bookmarkShortcut]) }}</span>
          <w-button type="primary" text size="l"
            @click="handleNoMorePromptAddBookmark">{{ $t('global.no_more_prompts') }}</w-button>
          <div data-popper-arrow></div>
        </div>
        <div
          v-else-if="showAddBookmark && deferredInstallPrompt"
          class="c-nav-install">
          <div class="font18b c-nav-install-title">
            {{ $t('global.install_prompt') }}
            <w-button icon name="icon_close" :icon-size="24"
              @click="handleCloseInstall" />
          </div>
          <div class="c-nav-install-tips">
            <img src="~@/assets/images/logo.png" alt="LootBar logo" />
            <p class="font16r-min tips" v-html="$t('global.install_prompt_tips')"></p>
          </div>
          <div class="c-nav-install-actions">
            <w-button plain
              @click="handleCancelInstall">
              {{ $t('global.later') }}</w-button>
            <w-button type="primary"
              @click="handleInstall">
              {{ $t('global.confirm') }}</w-button>
          </div>
        </div>
      </transition>
      <transition name="fadein">
        <div
          v-if="showNotSupportPush"
          class="w-tooltips font16 c-nav-bookmark"
          data-popper-placement="bottom-start">
          <span>{{ $t('lootBar.browser_not_support_push') }}</span>
          <w-button type="primary" text size="l"
            @click="handleNoMorePromptAddBookmark">{{ $t('global.no_more_prompts') }}</w-button>
          <div data-popper-arrow></div>
        </div>
      </transition>
      <div class="c-nav-route">
        <router-link class="c-nav-route-item home"
          :to="$_localePath({ name: 'Index' })"
          :class="{'is-active': $route.name === 'Index'}"
          @click.native="handleGoIndexClick">
          {{ $t('global.home') }}
        </router-link>
        <NavGames v-if="!isNavGamesInModile" class="c-nav-route-item" />
        <a class="c-nav-route-item" :href="blogUrl">{{ $t('global.blog') }}</a>
        <!-- <router-link v-for="route in routes" :to="route.to" :key="route.to"
          @mouseover.native="handleRouteMouseover($event, route)"
          @mouseleave.native="handleRouteMouseleave($event, route)"
          @click.native="handleRouteTo(route)">
          <w-button text size="m" type="normal-4"
            :id="route.title === $t('nav.help') && 'c-driver-help'">
            {{route.title}}</w-button>
          <span
            v-if="calcBadge(route.badgeKey) && isLogined"
            class="c-nav-route-badge">{{calcBadge(route.badgeKey)}}</span>
        </router-link> -->
      </div>
      <div class="c-nav-search" :class="{'is-active': focusing}">
        <w-button
          class="c-nav-search-button"
          @click="handleSearchBtnClick">
          <w-icon name="icon_home_search" :size="40" />
        </w-button>
        <Autocomplete
          slot="reference"
          ref="searchInput"
          v-model="search"
          transparent
          class="c-nav-search-inner"
          :placeholder="$t('lootBar.placehorder')"
          :clearable="!!search"
          :suggestions="suggestions"
          :delay="500"
          :z-index="10000"
          @focus="handleSearchInputFocus"
          @blur="handleSearchInputBlur"
          @keypress.native.enter="handleSearch"
          @select="handleSearch"
          @action="handleSearchAction"
          @clear="handleSearchClear">
          <w-icon
            slot="prefix"
            class="c-nav-search-icon" name="icon_home_search" :size="40"
            @click="handleSearchBtnClick" />
        </Autocomplete>
      </div>
      <!-- prefer -->
      <w-button class="c-nav-prefer"
        @click="isModalPreferShow = true;$logger('page_c2c_langcurrency_click')">
        {{ preferCurrency }}
        <span class="c-nav-prefer-gapline"></span>
        {{ preferLanguage }}
      </w-button>
      <div class="c-nav-account c-nav-account--desktop">
        <template v-if="loginStatus === LoginStatus.unknown">
          <w-spinner theme="normal-4" />
        </template>
        <template v-else-if="loginStatus === LoginStatus.unlogined
          || loginStatus === LoginStatus.unregistered">
          <w-button
            class="c-nav-sign" size="l"
            type="primary"
            @click="handleSignIn">
            {{ $t('global.sign_in') }}
          </w-button>
        </template>
        <template v-else-if="loginStatus === LoginStatus.logined">
          <div class="c-nav-userinfo">
            <!-- <router-link
              class="c-nav-userinfo-balance"
              :to="{name:'Account.MyBalance'}"
              @click.native="$logger('home_balance_click')">
              <div class="font12">{{$t('nav.my_balance')}}</div>
              <div class="font18">
                <span v-if="assetBrief">{{baseCurrencySymbol}}{{
                  assetBrief.cash_amount | formatUsd}}</span>
              </div>
            </router-link> -->
            <router-link class="c-nav-userinfo-msg" :to="$_localePath({name:routeMessagesName})">
              <w-button class="c-nav-userinfo-msg-inner">
                <div
                  v-if="notifications"
                  style="display: flex;align-items: center;justify-content: center;">
                  <w-icon name="icon_messages" :size="24" />
                  <i v-if="unreadMessage" class="userinfo-unread-badge"></i>
                </div>
                <w-spinner v-else size="s" />
              </w-button>
            </router-link>
            <div :key="randomkey" class="c-nav-userinfo-avatar">
              <router-link
                :to="$_localePath({name:'Account'})"
                class="c-nav-userinfo-avatar-wrap">
                <Avatar v-if="userInfo" :src="userInfo.profile_pic" />
                <w-spinner v-else size="s" theme="normal-3" />
              </router-link>
              <span
                v-if="unreadTotal"
                class="font14b-min userinfo-unread-number">
                {{ unreadTotal }}
              </span>
              <div class="userinfo-popover w-popover__inner">
                <i class="popover-pad"></i>
                <div class="popover-info">
                  <div v-if="userInfo" class="popover-info-content">
                    <div class="popover-info-section">
                      <Avatar class="popover-avatar"
                        :size="64" :src="userInfo&&userInfo.profile_pic" />
                      <span class="font16">{{ userInfo&&userInfo.nickname }}</span>
                    </div>
                    <div class="popover-info-point">
                      <router-link
                        class="point-button"
                        :to="$_localePath({name: 'Account.MyPoint.Task'})"
                        @click="$logger('c2c_profile_points_click', { uid: userInfo?.uid })">
                        <i></i>
                        <span class="font14r-min">{{ $t('account.point_nav_check_in') }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
                <ul class="popover-menu">
                  <router-link
                    v-for="menu in menus"
                    :key="menu.name" :to="$_localePath(menu.to)"
                    :target="menu.to.name === 'Faq' ? '_blank' : ''"
                    @click.native="randomkey=Math.random();$logger(menu.log, menu?.logData)">
                    <li style="position: relative;">
                      <span class="popover-menu-item-title"
                        style="display: inline-flex; align-items: center;">{{ menu.title }}
                      </span>
                      <span
                        v-if="menu.to.name === 'Account.MyBalance' && assetBrief"
                        class="c-nav-account-balance font16r-min font-fin">
                        {{ baseCurrencySymbol }}{{ assetBrief.cash_amount | formatUsd }}</span>
                      <span
                        v-if="menu.to.name === 'Account.MyPoint.Log' && assetBrief"
                        class="c-nav-account-point font16r-min font-fin">
                        <i></i>{{ assetBrief.point }}</span>
                      <span
                        v-if="menu.to.name === 'Account.Coupon' && unreadCoupon"
                        style="text-align: right;">
                        <div class="font14b-min c-nav-route-num">{{ unreadCoupon }}</div></span>
                      <span
                        v-if="menu.to.name === 'Account.Buy' && submitInfoCount"
                        style="text-align: right;">
                        <div class="font14b-min c-nav-route-num">{{ submitInfoCount }}</div></span>
                      <span
                        v-if="menu.to.name === 'Account.Feedback' && feedbackUnread"
                        style="text-align: right;">
                        <div class="font14b-min c-nav-route-num">{{ feedbackUnread }}</div></span>
                      <w-icon name="icon_arrow_right" :size="16" />
                    </li>
                  </router-link>
                  <li
                    :class="{'is-disabled': logoutLoading}"
                    @click="handleLogout">
                    <span>{{ $t('global.log_out') }}</span>
                    <w-spinner v-if="logoutLoading" size="s" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div
        v-if="submenuShow || submenuHover"
        class="c-nav-submenu w-popover__inner"
        :style="{left: `${submenuLeft}px` }"
        @mouseover="submenuHover=true"
        @mouseleave="submenuHover=false">
        <ul class="popover-menu">
          <template v-for="(item, index) in submenu">
            <a
              v-if="item.to" :key="index"
              :href="item.to" :target="item.target"
              @click="handleRouteTo(route)">
              <li>
                <span>{{ item.title }}</span>
                <w-icon name="icon_home_arrow" :size="16" />
              </li>
            </a>
            <a v-else-if="item.method" :key="index" href="javascript:void(0);"
              @click="item.method">
              <li @click="handleRouteTo(route)">
                <span>{{ item.title }}</span>
                <w-icon name="icon_home_arrow" :size="16" />
              </li>
            </a>
            <a v-else :key="index" href="javascript:void(0);">
              <li>
                <span>{{ item.title }}</span>
                <w-icon name="icon_home_arrow" :size="16" />
              </li>
              <div class="w-popover__inner">
                <ul class="popover-menu">
                  <a
                    v-for="(item, index) in item.submenu" :key="index"
                    :href="item.to" :target="item.target"
                    @click="handleRouteTo(route)">
                    <li>
                      <span>{{ item.title }}</span>
                      <w-icon name="icon_home_arrow" :size="16" />
                    </li>
                  </a>
                </ul>
              </div>
            </a>
          </template>
        </ul>
      </div>
    </div>
    <!-- 导航条指引或者提醒用户区域 -->
    <slot name="GuidanceZone"></slot>
    <slot></slot>
    <ModalPrefer
      :show="isModalPreferShow"
      @confirm="isModalPreferShow = false"
      @close="isModalPreferShow = false" />
  </div>
</template>

<script>
import localStorage from '@/utils/local-storage';
import { TASK, startupRunner } from '@/utils/startup';
import {
  GameType, TextType, BlogUrlEnumLanguage, BlogUrlEnum,
} from '@/enums';
import { getEndOfTime, isGoogleBot } from '@/utils';
import mixin from './Nav.mixin.c2c';
import Avatar from '../Avatar.vue';

import NavGames from './components/Nav/Games.desktop.vue';
import NavSlide from './components/Nav/NavSlide.vue';

export default {
  components: {
    Avatar,
    NavGames,
    NavSlide,
  },
  mixins: [mixin],
  provide: {
    theme: 'dark',
  },
  data() {
    return {
      GameType,

      submenu: [],
      submenuLeft: 0,
      submenuShow: false,
      submenuHover: false,

      TEST_ENV: process.env.VUE_APP_CHECKOUT === 'test',

      noMorePromptAddBookmark: true,
      noMorePromptNotSupportPush: true,
      installPromptClosed: false,
      isNavGamesInModile: window.innerWidth < 1080,
    };
  },
  computed: {
    activityStyle() {
      if (this.activities.length === 0) return {};
      return {
        '--background-image': `url(${this.hoverActivity.extra.thumbnail_url})`,
        '--background-image-hover': `url(${this.hoverActivity.extra.hovered_thumbnail_url})`,
      };
    },
    blogUrl() {
      const blogUrl = BlogUrlEnumLanguage[this.textType] || BlogUrlEnumLanguage[TextType.en];
      const urlParamsData = {
        utm_source: this.$route.query.utm_source || '',
        utm_medium: this.$route.query.utm_medium || '',
        utm_campaign: this.$route.query.utm_campaign || '',
      };
      const url = new URL(blogUrl, BlogUrlEnum);
      const urlParams = new URLSearchParams(url.search);
      Object.keys(urlParamsData).forEach((key) => {
        if (urlParamsData[key]) {
          urlParams.set(key, urlParamsData[key]);
        }
      });
      url.search = urlParams.toString();
      return url.href;
    },
    routes() {
      const routes = [
        { to: '/market/all', title: `回market${this.$t('nav.market')}` },
      ];
      const submenu = [
        { to: '/faq', title: this.$t('nav.faq') },
        { to: '', method: this.handleFeedback, title: this.$t('nav.contact_support') },
        { to: '/static/user_agreement.html', title: this.$t('nav.terms_of_service'), target: '_blank' },
        { to: '/static/privacy_policy.html', title: this.$t('nav.privacy_policy'), target: '_blank' },
        { to: '/static/cookie_policy.html', title: this.$t('nav.cookie_policy'), target: '_blank' },
        {
          to: '',
          title: this.$t('nav.more_information'),
          submenu: [
            { to: '/static/shipping_policy.html', title: this.$t('nav.shipping_policy'), target: '_blank' },
            { to: '/static/about_us.html', title: this.$t('nav.about_us'), target: '_blank' },
            { to: '/static/contact_us.html', title: this.$t('nav.contact_us'), target: '_blank' },
            { to: '/static/law_jp.html', title: '特定商取引法に基づく', target: '_blank' },
          ],
        },
      ];
      routes.push({
        to: '/faq',
        title: this.$t('nav.help'),
        submenu,
      });
      return routes;
    },
    subgames() {
      return this.$store.getters.subgames;
    },
    subgame() {
      return this.$store.getters.subgame;
    },
    userSubgame() {
      return this.$store.getters.userSubgame;
    },
    unreadMessage() {
      if (!this.notifications) return false;
      return this.unreadTradeMessage
        + this.unreadSystemMessage
        + this.unreadImMessage > 0;
    },
    routeMessagesName() {
      if (!this.unreadMessage || this.unreadTradeMessage) return 'Messages.Trade';
      return 'Messages.System';
    },
    // 页面title
    titleType() {
      return {
        [GameType.knivesout]: 'Knivesout',
      };
    },
    bookmarkShortcut() {
      const platform = navigator.userAgentData?.platform
        || navigator.platform || '';
      const isMacOS = platform.toUpperCase().indexOf('MAC') >= 0;
      return `${isMacOS ? 'CMD' : 'Ctrl'}+D`;
    },
    showAddBookmark() {
      const { isInStandalone } = this.$store.state;
      const { name } = this.$route;
      const isActiveRoute = ['Index', 'Topup'].includes(name);
      return !isInStandalone
        && !this.noMorePromptAddBookmark
        && !this.installPromptClosed
        && isActiveRoute;
    },
    deferredInstallPrompt() {
      return this.$store.state.deferredInstallPrompt;
    },
    showNotSupportPush() {
      const { name } = this.$route;
      const isActiveRoute = ['Seller.Order'].includes(name);
      const { isSupported } = this.$fcm;
      return !this.noMorePromptNotSupportPush && !isSupported && isActiveRoute;
    },
    showPwaInstall() {
      return this.showAddBookmark && this.deferredInstallPrompt;
    },
  },
  watch: {
    showPwaInstall: {
      handler(val) {
        if (val) this.$logger('c2c_pc_pwa_install_popup');
      },
      immediate: true,
    },
  },
  created() {
    this.$router.onReady(async () => {
      startupRunner.register(TASK.GuideInstallPwa, () => {
        if (isGoogleBot()) return Promise.reject();
        // 一天只显示一次安装提示
        const map = localStorage.get('no-more-prompts') || {};
        const cur = new Date().getTime();
        this.noMorePromptAddBookmark = cur <= map.addBookmark;
        this.noMorePromptNotSupportPush = cur <= map.notSupportPush;
        return Promise.resolve();
      });
    });
    window.addEventListener('resize', this.handleNavGamesInMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleNavGamesInMobile);
  },
  methods: {
    handleRouteMouseover(e, { submenu }) {
      if (!submenu) this.submenu = [];
      const { left } = e.target.getBoundingClientRect();
      this.submenu = submenu;
      this.submenuLeft = left;
      this.submenuShow = true;
    },
    handleRouteMouseleave() {
      window.setTimeout(() => {
        this.submenuShow = false;
      });
    },
    handleNoMorePromptAddBookmark() {
      const map = localStorage.get('no-more-prompts') || {};
      // 一周后再次提示
      const offset = 1000 * 60 * 60 * 24 * 7;
      const timestamp = getEndOfTime(new Date().getTime() + offset, 'day');
      map.addBookmark = timestamp;
      localStorage.set('no-more-prompts', map);
      this.noMorePromptAddBookmark = true;
    },
    handleNoMorePromptNotSupportPush() {
      const map = localStorage.get('no-more-prompts') || {};
      const timestamp = getEndOfTime(new Date().getTime(), 'day');
      map.notSupportPush = timestamp;
      localStorage.set('no-more-prompts', map);
      this.noMorePromptNotSupportPush = true;
    },
    handleInstall() {
      this.$logger('c2c_pc_pwa_install_popup_install_click');
      this.installPromptClosed = true;
      this.$_handleInstall();
    },
    handleCancelInstall() {
      this.$logger('c2c_pc_pwa_install_popup_cancel_click');
      this.handleNoMorePromptAddBookmark();
    },
    handleCloseInstall() {
      this.$logger('c2c_pc_pwa_install_popup_close_click');
      this.handleNoMorePromptAddBookmark();
    },
    handleNavGamesInMobile() {
      if (window.innerWidth < 1080) {
        this.isNavGamesInModile = true;
      } else {
        this.isNavGamesInModile = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@height: 80px;

.c-nav {
  height: @height;
  position: relative;
  z-index: 9999;

  &-inner {
    height: @height;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 32px;
    background-color: @tint_light_2;
  }

  &-logo {
    &-wrap {
      flex: 0 0 82px;
      display: inline-block;
      line-height: 0;
    }
  }

  &-bookmark {
    position: absolute;
    z-index: 999;
    top: 80px;
    left: 32px;
    transform: translateY(16px);
    max-width: none;
    padding: 12px 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: rgba(#fff, 0.6);
    span {
      margin: 0 24px 0 4px;
    }
    [data-popper-arrow] {
      width: 16px; height: 16px;
      left: 32px; top: -8px;
    }
  }

  &-install {
    position: absolute;
    z-index: 999;
    right: 280px; top: 80px;
    min-width: 360px;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.20);
    background-color: @tint_white;
    &-title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-tips {
      display: flex;
      align-items: center;
      max-width: 360px;
      img {
        width: 64px; height: 64px;
        margin-right: 12px;
        flex: 0 0 auto;
      }
      .tips {
        color: rgba(#1f2128, 0.6);
        :deep(b) {
          color: @tint_error;
          font-weight: 500;
        }
      }
    }
    &-actions {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      & > * {
        margin-left: 8px;
        min-width: 120px;
        &.w-button--normal {
          color: rgba(#1f2128, 0.6);
          border-color: rgba(#1f2128, 0.25);
        }
      }
    }
  }

  &-route {
    flex: 1 1 0;
    white-space: nowrap;
    overflow: hidden;
    & > * {
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      height: @height;
      margin-right: 44px;
      position: relative;
      @media (max-width: 1280px) {
        margin-right: 16px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &-item {
      color: #858585;
      cursor: pointer;
      font-size: 16px;
      padding: 0 18px;
      &:hover {
        color: var(--el-wf1);
        opacity: 1;
      }
      &.home {
        margin-left: 80px;
        @media (max-width: 1280px) {
          margin-left: 40px;
        }
      }
      &.is-active {
        color: var(--el-bc0);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 30px;
          background: var(--el-bc0);
          height: 4px;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 4px;
        }
        &:hover {
          color: var(--el-bc0);
        }
      }
    }
    &-badge {
      display: inline-block;
      height: 16px;
      margin-left: 4px;
      font-size: 14px;
      line-height: 16px;
      color: @label_light_1;
      background-color: @tint_primary_2;
      padding: 0 4px;
      border-radius: 2px;
    }
    &-num {
      display: inline-block;
      height: 16px;
      min-width: 16px;
      margin-left: 4px;
      padding: 0 4px;
      color: #fcfcfc;
      background-color: #eb4b4b;
      border-radius: 8px;
      text-align: center;
    }
    &-unread {
      position: absolute;
      right: 0;
      top: 50%;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: @tint_error;
      border: 2px solid @tint_dark_3;
      box-sizing: border-box;
      transform: translate(100%, -100%);
    }
  }

  &-game {
    min-width: 48px;
    width: auto;
    height: 48px;
    margin-right: 16px;
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
    &-option {
      display: inline-flex;
      align-items: center;
      color: @label_dark_4;
      width: 100%;
      &:hover {
        color: @label_dark_3;
      }
    }
    .w-icon {
      width: 32px; height: 32px;
    }
  }

  &-subgame {
    min-width: 48px;
    width: auto;
    height: 48px;
    margin-right: 16px;
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
    &-option {
      display: inline-flex;
      align-items: center;
      color: @label_light_1;
      font-weight: bold;
      width: 100%;
    }
  }

  &-prefer {
    .font16;
    height: 48px;
    color: @label_dark_1;
    background-color: fade(@tint_white, 5%);
    font-weight: 600;
    margin-right: 16px;
    transition: all 0.15s ease;
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
    &-gapline {
      height: 16px;
      width: 1px;
      background: @tint_dark_2;
      margin: 0 7.5px;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: @label_dark_1;
    }
  }

  &-search {
    flex: 0 0 auto;
    position: relative;
    height: 48px;
    margin-right: 16px;
    &-button {
      width: 40px !important;
      height: 40px !important;
      background-color: transparent;
      min-width: auto;
      padding: 0;
      line-height: 0;
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 1;
      display: none;
    }
    &-inner {
      width: 240px;
      height: 48px;
      position: absolute;
      right: 0;
      top: 0;
      transition: width 0.3s;
      &::after {
        content: '';
        background-color: @tint_light_2;
        position: absolute;
        inset: 0;
        z-index: 0;
      }
      :deep(&) > * {
        z-index: 1;
      }
    }
    @media (max-width: 1440px) {
      &-inner {
        width: 48px;
      }
      &-icon {
        cursor: pointer;
      }
      &.is-active &-inner {
        width: 240px;
        transition: width 0.5s;
      }
    }
    @media (max-width: 1360px) {
      margin-right: 12px;
    }
  }

  &-userinfo {
    flex: 0 0 auto;
    display: flex;
    & > * {
      margin-right: 16px;
      @media (max-width: 1360px) {
        margin-right: 12px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .userinfo-unread {
      &-badge {
        position: absolute;
        z-index: 8001;
        right: 9px;
        top: 12px;
        width: 8px; height: 8px;
        border-radius: 4px;
        background-color: var(--fu-red1);
        box-sizing: border-box;
        pointer-events: none;
      }
      &-number {
        position: absolute;
        z-index: 8001;
        right: 0;
        top: 0;
        padding: 0 4px;
        border-radius: 8px;
        min-width: 16px;
        text-align: center;
        background-color: var(--fu-red1);
        color: var(--fu-white1);
        box-sizing: border-box;
        pointer-events: none;
      }
    }
    .userinfo-count {

    }
    &-balance {
      height: 48px;
      padding: 4px 16px 0;
      box-sizing: border-box;
      background-color: fade(@tint_white, 5%);
      border-radius: 2px;
      color: @label_light_4!important;
      white-space: nowrap;
      &:hover { opacity: 0.7; }
      &:active { opacity: 0.3; }
      span {
        color: @label_primary_2;
      }
    }
    &-msg {
      width: 48px;
      height: 48px;
      &-inner {
        background-color: fade(@tint_white, 5%);
        position: relative;
        z-index: 8000;
        min-width: auto;
        width: 48px;
        height: 48px;
        padding: 0;
        color: @label_light_4;
        &:hover .userinfo-unread {
          border: 2px solid @tint_dark_4;
        }
      }
      .userinfo-unread {
        right: 9px;
        top: 12px;
      }
    }
    &-avatar {
      position: relative;
      cursor: pointer;
      border-radius: 24px;
      &-wrap {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid @tint_light_4;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        z-index: 8000;
        & > i {
          width: 48px;
          height: 48px;
          box-sizing: border-box;
          display: block;
          margin: -1px;
          background: center/contain no-repeat;
        }
      }
      .userinfo-popover {
        display: none;
        background-color: @tint_light_3;
        position: absolute;
        z-index: 7999;
        top: 48px+16px;
        right: 0;
        cursor: default;
        width: 296px;
        li:hover .userinfo-unread {
          border: 2px solid @tint_light_4;
        }
        .userinfo-unread {
          top: 4px;
          right: 4px;
        }
        .store-state {
          display: inline-block;
          border-radius: 2px;
          width: 47px;
          height: 18px;
          color: #ebebf5;
          font-size: 12px;
          text-align: center;
          line-height: 18px;
        }
        .online {
          background: #7ed321;
        }
        .offline {
          background: #888888;
        }
      }
      &:hover .userinfo-popover {
        display: block;
      }
    }
  }

  &-sign {
    flex: 0 0 auto;
    height: 48px;
    min-width: 240px;
    &:hover {
      background: @tint_primary_1;
    }
  }

  &-submenu {
    position: absolute;
    min-width: 196px;
    top: 80px;
  }

  @media (max-width: 1079px) {
    &-route {
      position: relative;
      z-index: -1;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
      }

      * {
        opacity: 0;
      }
    }

    &-search.is-active ~ &-route {
      display: none;
    }
  }
}
.popover {
  &-pad {
    width: 100%;
    height: 64px;
    position: absolute;
    top: -64px;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  }
  &-info {
    // height: 126px;
    text-align: center;
    color: @label_dark_4;
    display: flex;
    align-items: center;
    padding: 0 16px;
    & > * {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    h2,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        color: @label_dark_3;
        margin-right: 4px;
      }
      .popover-info-icon {
        color: @tint_light_4;
      }
      &:hover span {
        color: @label_dark_1;
      }
      &:hover .popover-info-icon {
        color: @label_dark_2;
      }
      .popover-info-store {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        height: 18px;
        width: 47px;
        background: @tint_light_3;
        color: @tint_light_1;
        border-radius: 2px;
        margin-right: 7px;
        &.online {
          background: @tint_success;
        }
      }
    }

    .popover-info-content {
      width: 100%;

      &, .popover-info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .popover-info-section {
        .popover-avatar {
          margin-right: 0;
        }

        span {
          margin-top: 4px;
          color: var(--el-wf1);
        }
      }

      .popover-info-point {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        padding: 12px;
        cursor: pointer;

        .point-button {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid var(--el-wf3);
          border-radius: 100px;

          i {
            margin-right: 5px;
            width: 16px;
            height: 16px;
            background: center/contain url("@/assets/images/icon_emoji_money.png") no-repeat;
          }

          span {
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--el-wf1);
          }
        }
      }
    }
  }
  &-avatar {
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: center/contain no-repeat;
    margin-right: 12px;
  }
  &-menu {
    background-color: @tint_light_3;
    border-radius: 4px;
    a {
      white-space: nowrap;
      display: block;
      position: relative;
      span { margin-right: 8px; }
      & > .w-popover__inner {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
      }
      &:hover > .w-popover__inner {
        display: block;
      }
    }
    li {
      .font16;
      border-radius: 4px;
      padding: 16px;
      display: flex;
      align-items: center;
      color: @label_dark_2;
      cursor: pointer;
      &:hover {
        color: @label_dark_3;
        background-color: #444;
      }
      .popover-menu-item-content {
        color: @label_dark_4;
      }
      .c-nav-account-balance,
      .c-nav-account-point {
        color: var(--el-wf1);
      }
      .c-nav-account-point {
        display: flex;
        align-items: center;

        i {
          margin-right: 4px;
          width: 20px;
          height: 20px;
          background: url("@/assets/images/icon_point_logomini.png") no-repeat center;
          background-size: 100%;
        }
      }
      .popover-menu-item-title {
        flex: 1 1 0;
        color: @label_dark_2;
      }
      &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
</style>
