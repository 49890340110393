import Vue from 'vue';
import Big from 'big.js';
import store from '@/store';
import filters from '@/filters';
import mixins from '@/mixins';
import plugins from '@/plugins';
import directive from '@/directive';
import {
  isMobile,
  guid,
  isInBuffClient,
  loadScriptWithRetr,
} from '@/utils';
import { init as initSensors } from '@/utils/sensors';
import { i18n } from '@/utils/i18n';
import native from '@/utils/native.bridge';
import LocalStorage from '@/utils/local-storage';
import ABTest from '@/utils/abtest/coupler/vue';

import desktopPackages from './packages/index.desktop';
import mobilePackages from './packages/index.mobile';

import desktopEntry from './Entry.desktop.vue';
import desktopRouter from './router/desktop';
import mobileEntry from './Entry.mobile.vue';
import mobileRouter from './router/mobile';

import './utils/polyfill';
import './utils/axios.ext';
import './assets/less/main.less';
import 'default-passive-events';

import './registerServiceWorker';

// 全局Big.js
window.Big = Big;

Vue.config.productionTip = false;
Vue.prototype.$native = native;

const deviceId = guid();
if (!LocalStorage.get('device-id')) {
  LocalStorage.set('device-id', deviceId);
}
// uniq-id可能会跟随服务端返回的值变化
if (!LocalStorage.get('ps-uniq-id')) {
  LocalStorage.set('ps-uniq-id', guid());
}

ABTest.init({
  experiments: `${process.env.PUBLIC_PATH}static/ab.json`,
  did: deviceId,
});

Vue.use(filters);
Vue.use(mixins);
Vue.use(plugins);
Vue.use(directive);
Vue.use(ABTest.plugin);

if (isMobile()) {
  Vue.use(mobilePackages);
  new Vue({
    router: mobileRouter,
    store,
    i18n,
    render: (h) => h(mobileEntry),
  }).$mount('#app');
} else {
  Vue.use(desktopPackages);
  new Vue({
    router: desktopRouter,
    store,
    i18n,
    render: (h) => h(desktopEntry),
  }).$mount('#app');
}

if (isInBuffClient()) {
  document.body.className += ' in-app';
}

// google analytics
// if (process.env.VUE_APP_GA) {
//   window.dataLayer = window.dataLayer || [];
//   // eslint-disable-next-line
//   function gtag() { window.dataLayer.push(arguments); }
//   gtag('js', new Date());
//   gtag('config', 'G-Z7XZ1D87HS');
// }

// 获取Forter token
document.addEventListener('ftr:tokenReady', (evt) => {
  Vue.prototype.$forter = evt.detail;
});
window.addEventListener('load', () => {
  // forter
  loadScriptWithRetr(`${process.env.PUBLIC_PATH}static/assets/js/forter.min.js`);
  // sentry
  if (process.env.NODE_ENV === 'production' && process.env.RELEASE_VERSION) {
    import('@sentry/vue').then((Sentry) => {
      Sentry.init({
        Vue,
        dsn: 'https://38caa592c2804856a9103107ea2985f8@sentry.guinfra.com/41',
        environment: process.env.VUE_APP_SENTRY_ENV || process.env.VUE_APP_DEPLOY_ENV || 'production',
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(
              isMobile() ? mobileRouter : desktopRouter,
            ),
          }),
        ],
        beforeSend: (event, hint) => {
          const { type } = hint.originalException || {};
          if (type === 'net_error' || type === 'canceled') {
            return null;
          }
          return event;
        },
        release: process.env.RELEASE_VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,
        ignoreErrors: [
          'ResizeObserver loop completed with undelivered notifications',
          'googletagmanager',
        ],
      });
    });
  }
  // fcount
  loadScriptWithRetr(`${process.env.PUBLIC_PATH}static/assets/js/fcount-1.1.0.sdk.min.js`);
  // sensorsdata
  loadScriptWithRetr(`${process.env.PUBLIC_PATH}static/assets/js/sensorsdata.min.js`);
  // google analytics
  loadScriptWithRetr(`https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_TRACKING_ID}`);
});
initSensors({
  name: 'sensors',
  server_url: process.env.VUE_APP_SC_SERVER_URL,
  heatmap: { scroll_notice_map: 'not_collect' },
  show_log: false,
  is_track_single_page: true,
  use_client_time: true,
  send_type: 'beacon',
});
window.sensors.quick('autoTrack');

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  store.commit('SET_DEFERRED_INSTALL_PROMPT', e);
});

const handleResize = (() => {
  let timer = null;
  let cur = isMobile();
  return function () {
    if (timer) return;
    timer = setTimeout(() => {
      if (cur !== isMobile()) {
        cur = isMobile();
        document.body.style.opacity = 0;
        window.location.reload();
      }
      timer = null;
    }, 1e3);
  };
})();
window.addEventListener('resize', handleResize);

// 客户端内统一使用客户端打开新窗口
if (isInBuffClient()) {
  window.addEventListener('click', (e) => {
    if (e.target.tagName === 'A' && e.target.href && e.target.target === '_blank') {
      const url = e.target.href;
      native('window_open', { url }, { timeout: -1 });
      e.preventDefault();
    }
  });
}
