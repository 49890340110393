/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { isStandalone } from '@/utils';

if (process.env.NODE_ENV === 'production' && isStandalone()) {
  register('/service-worker.js', {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered(registration) {
      // 30s检查一次新版
      setInterval(() => { registration.update().catch(() => {}); }, 30e3);
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      document.dispatchEvent(new CustomEvent('swUpdated'));
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
