import Vue from 'vue';
import Toast from './toast.vue';

const ToastCtor = Vue.extend(Toast);
const _toasts = [];

function updateToastPos() {
  const len = _toasts.length;
  for (let i = 0; i < len; i += 1) {
    _toasts[i].offset = len - 1 - i;
  }
}

const ToastInstance = (type) => (text, delay = 4000) => {
  let instance = null;
  const timer = null;
  instance = new ToastCtor({
    el: document.createElement('div'),
  });
  instance.text = text;
  instance.type = type;
  document.body.appendChild(instance.$el);
  instance.show = true;
  // loading状态则不进入其它状态的处理
  if (type === 'loading') {
    instance.lastTime = +new Date();
    instance.timer = window.setTimeout(() => {
      instance.show = false;
      instance = null;
      window.clearTimeout(timer);
    }, 2000);
    return instance;
  }
  _toasts.push(instance);
  if (_toasts.length > 3) { // 最多显示三个
    const [prevInstance] = _toasts.splice(0, 1);
    prevInstance.show = false;
    window.clearTimeout(prevInstance.timer);
  }
  window.setTimeout(() => {
    updateToastPos();
  });
  instance.timer = window.setTimeout(() => {
    const index = _toasts.indexOf(instance);
    _toasts.splice(index, 1);
    updateToastPos();
    instance.show = false;
    instance = null;
    window.clearTimeout(timer);
  }, delay);
  return instance;
};

ToastInstance.install = (VueInstance) => {
  VueInstance.prototype.$toast = ToastInstance();
  VueInstance.prototype.$Message = {
    success: ToastInstance('success'),
    error: ToastInstance('error'),
    info: ToastInstance('info'),
    loading: ToastInstance('loading'),
  };
};

export default ToastInstance;
