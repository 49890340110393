import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {};

const getters = {};
const mutations = {};
const actions = {
  getActivityDetailEva() {
    return Axios({
      method: 'get',
      url: api.getActivityDetailEva(),
    }).then((resp) => resp.data);
  },
  postFinishTask(_, { payload }) {
    return Axios({
      method: 'post',
      url: api.postFinishTask(),
      data: payload,
    }).then((resp) => resp.data);
  },
  postLottery() {
    return Axios({
      method: 'post',
      url: api.postLottery(),
    }).then((resp) => resp.data);
  },
  getLotteryHistory() {
    return Axios({
      method: 'get',
      url: api.getLotteryHistory(),
    }).then((resp) => resp.data);
  },
};

const module = {
  state,
  getters,
  mutations,
  actions,
};

export default module;
