const navigateListeners = [];

(() => {
  const oldPushState = window.history.pushState;
  window.history.pushState = function pushState(...args) {
    const ret = oldPushState.apply(this, args);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  };

  const oldReplaceState = window.history.replaceState;
  window.history.replaceState = function replaceState(...args) {
    const ret = oldReplaceState.apply(this, args);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  };

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'));
  });
})();

window.addEventListener('locationchange', () => {
  navigateListeners.forEach((listener) => {
    listener();
  });
});

export const addNavigateListener = (fn) => {
  if (navigateListeners.includes(fn)) return;
  navigateListeners.push(fn);
};

export const removeNavigateListener = (fn) => {
  const index = navigateListeners.indexOf(fn);
  if (index === -1) return;
  navigateListeners.splice(index, 1);
};
