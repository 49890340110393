class EventEmitter {
  cacheFns = {};

  emit(type, ...payload) {
    const fns = this.cacheFns[type];
    if (!fns) return;
    fns.forEach((fn) => {
      fn(...payload);
    });
  }

  on(type, cb) {
    const fns = this.cacheFns[type] || [];
    fns.push(cb);
    this.cacheFns[type] = fns;
  }

  removeAllListeners() {
    this.cacheFns = {};
  }
}

export default EventEmitter;
