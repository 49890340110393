import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {
  waitDeliverNum: 0,
  waitConfirmDeliverNum: 0,
  paySuccessNum: 0,
};

const getters = {
  waitDeliverNum: (_state) => _state.waitDeliverNum,
  waitConfirmDeliverNum: (_state) => _state.waitConfirmDeliverNum,
  paySuccessNum: (_state) => _state.paySuccessNum,
};
const mutations = {
  SET_WAIT_DELIVER_NUM(_state, waitDeliverNum) {
    _state.waitDeliverNum = waitDeliverNum;
  },
  SET_WAIT_CONFIRM_DELIVER_NUM(_state, waitConfirmDeliverNum) {
    _state.waitConfirmDeliverNum = waitConfirmDeliverNum;
  },
  SET_PAY_SUCCESS_NUM(_state, paySuccessNum) {
    _state.paySuccessNum = paySuccessNum;
  },
};
const actions = {
  /**
   * 获取sell历史
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  getMarketSellOrderHistory({ rootState }, {
    search, filters, page,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketSellOrderHistory(),
      params: {
        ...filters,
        search,
        game: rootState.game,
        page_num: page.pageNum,
        page_size: page.pageSize,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取在售货品列表
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  getMarketSelling({ rootState }, {
    search, sortBy, filters, page,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketSelling(),
      params: {
        ...filters,
        search,
        game: rootState.game,
        sort_by: sortBy,
        page_num: page.pageNum,
        page_size: page.pageSize,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 下架
   * @param {string} sell_orders 上架记录ID列表
   */
  cancelSellOrder({ rootState }, { sellOrders } = {}) {
    return Axios({
      method: 'post',
      url: api.cancelSellOrder(),
      data: {
        game: rootState.game,
        sell_orders: sellOrders,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 下架全部
   */
  cancelAllSellOrder({ rootState }, { game } = {}) {
    return Axios({
      method: 'post',
      url: api.cancelAllSellOrder(),
      data: {
        game: rootState.game || game,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 单个取消发货
   * @param {string} billOrderId 订单ID
   */
  // cancelOrderDeliver({ rootState }, {
  //   billOrderId,
  // } = {}) {
  //   return Axios({
  //     method: 'post',
  //     url: api.cancelOrderDeliver(),
  //     data: {
  //       game: rootState.game,
  //       bill_order_id: billOrderId,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 单个取消发货
   * @param {string} billOrderId 订单ID
   */
  // cancelOrderDeliverPreview({ rootState }, {
  //   billOrderId,
  // } = {}) {
  //   return Axios({
  //     method: 'get',
  //     url: api.cancelOrderDeliverPreview(),
  //     params: {
  //       game: rootState.game,
  //       bill_order_id: billOrderId,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 改价
   * @param {string} sellOrders 订单信息
   */
  changeSellOrderPrice({ rootState }, { sellOrders } = {}) {
    return Axios({
      method: 'post',
      url: api.changeSellOrderPrice(),
      data: {
        game: rootState.game,
        sell_orders: sellOrders,
      },
    }).then((resp) => resp.data.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
