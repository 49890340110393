import Vue from 'vue';
import { DialogState } from '@/enums';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import ABTest from '@/utils/abtest/coupler/vue';
import desktopRouter from '@/router/desktop';
import mobileRouter from '@/router/mobile';

let Verify = null;
let promise = null;

const VerifyCreator = (store) => async (noguide) => {
  if (promise) return promise;
  if (!Verify) {
    ABTest.setLayer('uid', store.getters.userInfo?.uid);
    Verify = (await ABTest.asyncGetABTestComponent({
      experimentName: 'kyc_guide',
      variants: {
        legacy: () => import('./verify.legacy.vue'),
        new: () => import('./verify.vue'),
        default: () => import('./verify.vue'),
      },
    })()).default;
  }
  const VerifyCtor = Vue.extend(Verify);
  const instance = new VerifyCtor({
    el: document.createElement('div'),
    propsData: { noguide },
    store,
    i18n,
    router: isMobile() ? mobileRouter : desktopRouter,
  });
  instance.show = true;
  instance.promise = new Promise((resolve, reject) => {
    instance.$on('close', (state) => {
      instance.show = false;
      if (state === DialogState.confirm) resolve();
      else reject();
      promise = null;
    });
  });
  promise = instance.promise;
  return promise;
};

export default VerifyCreator;
