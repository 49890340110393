import padLeft from './pad-left';

export default function formatTime(timestamp, format, timezoneOffset) {
  const _format = (format && typeof (format) === 'string') ? format : 'M DD, YY HH:FF:SS';
  const time = timezoneOffset !== undefined
    ? new Date(timestamp + (timezoneOffset * 60 + new Date().getTimezoneOffset()) * 60 * 1e3)
    : new Date(timestamp);
  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const timeObj = {
    year: time.getFullYear(),
    month: time.getMonth() + 1,
    day: time.getDate(),
    hour: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
  };
  return _format.replace(/YY/g, timeObj.year)
    .replace(/yy/g, timeObj.year)
    .replace(/mm/g, timeObj.month)
    .replace(/dd/g, timeObj.day)
    .replace(/hh/g, timeObj.hour)
    .replace(/ff/g, timeObj.minutes)
    .replace(/ss/g, timeObj.seconds)
    .replace(/MM/g, padLeft(timeObj.month, 2))
    .replace(/M/g, monthList[timeObj.month - 1])
    .replace(/DD/g, padLeft(timeObj.day, 2))
    .replace(/HH/g, padLeft(timeObj.hour, 2))
    .replace(/FF/g, padLeft(timeObj.minutes, 2))
    .replace(/SS/g, padLeft(timeObj.seconds, 2));
}
