class AudioPlayer {
  audios = {};

  async play(audioName) {
    let audio = this.audios[audioName];
    if (!audio) {
      const { default: src } = await import(`@/assets/audio/${audioName}`);
      if (!src) throw new Error(`Audio ${audioName} not found`);
      audio = new Audio(src);
      this.audios[audioName] = audio;
    }
    if (!audio.paused) {
      audio.currentTime = 0;
    } else {
      audio.play();
    }
  }
}

export default new AudioPlayer();
