import localStorage from './local-storage';

export default class InputHistory {
  constructor(key, len = 10) {
    this.key = key;
    this.maxlength = len;
    this.list = localStorage.get(key) || [];
  }

  add(value) {
    const list = this.list.slice();
    const index = list.indexOf(value);
    if (index === -1) {
      list.unshift(value);
    } else {
      list.splice(index, 1);
      list.unshift(value);
    }
    this.list = list.slice(0, this.maxlength);
    localStorage.set(this.key, this.list);
  }

  remove(value) {
    const list = this.list.slice();
    const index = list.indexOf(value);
    if (index === -1) return;
    list.splice(index, 1);
    this.list = list;
    localStorage.set(this.key, this.list);
  }

  removeByIndex(index) {
    const list = this.list.slice();
    if (index < 0 || index >= list.length) return;
    list.splice(index, 1);
    this.list = list;
    localStorage.set(this.key, this.list);
  }

  clear() {
    this.list = [];
    localStorage.set(this.key, this.list);
  }
}
