<template>
  <LoginC2C v-if="chosenType === ProfileGuideEnum.signin
              && isMobile && !game"
    :show="show"
    @close="handleClose"
    @login="handleSignIn" />
  <Login v-else-if="chosenType === ProfileGuideEnum.signin"
    :show="show"
    @close="handleClose"
    @login="handleSignIn" />
  <BindKnivesoutSelect v-else-if="chosenType === ProfileGuideEnum.bindKnivesout"
    :show="show"
    @close="handleClose" />
  <w-modal
    v-else-if="chosenType === ProfileGuideEnum.addBookmarkSafari"
    :show="show"
    @close="handleClose">
    <div class="guide-install-safari-tips">
      <p class="font16" v-html="$t('bookmark.add_bookmark_safari.step0')"></p>
      <h1 class="font14">{{ $t('global.step_n', ['1']) }}</h1>
      <p class="font16">{{ $t('bookmark.add_bookmark_safari.step1') }}
        <w-icon name="icon_safari_share" :size="24" /></p>
      <h1 class="font14">{{ $t('global.step_n', ['2']) }}</h1>
      <p class="font16">{{ $t('bookmark.add_bookmark_safari.step2') }}
        <w-icon name="icon_safari_add" :size="24" /></p>
      <w-button class="guide-install-safari-tips-close" type="primary" text
        @click="handleClose">
        {{ $t('deposit.llpay_deposit_still_processing.confirm') }}</w-button>
    </div>
  </w-modal>
  <w-modal
    v-else-if="chosenType === ProfileGuideEnum.openInBrowserTiktok"
    :show="show"
    @close="handleClose">
    <div class="guide-open-browser-tiktok">
      <i class="w-icon icon_arrow_tiktok"></i>
      <div class="guide-open-browser-tiktok-tips tiktok">
        <h1 class="font14">{{ $t('global.step_n', ['1']) }}</h1>
        <p
          class="font16"
          v-html="$t('bookmark.add_bookmark_tiktok.step1',
                     {icon:'<span class=\'tiktok-icon\'>···</span>'})"></p>
        <h1 class="font14">{{ $t('global.step_n', ['2']) }}</h1>
        <p class="font16">{{ $t('bookmark.add_bookmark_tiktok.step2') }}</p>
        <w-button class="guide-open-browser-tiktok-tips-close" type="primary" text
          @click="handleClose">
          {{ $t('deposit.llpay_deposit_still_processing.confirm') }}</w-button>
      </div>
    </div>
  </w-modal>
  <w-dialog v-else
    class="guide-dialog"
    wrap-class="guide-dialog-inner"
    :show="show"
    :title="title"
    :need-confirm-btn="false"
    :need-cancel-btn="false"
    @close="handleClose">
    <!-- signin -->
    <template v-if="chosenType === ProfileGuideEnum.signin">
      <div class="guide-dialog-logo"><i class="w-icon icon-logo"></i></div>
      <p class="guide-dialog-tips">
        {{ $t(`global.sign_in_through_${loginChannel ? loginChannel : 'line'}_tips`) }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary"
        @click="handleSignIn(loginChannel)">
        <template v-if="loginChannel === 'facebook'">
          <w-icon
            name="icon_home_login_facebook" :size="24"
            style="margin-right: 8px" />{{ $t('global.sign_in_through_facebook') }}
        </template>
        <template v-else-if="loginChannel === 'twitter'">
          <w-icon
            name="icon_home_login_twitter" :size="24"
            style="margin-right: 8px" />{{ $t('global.sign_in_through_twitter') }}
        </template>
        <template v-else>
          <w-icon
            name="icon_home_login_line" :size="24"
            style="margin-right: 8px" />{{ $t('global.sign_in_through_line') }}
        </template>
      </w-button>
    </template>
    <!-- email -->
    <template v-else-if="chosenType === ProfileGuideEnum.email
      || chosenType === ProfileGuideEnum.emailSupport">
      <div class="guide-dialog-form">
        <div class="form-item">
          <w-input
            v-model="email"
            class="form-item--input"
            :error="emailError"
            :placeholder="$t('guide.placeholder.email')"
            @focus="emailError=false" />
        </div>
        <div class="form-item">
          <w-input
            ref="authcodeInput"
            v-model="authcode"
            class="form-item--input"
            :error="authcodeError"
            :placeholder="$t('guide.placeholder.code')"
            :formatter="formatter"
            @focus="authcodeError=false"
            @keypress.native.enter="handleVerifyEmail">
            <w-button
              slot="suffix"
              text type="primary"
              :disabled="!email || !!cd"
              :loading="sendLoading"
              @click="handleSendEmail">{{ sendText }}</w-button>
          </w-input>
          <p class="form-item--tips">{{ $t('global.authcode_tips') }}</p>
        </div>
        <p class="form--tips">
          <template v-if="chosenType === ProfileGuideEnum.email">
            {{ $t('guide.email_verify_tips') }}
          </template>
          <template v-else>
            {{ $t('guide.email_support_tips') }}
          </template>
        </p>
      </div>
      <w-button
        class="guide-dialog-button"
        type="primary"
        :disabled="!email || !authcode || comfirmTextCd"
        :loading="loading"
        @click="handleVerifyEmail">{{ confirmText }}</w-button>
    </template>
    <!-- bind google -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindGoogle">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.google') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        @click="handleBindGoogle">
        <w-icon
          name="icon_home_login_google" :size="24"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_google') }}
      </w-button>
    </template>
    <!-- bind facebook -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindFacebook">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.facebook') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        @click="handleBindFacebook">
        <w-icon
          name="icon_home_login_facebook" :size="24"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_facebook') }}
      </w-button>
    </template>
    <!-- bind twitter -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindTwitter">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.twitter') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        @click="handleBindTwitter">
        <w-icon
          name="icon_home_login_twitter" :size="24"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_twitter') }}
      </w-button>
    </template>
    <!-- bind line -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindLine">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.line') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        @click="handleBindLine">
        <w-icon
          name="icon_home_login_line" :size="24"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_line') }}
      </w-button>
    </template>
    <!-- bind apple -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindApple">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.apple') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        :disabled="!appleCanUse"
        @click="handleBindApple">
        <w-icon
          name="icon_home_login_apple" :size="24"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_apple') }}
      </w-button>
    </template>
    <!-- bind tiktok -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindTiktok">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.tiktok') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        @click="handleBindTiktok">
        <w-icon
          name="icon_home_login_tiktok" :size="24"
          class="icon-tiktok"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_tiktok') }}
      </w-button>
    </template>
    <!-- bind vk -->
    <template v-else-if="chosenType === ProfileGuideEnum.bindVk">
      <div class="guide-dialog-logo">
        <i class="w-icon icon-logo"></i>
      </div>
      <p class="guide-dialog-tips">
        {{ $t('global.bind_tips.vk') }}
      </p>
      <w-button
        class="guide-dialog-button"
        type="primary" :loading="loading"
        @click="handleBindVk">
        <w-icon
          name="icon_home_login_vk" :size="24"
          style="margin-right: 8px" />{{ $t('global.sign_in_through_vk') }}
      </w-button>
    </template>
  </w-dialog>
</template>

<script>
import { formatter, isMobile, loadScriptWithRetr } from '@/utils';
import localStorage from '@/utils/local-storage';
import {
  EmailSendDelay, DialogState, ProfileGuideEnum, GameType, TextType, LoginStatus,
} from '@/enums';
import regexp from '@/utils/regexp';

export default {
  components: {
    Login: () => ({
      component: import('@/views/components/Login/Index.vue'),
    }),
    LoginC2C: () => ({
      component: import('@/views/components/Login/Index.c2c.vue'),
    }),
    BindKnivesoutSelect: () => ({
      component: import('@/views/components/BindKnivesoutSelect.vue'),
    }),
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      validator(value) {
        return ProfileGuideEnum[value];
      },
    },
    params: Object,
  },
  data() {
    return {
      ProfileGuideEnum,
      loginChannel: localStorage.get('login-channel'),

      // 提交倒计时
      comfirmTextCd: 0,

      // type
      chosenType: '',

      // email
      email: '',
      emailError: false,
      authcode: '',
      authcodeError: false,
      cd: 0,
      sended: false,
      sendLoading: false,
      formatter: formatter.authcode,

      // Knivesout
      knivesoutId: '',
      knivesoutToken: '',
      knivesoutError: false,

      // common
      loading: false,

      // wechat
      wechat: null,
      state: '',

      // apple sdk
      appleCanUse: false,

    };
  },
  computed: {
    game() {
      return this.$store.state.game;
    },
    confirmText() {
      let text = this.$t('global.confirm');
      if (this.comfirmTextCd) {
        text = this.$t('account.recheck_tips', { second: this.comfirmTextCd });
      }
      return text;
    },
    TitleEnum() {
      return {
        [ProfileGuideEnum.signin]: this.$t('global.sign_in'),
        [ProfileGuideEnum.email]: this.$t('guide.setup_your_email'),
        [ProfileGuideEnum.emailSupport]: this.$t('guide.setup_your_email'),
        [ProfileGuideEnum.bindGoogle]: `${this.$t('global.bind')} Google`,
        [ProfileGuideEnum.bindFacebook]: `${this.$t('global.bind')} Facebook`,
        [ProfileGuideEnum.bindTwitter]: `${this.$t('global.bind')} Twitter`,
        [ProfileGuideEnum.bindLine]: `${this.$t('global.bind')} Line`,
        [ProfileGuideEnum.bindApple]: `${this.$t('global.bind')} Apple`,
        [ProfileGuideEnum.bindTiktok]: `${this.$t('global.bind')} Tiktok`,
        [ProfileGuideEnum.bindVk]: `${this.$t('global.bind')} VK`,
        [ProfileGuideEnum.bindKnivesout]: `${this.$t('global.bind')} Knivesout`,
      };
    },
    title() {
      return this.TitleEnum[this.chosenType];
    },
    sendText() {
      let text = this.$t(`guide.${this.sended ? 'resend' : 'send'}`);
      if (this.cd) {
        text = `(${this.cd})${text}`;
      }
      return text;
    },
    textType() {
      return this.$store.getters.textType;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    isLootbar() {
      return !this.$store.getters.gameType;
    },
    isMobile() {
      return isMobile();
    },
    isEn() {
      return this.textType === TextType.en;
    },
  },
  mounted() {
    this.chosenType = this.type;
    window.addEventListener('message', this.handlePostBindSteam, false);
    // 若是去绑定apple帐号，要去加载apple sdk
    if (this.chosenType === ProfileGuideEnum.bindApple) {
      this.getAppleSdk();
    } else if (this.chosenType === ProfileGuideEnum.email
      || this.chosenType === ProfileGuideEnum.emailSupport) {
      this.$logger('page_profile_email_send_view');
    }
  },
  methods: {
    getAppleSdk() {
      if (window.AppleID) {
        this.appleCanUse = true;
        return;
      }
      loadScriptWithRetr('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js').then(() => {
        this.appleCanUse = true;
      }).catch(() => {
        this.appleCanUse = false;
      });
    },
    handleClose(state = DialogState.close) {
      window.removeEventListener('message', this.handlePostBindSteam);
      this.$emit('close', state);
      if (state === DialogState.close) {
        if (this.chosenType === ProfileGuideEnum.email
          || this.chosenType === ProfileGuideEnum.emailSupport) {
          this.$logger('page_profile_email_send_view_close_click');
        }
      }
    },
    /**
     * @description: 重选登录渠道
     * @param {*}
     */
    handleReSelectLogin() {
      this.$store.dispatch('postAppLogout');
      this.handleClose('re-select');
      this.$guide(ProfileGuideEnum.signin).catch(() => {});
    },
    /**
     * @description: 登录
     * @param {string} channel 登录渠道（steam fb wx）
     * @param {string} game 选择游戏
     * @param {object} params 携带参数
     */
    async handleSignIn(channel = 'facebook', params) {
      // 邮箱登录的直接返回登录成功
      if (channel === 'email') {
        this.handleClose(DialogState.confirm);
        return;
      }
      this.$reg(channel, { ...params, ...this.params }).then((state) => {
        if (state === LoginStatus.logined) {
          this.handleClose(DialogState.confirm);
        } else {
          this.handleClose();
        }
      });
    },
    countdown() {
      const timer = window.setInterval(() => {
        this.cd -= 1;
        if (this.cd === 0) {
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    handleEmailError(err) {
      const { error } = err.data;
      if (/email/i.test(error)) {
        this.emailError = true;
      } else {
        this.authcodeError = true;
      }
      this.$_handleError(err);
    },
    async handleSendEmail() {
      this.sendLoading = true;
      this.$logger('page_profile_email_send_click');
      this.$refs.authcodeInput.focus();
      if (!this.email || !regexp.email.test(this.email)
        || !regexp.emailLength.test(this.email)) {
        this.emailError = true;
        this.sendLoading = false;
        this.$toast(this.$t('account.email_error'));
        return;
      }
      try {
        await this.$store.dispatch('postEmailSendAuthcode', {
          email: this.email,
        });
        await this.$store.dispatch('fetchUserInfo');
      } catch (err) {
        this.sended = true;
        if (err.data?.data?.remain_time) {
          this.cd = err.data?.data?.remain_time;
          this.countdown();
        }
        this.sendLoading = false;
        this.handleEmailError(err);
        return;
      }
      this.sended = true;
      this.cd = EmailSendDelay;
      this.countdown();
      this.sendLoading = false;
    },
    async handleVerifyEmail() {
      this.loading = true;
      this.$logger('page_profile_email_confirm_click');
      if (!this.email || !regexp.email.test(this.email)
        || !regexp.emailLength.test(this.email)) {
        this.emailError = true;
        this.loading = false;
        this.$toast(this.$t('account.email_error'));
        return;
      }
      if (!this.authcode || !regexp.authcode.test(this.authcode)) {
        this.authcodeError = true;
        this.loading = false;
        return;
      }
      try {
        await this.$store.dispatch('postEmailVerifyAuthcode', {
          email: this.email, authcode: this.authcode,
        });
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.email = this.email;
      } catch (err) {
        this.loading = false;
        if (err.data?.data?.remain_time) {
          this.comfirmTextCd = err.data.data.remain_time;
          const timer = setInterval(() => {
            if (this.comfirmTextCd === 0) {
              clearInterval(timer);
              return;
            }
            this.comfirmTextCd -= 1;
          }, 1000);
        }
        this.handleEmailError(err);
        return;
      }
      this.loading = false;
      this.handleClose(DialogState.confirm);
    },
    /**
     * @description: 处理绑定google
     * @param {string} token
     */
    async bindGoogle(e) {
      if (e.origin !== window.location.origin) return;
      if (e.data.op !== 'bind-google') return;
      try {
        const data = await this.$store.dispatch('postGoogleBind', e.data.data);
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.google_name = data.google_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    handleBindGoogle() {
      const url = '/authorization/google';
      const ref = window.open(url, '_blank', 'width=800,height=800');
      window.addEventListener('message', this.bindGoogle, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', this.bindGoogle, false);
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    /**
     * @description: 处理绑定apple
     * @param {string} idToken
     * @param {string} name
     */
    async bindApple({ idToken, name }) {
      try {
        const data = await this.$store.dispatch('postAppleBind', { idToken, name });
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.apple_name = data.apple_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    /**
     * @description: 绑定Apple
     * @param {*}
     */
    handleBindApple() {
      if (!window.AppleID) {
        return;
      }
      window.AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLE_ID,
        scope: 'name email',
        redirectURI: `${window.location.origin}/authorization/apple/verify`,
        state: '',
        nonce: '',
        usePopup: true,
      });
      window.AppleID.auth.signIn().then((data) => {
        const { authorization: { id_token: idToken } } = data;
        const name = data.user ? `${data.user.name.firstName} ${data.user.name.lastName}` : 'Apple User';
        this.bindApple({ idToken, name });
      }).catch((error) => {
        console.log(error);
      });
    },
    /**
     * @description: 处理绑定Tiktok
     * @param {string} idToken
     * @param {string} name
     */
    async bindTiktok(e) {
      if (e.origin !== window.location.origin) return;
      if (e.data.op !== 'bind-tiktok') return;
      try {
        const data = await this.$store.dispatch('postTiktokBind', e.data.data);
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.tiktok_name = data.tiktok_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    /**
     * @description: 绑定Tiktok
     * @param {*}
     */
    handleBindTiktok() {
      this.$logger('page_profile_tiktok_bind_confirm_click');
      const url = '/authorization/tiktok';
      const ref = window.open(url, '_blank', 'width=800,height=800');
      window.addEventListener('message', this.bindTiktok, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', this.bindTiktok, false);
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    /**
     * @description: 处理绑定facebook
     * @param {string} token
     */
    async bindFacebook(e) {
      if (e.origin !== window.location.origin) return;
      if (e.data.op !== 'bind-facebook') return;
      try {
        const data = await this.$store.dispatch('postFacebookBind', e.data.data);
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.facebook_name = data.facebook_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    /**
     * @description: 绑定Facebook
     * @param {*}
     */
    handleBindFacebook() {
      const url = '/authorization/facebook';
      const ref = window.open(url, '_blank', 'width=800,height=800');
      window.addEventListener('message', this.bindFacebook, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', this.bindFacebook, false);
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    /**
     * @description: 处理绑定twitter
     * @param {string} token
     */
    async bindTwitter(e) {
      if (e.origin !== window.location.origin) return;
      if (e.data.op !== 'bind-twitter') return;
      try {
        const data = await this.$store.dispatch('postTwitterBind', e.data.data);
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.twitter_name = data.twitter_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    handleBindTwitter() {
      this.$logger('page_profile_twitter_bind_confirm_click');
      const url = '/authorization/twitter';
      const ref = window.open(url, '_blank', 'width=800,height=800');
      window.addEventListener('message', this.bindTwitter, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', this.bindTwitter, false);
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    /**
     * @description: 处理绑定line
     * @param {string} token
     */
    async bindLine(e) {
      if (e.origin !== window.location.origin) return;
      if (e.data.op !== 'bind-line') return;
      try {
        const data = await this.$store.dispatch('postLineBind', e.data.data);
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.line_name = data.line_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    handleBindLine() {
      this.$logger('page_profile_line_bind_confirm_click');
      const url = '/authorization/line';
      const ref = window.open(url, '_blank', 'width=800,height=800');
      window.addEventListener('message', this.bindLine, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', this.bindLine, false);
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    /**
     * @description: 处理绑定Vk
     * @param {string} slient_token
     * @param {string} uuid
     */
    async bindVk(e) {
      if (e.origin !== window.location.origin) return;
      if (e.data.op !== 'bind-vk') return;
      try {
        const data = await this.$store.dispatch('postVkBind', e.data.data);
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.vk_name = data.vk_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
      }
    },
    /**
     * @description: 绑定Vk
     * @param {*}
     */
    handleBindVk() {
      this.$logger('page_profile_vk_bind_confirm_click');
      const url = '/authorization/vk';
      const ref = window.open(url, '_blank', 'width=800,height=800');
      window.addEventListener('message', this.bindVk, false);
      const timer = window.setInterval(() => {
        if (ref === null || ref.closed) {
          window.removeEventListener('message', this.bindVk, false);
          window.clearInterval(timer);
        }
      }, 1e3);
    },
    /**
     * @description: 绑定第三方游戏角色
     * @param {*}
     */
    async handleBindKnivesout() {
      this.loading = true;
      const game = GameType.knivesout;
      try {
        await this.$store.dispatch('postPartnerRoleBind', {
          game,
          id: this.knivesoutId,
          token: this.knivesoutToken,
        });
        this.$store.dispatch('fetchUserInfo');
      } catch (err) {
        this.$_handleError(err);
        this.loading = false;
        this.knivesoutError = true;
        return;
      }
      this.loading = false;
      this.handleClose(DialogState.confirm);
    },
    /**
     * @description: 绑定微信
     * @param {object} e 子窗口数据
     */
    async handleBindWechat(e) {
      const { type, state, code } = e.data;
      if (type !== 'Wechat' || state !== this.state) return;
      try {
        const data = await this.$store.dispatch('postWechatBind', { code });
        await this.$store.dispatch('fetchUserInfo');
        this.userInfo.wechat_name = data.wechat_name;
        this.handleClose(DialogState.confirm);
      } catch (err) {
        this.$_handleError(err);
        this.handleClose(DialogState.close);
      }
    },
  },
};
</script>

<style lang="less" scoped>

.guide-install-safari-tips {
  z-index: 999999;
  padding: 12px 16px;
  color: @label_white;
  background-color: fade(@tint_black, 80%);
  position: fixed;
  width: 95%;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  h1 {
    color: rgba(#fff, 0.6);
    margin-top: 16px;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    margin-bottom: 8px;
    svg {
      vertical-align: bottom;
    }
    :deep(b) {
      color: @tint_error;
      font-weight: 500;
    }
  }
  &-close {
    float: right;
  }
  &::after {
    content: ' ';
    position: absolute;
    left: 50%;
    bottom: -6px;
    width: 12px;
    height: 12px;
    background-color: @tint_black;
    transform-origin: center center;
    z-index: -1;
    transform: translate(-50%, 0) rotate(45deg);
  }
}

.guide-open-browser-tiktok {
  padding: 12px 16px;
  color: @label_white;
  background-color: fade(@tint_black, 80%);
  left: 50%;
  transform: translate(-50%, 0);
  width: 95%;
  position: fixed;
  top: 40px;
  border-radius: 4px;
  .icon_arrow_tiktok {
    right: 16px;
    top: -26px;
    position: fixed;
    z-index: 1000000
  }
  h1 {
    color: rgba(#fff, 0.6);
    margin-top: 16px;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    margin-bottom: 8px;
    svg {
      vertical-align: bottom;
    }
  }
  &-tips-close {
    float: right;
  }
  // .guide-install-safari-tips {
  //   position: fixed;
  //   top: 37px;
  //   bottom: auto;
  //   &-close {
  //
  //   }
  // }
  :deep(.tiktok-icon) {
    // color: red;
    display: inline-block;
    background: rgba(255, 255, 255, 0.22);
    width: 22px;
    text-align: center;
    border-radius: 4px;
    margin: 0 3px;
  }
}
.guide-dialog {
  padding: 16px;
  :deep(&-inner) {
    width: 100%;
    max-width: 456px;
    @media (max-width: 750px) {
      .w-dialog__close {
        top: 100%;
        left: 50%;
        transform: translate(-50%, 16px);
      }
      .w-dialog__header h1 {
        .font18;
      }
    }
  }
  p {
    .font16;
    a {
      color: @label_primary_2;
      &:hover,
      &:active {
        color: @label_primary_1;
      }
    }
  }
  &-logo {
    text-align: center;
    margin: 12px 0 48px;
    line-height: 0;
    svg {
      width: 136px;
      height: 73px;
      color: @tint_primary_1;
    }
    @media (max-width: 750px) {
      display: none;
    }
  }
  &-fig {
    text-align: center;
    margin: -8px 0 16px;
    line-height: 0;
    i {
      display: inline-block;
      width: 154px;
      height: 120px;
      background: center/contain no-repeat;
    }
    &.fig--not-sealed i {
      background-image: url("~@/assets/images/img_boxnotsealed.png");
      margin-bottom: 12px;
    }
    &.fig--open i {
      background-image: url("~@/assets/images/img_boxopen.png");
      margin-bottom: 12px;
    }
    &.fig--app-setup i {
      width: 280px;
      height: 147px;
      background-image: url("~@/assets/images/img_appsetup.png");
    }
    @media (max-width: 750px) {
      display: none;
    }
  }
  &-form {
    margin: 8px 0 40px;
  }
  &-tips {
    margin-bottom: 40px;
    text-align: center;
    span {
      color: @tint_light_1
    }
  }
  &-button {
    .font16;
    width: 100%;
    height: 48px;
    :deep(.icon-tiktok) {
      rect {
        fill: transparent;
      }
      path {
        fill: #1f2128;
      }
    }
  }
  &-subbutton {
    &-wrap {
      text-align: center;
      margin-top: 8px;
    }
    text-decoration: underline;
  }
  &-footer {
    display: flex;

    &>* {
      flex: 1 1 0;
      margin-right: 16px;
      height: 48px;
      font-size: 18px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-wechat {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 16px;
    &-tips {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        .font14;
      }
    }
    :deep(iframe) {
      width: 200px;
      height: 200px;
    }
  }

  .form-item {
    line-height: 0;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    &--input {
      .font16;
      width: 100%;
      height: 48px;

      .w-input__suffix {
        .w-button {
          &.w-button--primary {
            color: var(--el-gf2);
          }
        }
      }
    }
    &--tips {
      .font12;
      margin-top: 4px;
      padding: 0 16px;
      color: @label_light_4;
    }
  }
  .form--tips {
    .font14;
    color: @label_light_3;
    margin-top: 12px;
    padding: 0 16px;
  }
}
.popover-apikey,
.popover-apikey-error {
  width: 420px;
  padding: 16px;
  background-color: @tint_black;
  h2 {
    .font18;
    margin-bottom: 16px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    color: @label_light_2;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 750px) {
    width: 350px;
    h2,
    p {
      .font16;
    }
  }
}

</style>
