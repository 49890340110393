import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {
};

const getters = {

};
const mutations = {
};
const actions = {
  /**
   * 查询货品上架/改价预览数据
   * @param {Array} assets 需要查询的货品列表
   *   @param {string} assetid
   *   @param {string} classid
   *   @param {string} contextid
   *   @param {string} game
   *   @param {string} goods_id
   *   @param {string} instanceid
   *   @param {string} market_hash_name
   *   @param {string} price 改价时需传原价格
   */
  getMarketSellOrderPreview({ rootState }, { assets }) {
    const url = api.getPartnerSellOrderPreview();
    const PER = 50; // 分块加载，服务端限制一次最多200条
    let rest = assets.slice();
    const assetsArr = [];
    do {
      assetsArr.push(rest.slice(0, PER));
      rest = rest.slice(PER);
    } while (rest.length);

    const promiseArr = assetsArr.map((items) => Axios({
      method: 'post',
      url,
      data: {
        game: rootState.game,
        assets: items,
      },
    }).then((resp) => resp.data));

    return Promise.all(promiseArr).then((respArr) => respArr.reduce((prev, cur) => {
      if (!prev) return cur;
      prev.goods_infos = { ...prev.goods_infos, ...cur.goods_infos };
      prev.group_key_data = { ...prev.group_key_data, ...cur.group_key_data };
      prev.items = [...prev.items, ...cur.items];
      return prev;
    }, null));
  },
  /**
   * 查询合作游戏货品上架/改价预览数据
   * @param {Array} assets 需要查询的货品列表
   *   @param {string} assetid
   *   @param {string} classid
   *   @param {string} contextid
   *   @param {string} game
   *   @param {string} goods_id
   *   @param {string} instanceid
   *   @param {string} market_hash_name
   *   @param {string} price 改价时需传原价格
   */
  getPartnerSellOrderPreview({ rootState }, { assets }) {
    const PER = 50; // 分块加载，服务端限制一次最多200条
    let rest = assets.slice();
    const assetsArr = [];
    do {
      assetsArr.push(rest.slice(0, PER));
      rest = rest.slice(PER);
    } while (rest.length);

    const promiseArr = assetsArr.map((items) => Axios({
      method: 'post',
      url: api.getPartnerSellOrderPreview(),
      data: {
        game: rootState.game,
        assets: items,
      },
    }).then((resp) => resp.data));

    return Promise.all(promiseArr).then((respArr) => respArr.reduce((prev, cur) => {
      if (!prev) return cur;
      prev.goods_infos = { ...prev.goods_infos, ...cur.goods_infos };
      prev.group_key_data = { ...prev.group_key_data, ...cur.group_key_data };
      prev.items = [...prev.items, ...cur.items];
      return prev;
    }, null));
  },
  /**
   * 查询货品手续费
   * @param {string} prices 用`,`拼接的价格列表
   * @param {number} checkPrice 是否检查价格，0或1
   * @param {string} goodsIds 用`,`拼接的商品ID列表
   * @param {number} isChange 1代表改价，0为上架
   */
  getMarketBatchFee({ rootState }, {
    prices,
    checkPrice = 0,
    goodsIds,
    isChange = 0,
  }) {
    return Axios({
      method: 'post',
      url: api.getMarketBatchFee(),
      data: {
        game: rootState.game,
        prices: prices.split(','),
        check_price: checkPrice,
        goods_ids: goodsIds.split(','),
        is_change: isChange,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 根据实收查询定价
   * @param {string} incomes 用`,`拼接的价格列表
   */
  getMarketPricesFromIncomes({ rootState }, { incomes, goodsIds }) {
    return Axios({
      method: 'post',
      url: api.getMarketPricesFromIncomes(),
      data: {
        game: rootState.game,
        incomes: incomes.split(','),
        goods_ids: goodsIds.split(','),
      },
    }).then((resp) => resp.data);
  },
  /**
   * 上架饰品
   * @param {Array} assets 物品列表
   *   @param {string} assetid 物品id
   *   @param {number} price 上架价格
   *   @param {number} income 实收价格（竞价饰品）
   *   @param {string} desc 物品描述
   */
  async postMarketCreateSellOrder({ rootState }, { assets }) {
    const url = api.postPartnerCreateSellOrder();
    const PER = 50; // 分块加载，服务端限制一次最多200条
    let rest = assets.slice();
    const assetsArr = [];
    do {
      assetsArr.push(rest.slice(0, PER));
      rest = rest.slice(PER);
    } while (rest.length);

    let result = {};
    for (let i = 0; i < assetsArr.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const data = await Axios({
        method: 'post',
        url,
        data: {
          game: rootState.game,
          assets: assetsArr[i].map((item) => ({ ...item, game: rootState.game })),
        },
      }).then((resp) => resp.data.data);
      result = { ...result, ...data };
    }
    return result;
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
