<template>
  <div class="topup-empty">
    <Empty
      :type="type"
      :tips="title" />
  </div>
</template>

<script>
import Empty from '@/components/Empty.vue';

export default {
  components: {
    Empty,
  },
  props: {
    title: String,
    type: {
      type: String,
      default: 'C2CNoData',
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="less" scoped>
.topup-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh !important;
  // padding: 100px 20px 0;
  @media (max-width: 768px) {
    height: calc(90% - 44px);
  }
  :deep(.c-empty) {
    .c-empty-fig {
      width: 160px;
      height: 93px;
    }
    .c-empty-tips {
      color: rgba(31, 33, 40, 0.25);
      font-size: 15px;
    }
  }
}
</style>
