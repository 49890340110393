import store from '@/store';

import creator from './creator';

const plugin = {};
plugin.install = (VueInstance) => {
  const feedback = creator(store);
  VueInstance.prototype.$feedback = async (payload, replyId) => {
    const { feedbackToken } = store.state.Feedback;
    if (!feedbackToken) {
      await store.dispatch('fetchFeedbackToken');
    }
    return feedback(payload, replyId);
  };
};

export default plugin;
