<template>
  <div v-if="saveText" class="c-save-tag font12b-min font-fin">{{ saveText }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatPrice from '@/filters/format-price';

export default {
  props: {
    rate: {
      type: [Number, String],
      default: 0,
    },
    price: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['currencySymbol']),
    saveText() {
      if (parseFloat(this.rate) > 0) {
        return `-${this.rate}%`;
      }

      if (parseFloat(this.price) > 0) {
        return `-${this.currencySymbol}${formatPrice(parseFloat(this.price), null, { rated: true })}`;
      }

      return '';
    },
  },
};
</script>

<style lang="less">
.c-save-tag {
  padding: 0 4px;
  min-width: 48px;
  color: var(--el-wf1);
  text-align: center;
  border-radius: var(--radius-3);
  background: var(--fu-orange1);
  box-sizing: border-box;
}
</style>
