<script>
export default {
  props: {
    keyword: [String, Number],
  },
  render(h) {
    const { text } = this.$slots.default[0];
    const escape = this.keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escape, 'gi');
    const result = text.replace(regex, '<span class="c-highligr_text_search">$&</span>');
    return h('span', {
      domProps: {
        innerHTML: result,
      },
    });
  },
};
</script>

<style lang="less">
.c-highligr_text_search {
  color: @tint_primary_1;
}
</style>
