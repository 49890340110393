import Vue from 'vue';
import Video from './video.vue';

const VideoCtor = Vue.extend(Video);

const VideoCreator = () => ({
  title, url,
} = {}) => {
  const instance = new VideoCtor({
    el: document.createElement('div'),
  });
  instance.title = title;
  instance.url = url;
  instance.show = true;
  instance.promise = new Promise((resolve) => {
    instance.$on('close', () => {
      instance.show = false;
      resolve();
    });
  });

  instance.promise.instance = instance;

  return instance.promise;
};

export default VideoCreator;
