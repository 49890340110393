let Clipboard = null;

const VueClipboardConfig = {
  autoSetContainer: false,
  appendToBody: true, // This fixes IE, see #50
};
const ClipboardPlugin = {};

ClipboardPlugin.install = (VueInstance) => {
  VueInstance.prototype.$copyText = async (text, container) => {
    // https://github.com/Inndy/vue-clipboard2/blob/master/vue-clipboard.js
    if (!Clipboard) {
      await import('clipboard/dist/clipboard.min').then((ClipboardInstance) => {
        Clipboard = ClipboardInstance.default;
      });
    }
    return new Promise((resolve, reject) => {
      const fakeElement = document.createElement('button');
      const clipboard = new Clipboard(fakeElement, {
        text() { return text; },
        action() { return 'copy'; },
        container: typeof container === 'object' ? container : document.body,
      });
      clipboard.on('success', (e) => {
        clipboard.destroy();
        resolve(e);
      });
      clipboard.on('error', (e) => {
        clipboard.destroy();
        reject(e);
      });
      if (VueClipboardConfig.appendToBody) document.body.appendChild(fakeElement);
      fakeElement.click();
      if (VueClipboardConfig.appendToBody) document.body.removeChild(fakeElement);
    });
  };
};

export default ClipboardPlugin;
