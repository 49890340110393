import Vue from 'vue';
import { i18n } from '@/utils/i18n';
import Dialog from './dialog.vue';

const DialogCtor = Vue.extend(Dialog);

const DialogCreator = () => ({
  needTitle = true,
  title,
  content,
  tips,
  needCloseIcon = true,
  needConfirmBtn,
  needCancelBtn,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  confirmType,
  width,
  maxHeight,
  countdown,
  theme = '',
} = {}) => {
  const instance = new DialogCtor({
    el: document.createElement('div'),
    i18n,
  });
  instance.title = title || (needTitle ? i18n.t('global.notice') : '');
  instance.content = content;
  instance.tips = tips;
  instance.needCloseIcon = needCloseIcon;
  instance.needConfirmBtn = needConfirmBtn;
  instance.needCancelBtn = needCancelBtn;
  instance.confirmText = confirmText;
  instance.cancelText = cancelText;
  instance.onConfirm = onConfirm;
  instance.onCancel = onCancel;
  instance.confirmType = confirmType;
  instance.width = width;
  instance.maxHeight = maxHeight;
  instance.show = true;
  instance.countdown = countdown;
  if (theme) {
    instance.theme = theme;
  }
  instance.promise = new Promise((resolve) => {
    instance.$on('close', (state) => {
      instance.show = false;
      resolve({ state });
    });
  });

  instance.promise.instance = instance;

  return instance.promise;
};

export default DialogCreator;
