import { mapGetters } from 'vuex';
import {
  CountryCodeCurrencyCodeMap, CountryCodeLanguageMap,
  TextTypeHreflangMap, LanguageCurrencyCodeMap, TextType, LoginStatus,
} from '@/enums';
import { loadLanguageAsyncWithoutSet } from '@/utils/i18n';
import ua from '@/utils/ua';
import { MethodRouteMap, extractSchema, preventDefaultMethods } from '@/utils/webpush';
import LocalStorage from '@/utils/local-storage';
import audioPlayer from '@/utils/audio-player';
import bus from '@/utils/bus';
import { TASK, startupRunner } from '@/utils/startup';
import { googleSignIn } from '@/utils/googleIdentityService';
import { CmpCountryEnum } from '@/enums/global';
import { isGoogleBot } from './utils';

export default {
  data() {
    return {
      showCookiePolicy: false,
      cookiePolicyCollapsed: true,
      suggestLanguage: null,
      cookiePolicyComponent: null,
      cookiePolicyHandle: null,
    };
  },
  methods: {
    selectCurrency(currencyCode) {
      const { initCurrencyCode } = this.$store.state.Account;
      const selected = this.currencies.find((item) => item.key === currencyCode)
        || this.currencies.find((item) => item.key === initCurrencyCode)
        || this.currencies.find((item) => item.key === LanguageCurrencyCodeMap[this.textType]);
      this.$store.commit('SET_CURRENCY', selected);
    },
    async getSupportedCurrency() {
      try {
        const { items } = await this.$store.dispatch('getSupportedCurrency');
        this.$store.commit('SET_CURRENCIES', items);
      } catch (err) {
        this.$_handleError(err);
      }
      this.selectCurrency(this.currencyCode);
    },
    async initFeedbackLang() {
      return this.$store.dispatch('initFeedbackLang');
    },
    updateNavGameAppList() {
      this.$store.dispatch('getNavGameAllAppList');
      this.$store.dispatch('getNavGameHotAppList');
    },
    updateFcmToken() {
      if (!this.$fcm.isSupported) return;
      if (this.userInfo) {
        this.$fcm.removeAllListeners();
        this.$fcm.on('updatetoken', (token) => {
          this.$store.dispatch('postAccountFcmRegistToken', { token });
        });
        this.$fcm.on('foreground-message', () => {
          if (this.userInfo.user_type === 2) {
            audioPlayer.play('notice.wav');
          }
        });
        this.$fcm.on('notification-clicked', (data) => {
          const { click_action: action } = data.data.FCM_MSG.data;
          const { method, payload } = extractSchema(action);
          if (method && preventDefaultMethods.has(method)) {
            bus.$emit('webpush.clicked', { method, payload });
          } else if (method && MethodRouteMap[method]) {
            const userType = this.userInfo?.user_type || 0;
            const route = MethodRouteMap[method](payload, userType);
            this.$router.push(route);
          }
        });
        if (this.$fcm.permission === 'granted') {
          this.$fcm.init().catch(() => {});
        }
      } else {
        this.$fcm.clear().catch(() => {});
      }
    },
    updateHreflang() {
      const { name, query, hash } = this.$route;
      const { hreflang, ..._params } = this.$route.params || {};
      if (hreflang !== TextTypeHreflangMap[this.textType]) {
        const _hreflang = TextTypeHreflangMap[this.textType];
        this.$router.replace({
          name,
          query,
          params: { ..._params, hreflang: _hreflang },
          hash,
        });
      }
    },
    async handleSuggestedLanguage(countryCode) {
      const language = CountryCodeLanguageMap[countryCode] || TextType.en;
      if (language === this.textType) return Promise.resolve();
      const key = CountryCodeLanguageMap[countryCode] ? countryCode.toLowerCase() : 'default';
      // 先加载对应语言包，再弹出提示框
      await loadLanguageAsyncWithoutSet(language);
      const title = this.$t('global.notice', language);
      const content = this.$te(`profile.language_detect_suggest_${key}`, language)
        ? this.$t(`profile.language_detect_suggest_${key}`, language)
        : this.$t('profile.language_detect_suggest_default', language);
      const confirmText = this.$t('profile.language_detect_accept', language);
      const cancelText = this.$t('profile.language_detect_ignore', language);
      this.suggestLanguage = {
        title, content, confirmText, cancelText, language,
      };
      return new Promise((resolve, reject) => {
        this.suggestLanguage.resolve = resolve;
        this.suggestLanguage.reject = reject;
      });
    },
    handleSuggestLanguageConfirm() {
      this.$store.dispatch('updateLaunguage', { language: this.suggestLanguage.language });
      this.updateHreflang();
      this.suggestLanguage.resolve();
      this.suggestLanguage = null;
    },
    handleSuggestLanguageCancel() {
      this.suggestLanguage.reject();
      this.suggestLanguage = null;
    },
  },
  mounted() {
    const promise = this.countryInfo ? Promise.resolve()
      : this.$store.dispatch('getIntegrateInitInfo');
    promise.then(() => {
      const { country_code: code } = this.countryInfo;
      // 俄罗斯用户使用yandex跟踪用户行为
      if (code === 'RU') require('@/assets/js/yandex.min.js'); // eslint-disable-line
      if (isGoogleBot()) {
        const currencyCode = LanguageCurrencyCodeMap[this.textType] || 'USD';
        this.selectCurrency(currencyCode);
      } else {
        const selectCurrencyCode = LocalStorage.get('select-currency');
        const currencyCode = selectCurrencyCode || CountryCodeCurrencyCodeMap[code];
        if (currencyCode) {
          this.selectCurrency(currencyCode);
        }
      }
      startupRunner.register(TASK.Hreflang, () => {
        if (!this.isLogined && !isGoogleBot()) {
          return this.handleSuggestedLanguage(code);
        }
        return Promise.reject();
      });
      startupRunner.register(TASK.GoogleOneClickLogin, () => {
        if (!this.isLogined) {
          return new Promise((resolve, reject) => {
            const callback = (params = {}) => {
              const { eventName, status } = params;
              if (eventName) this.$logger(eventName);
              switch (status) {
                case 'resolved':
                  resolve();
                  break;
                case 'rejected':
                  reject();
                  break;
                default:
                  break;
              }
            };
            googleSignIn(callback);
          });
        }
        return Promise.reject();
      });
      startupRunner.register(TASK.CookiePolicy, () => new Promise((resolve, reject) => {
        if (isGoogleBot() || this.isInBuffApp || this.isFc24Card) {
          reject();
          return;
        }

        if (this.isCmpEurope && !LocalStorage.get('COOKIE_POLICY')) {
          this.cookiePolicyComponent = 'CookiePolicy';
          this.cookiePolicyHandle = () => {
            resolve();
          };
          return;
        }

        resolve();
      }));
      startupRunner.run();
    });
    this.initFeedbackLang().catch(() => {});
    this.updateNavGameAppList();
    if (this.$fcm.isSupported) {
      this.updateFcmToken();
    }
  },
  computed: {
    ...mapGetters(['loginStatus', 'currencies', 'currencyCode', 'userInfo', 'countryInfo', 'textType']),
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    isLootbar() {
      return !this.$store.getters.gameType;
    },
    isInBuffApp() {
      return ua.os.client;
    },
    // 欧洲国家/地区
    isCmpEurope() {
      if (!this.countryInfo) return false;
      return CmpCountryEnum.indexOf(this.countryInfo.country_code.toUpperCase()) !== -1;
    },
    isFc24Card() {
      return this.$route?.query?.utm_source === 'fc24creator';
    },
  },
  watch: {
    textType() {
      this.$meta.set();
      if (!this.$store.state.preventGlobalRefresh) {
        this.reloading = true;
        this.$nextTick(() => {
          this.reloading = false;
        });
      }
      this.getSupportedCurrency();
      this.$store.commit('CLEAR_ANNOUNCEMENT');
      this.updateNavGameAppList();
    },
    userInfo(val, oldVal) {
      if (!val || !oldVal) {
        this.updateFcmToken();
        this.updateHreflang();
      }
    },
    $route(val, oldVal) {
      // 路由变化后需要主动触发yandex事件
      // ym 为 yandex SDK 绑定在 window 的方法
      if (window.ym) {
        const { origin } = window.location;
        const to = `${origin}${val.fullPath}`;
        const from = `${origin}${oldVal.fullPath}`;
        window.ym(process.env.VUE_APP_YANDEX_TAG, 'hit', to, { referer: from });
      }
    },
  },
};
