import market from './market';
import Inventory from './inventory';
import Buy from './buy';
import Sell from './sell';
import Account from './account';
import Feedback from './feedback';
import Store from './store';
import Community from './community';
import lootbar from './lootbar';
import Activity from './activity';
import Eva from './activity/eva';
import Im from './im';
import Coupon from './coupon';

export default {
  market,
  Inventory,
  Buy,
  Sell,
  Account,
  Feedback,
  Store,
  Community,
  lootbar,
  Activity,
  Im,
  Coupon,
  Eva,
};
