<template>
  <div class="c-rate" :class="{
    [`c-rate--${size}`]: size,
    'is-disabled': disabled,
  }">
    <span
      v-for="n in 5" :key="n"
      :class="{ 'is-hover': n === hoverIndex}"
      :data-index="n"
      @click="changeStar(n)">
      <i
        class="w-icon icon-star"
        :class="`is-${starTypes[n-1]}`"></i>
    </span>
  </div>
</template>

<script>
import { isMobile } from '@/utils';

export default {
  props: {
    value: {
      type: Number,
      default: 5,
    },
    size: {
      type: String,
      default: 'l',
      validator(val) {
        return ['s', 'm', 'l'].includes(val);
      },
    },
    disabled: Boolean,
  },
  data() {
    return {
      hoverIndex: -1,
    };
  },
  computed: {
    displayValue() {
      if (this.disabled) return this.value;
      return this.hoverIndex === -1 ? this.value : this.hoverIndex;
    },
    starTypes() {
      const types = [];
      for (let i = 0; i < 5; i += 1) {
        if (i + 0.8 <= this.displayValue) {
          types.push('full');
        } else if (i + 0.3 <= this.displayValue) {
          types.push('half');
        } else {
          types.push('');
        }
      }
      return types;
    },
  },
  mounted() {
    const eventName = isMobile() ? 'touchstart' : 'mouseenter';
    this.handleEnter = this._handleEnter.bind(this);
    this.handleMove = this._handleMove.bind(this);
    this.handleLeave = this._handleLeave.bind(this);
    this.$el.addEventListener(eventName, this.handleEnter);
  },
  beforeDestroy() {
    const eventName = isMobile() ? 'touchstart' : 'mouseenter';
    this.$el.removeEventListener(eventName, this.handleEnter);
  },
  methods: {
    handleHover(n) {
      if (this.disabled) return;
      this.hoverIndex = n;
    },
    clearHover() {
      if (this.disabled) return;
      this.hoverIndex = -1;
    },
    changeStar(n) {
      if (this.disabled) return;
      this.$emit('input', n);
    },
    handleBindStart() {

    },
    handleBind() {
      const moveEvent = isMobile() ? 'touchmove' : 'mousemove';
      const leaveEvent = isMobile() ? 'touchend' : 'mouseleave';
      this.$el.addEventListener(moveEvent, this.handleMove);
      this.$el.addEventListener(leaveEvent, this.handleLeave);
    },
    handleUnbind() {
      const moveEvent = isMobile() ? 'touchmove' : 'mousemove';
      const leaveEvent = isMobile() ? 'touchend' : 'mouseleave';
      this.$el.removeEventListener(moveEvent, this.handleMove);
      this.$el.removeEventListener(leaveEvent, this.handleLeave);
    },
    _handleEnter(e) {
      this.handleBind();
      let el = e.target;
      while (el && !el.dataset?.index) el = el.parentNode;
      if (!el) return;
      const { index } = el.dataset;
      this.handleHover(parseInt(index, 10));
    },
    _handleMove(e) {
      let el = e.target;
      while (el && !el.dataset?.index) el = el.parentNode;
      if (!el) return;
      const { index } = el.dataset;
      this.handleHover(parseInt(index, 10));
    },
    _handleLeave() {
      this.clearHover();
      this.handleUnbind();
    },
  },
};
</script>

<style lang="less" scoped>
.c-rate {
  display: inline-block;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  &.is-disabled {
    cursor: default;
  }
  span {
    display: inline-block;
    font-size: 0;
    &:last-child .w-icon {
      margin-right: 0;
    }
    &.is-hover .w-icon {
      transform: scale(1.3);
    }
  }
  .w-icon {
    transition: all 0.2s;
  }
  &--l .w-icon {
    width: 32px; height: 32px;
    margin-right: 8px;
  }
  &--m .w-icon {
    width: 20px; height: 20px;
    margin-right: 1px;
  }
  &--s .w-icon {
    width: 16px; height: 16px;
    margin-right: 1px;
  }
}
</style>
