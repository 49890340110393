import store from '@/store';
import setMeta from '@/utils/set-meta';

const meta = {};
meta.install = (VueInstance) => {
  VueInstance.prototype.$meta = {
    set: (params = {}, isMarket = false) => {
      const displayLanguage = store.getters.textType;

      setMeta({
        title: params.title,
        description: params.description,
        keywords: params.keywords || params.keyword,
        product: params.product,
        canonical: params.canonical,
      }, { isMarket, language: displayLanguage });
    },
  };
};

export default meta;
