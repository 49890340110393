import Vue from 'vue';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import desktopRouter from '@/router/desktop';
import mobileRouter from '@/router/mobile';
import { asyncLoadComponent } from '@/plugins/utils';

let Feedback = null;

const emptyFn = () => {};

const FeedbackCreator = (store) => async (payload = {}, replyId = '') => {
  if (!Feedback) {
    const component = isMobile()
      ? () => import('./Feedback/Feedback.mobile.vue')
      : () => import('./Feedback/Feedback.desktop.vue');
    try {
      Feedback = await asyncLoadComponent(component);
    } catch (error) {
      return Promise.reject();
    }
  }
  const FeedbackCtor = Vue.extend(Feedback);
  const instance = new FeedbackCtor({
    el: document.createElement('div'),
    propsData: { reply: !!replyId, id: replyId },
    store,
    i18n,
    router: isMobile() ? mobileRouter : desktopRouter,
  });
  const {
    category = '',
    content = '',
    images = [],
    isIm = false,
    ordernumberoffical = '',
    readonlyFields = [],
    onSuccess = emptyFn,
    onFailed = emptyFn,
  } = payload || {};
  instance.show = true;
  instance.category = category;
  instance.content = content;
  instance.uploadImgs = images;
  instance.isIm = isIm;
  instance.ordernumberoffical = ordernumberoffical;
  instance.readonlyFields = readonlyFields;
  instance.$on('close', (state) => {
    instance.show = false;
    if (state) onSuccess();
    else onFailed();
  });
  return instance;
};

export default FeedbackCreator;
