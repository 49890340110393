import Vue from 'vue';
import Tooltips from '@/packages/tooltips';
import { isObject, guid } from '@/utils';

const Ctor = Vue.extend(Tooltips);

// { msg, disabled, placement, instance }
const instances = new Map();

export default {
  bind(el, binding) {
    const { value } = binding;
    let msg = '';
    let placement = 'top-start';
    let disabled = false;
    if (!isObject(value)) {
      msg = value;
    } else {
      ({ msg, placement = 'top-start', disabled } = value);
    }

    // 用于跨钩子共享数据
    const id = guid();
    el.dataset.tooltipsId = id; // eslint-disable-line
    instances.set(id, {
      msg,
      placement,
      disabled,
      instance: null,
    });

    el.addEventListener('mouseover', () => {
      // 从 map 中获取数据
      // 因为数据有可能在 update 里面被改变了
      const elId = el.dataset.tooltipsId;
      const elInfos = instances.get(elId);
      let elInstance = elInfos.instance;
      if (!elInstance) {
        elInstance = new Ctor({
          el: document.createElement('div'),
          propsData: {
            offset: [0, 6],
          },
        });
        document.body.appendChild(elInstance.$el);
        elInstance.msg = elInfos.msg;
        elInstance.referenceElm = el;
        elInstance.placement = elInfos.placement;
        elInstance.disabled = elInfos.disabled;
        elInfos.instance = elInstance;
      }
      elInstance.show = true;
    });
    el.addEventListener('mouseleave', () => {
      const elId = el.dataset.tooltipsId;
      const elInfos = instances.get(elId);
      if (!elInfos.instance) return;
      elInfos.instance.show = false;
    });
  },

  update(el, binding) {
    const elId = el.dataset.tooltipsId;
    const elInfos = instances.get(elId);

    const { value } = binding;
    if (!isObject(value)) {
      elInfos.msg = value;
    } else {
      elInfos.msg = value.msg;
      elInfos.disabled = value.disabled;
    }

    if (elInfos.instance) {
      elInfos.instance.msg = elInfos.msg;
      elInfos.instance.disabled = elInfos.disabled;
    }
  },

  unbind(el) {
    const elId = el.dataset.tooltipsId;
    const elInfos = instances.get(elId);

    if (elInfos.instance) {
      elInfos.instance.$destroy();
    }
  },
};
