import Vue from 'vue';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import store from '@/store';
import { asyncLoadComponent } from '@/plugins/utils';

let Password = null;

const PasswordCreator = (type) => async (payload = {}) => {
  const {
    email = '',
    code = '',
    onSuccess = () => {},
    onClose = () => {},
  } = payload;
  if (!Password) {
    const component = isMobile()
      ? () => import('./Password.mobile.vue')
      : () => import('./Password.desktop.vue');
    try {
      Password = await asyncLoadComponent(component);
    } catch (error) {
      return Promise.reject();
    }
  }
  const PasswordCtor = Vue.extend(Password);
  const instance = new PasswordCtor({
    el: document.createElement('div'),
    store,
    i18n,
  });
  instance.type = type;
  instance.email = email;
  instance.code = code;
  instance.show = true;
  instance.$on('success', (_payload) => {
    onSuccess(_payload);
  });
  instance.$on('close', () => {
    instance.show = false;
    onClose();
  });

  return instance;
};

export default PasswordCreator;
