import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {

};

const getters = {

};
const mutations = {

};
const actions = {
  /**
   * 获取CSGO库存货品列表
   * @param {number} force 是否强刷: 0,1
   * @param {string} search 搜索字段
   * @param {string} sortBy 排序规则, 例:price.asc
   * @param {Object} filters 其他筛选条件
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  getMarketInventory({ rootState }, {
    search, sortBy, filters: _filters = {}, page, force,
  } = {}) {
    const filters = {};
    const tags = {};
    let hasTags = false;
    Object.keys(_filters).forEach((key) => {
      if (rootState.availableTags.has(key)) {
        tags[key] = _filters[key].split(',');
        hasTags = true;
      } else {
        filters[key] = _filters[key];
      }
    });
    const url = api.getMarketPartnerInventory();
    return Axios({
      method: 'get',
      url,
      params: {
        ...filters,
        tags: hasTags ? JSON.stringify(tags) : undefined,
        search,
        force,
        game: rootState.game,
        sort_by: sortBy,
        page_num: page.pageNum,
        page_size: page.pageSize,
      },
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
