import { DialogState } from '@/enums';
import store from '@/store';
import creator from './creator';

const plugin = {};
plugin.install = (VueInstance) => {
  VueInstance.prototype.$share = creator(store);
  VueInstance.prototype.$share.state = DialogState;
};

export default plugin;
