const EaseFnEnum = {
  linear: function linear(currentTime, startValue, changeValue, duration) {
    return (changeValue * currentTime) / duration + startValue;
  },
  easeIn: function easeInQuad(currentTime, startValue, changeValue, duration) {
    currentTime /= duration;
    return changeValue * currentTime * currentTime + startValue;
  },
  easeOut: function easeOutQuad(currentTime, startValue, changeValue, duration) {
    currentTime /= duration;
    return -changeValue * currentTime * (currentTime - 2) + startValue;
  },
  easeInOut: function easeInOutQuad(currentTime, startValue, changeValue, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) return (changeValue / 2) * currentTime * currentTime + startValue;
    currentTime -= 1;
    return -(changeValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue;
  },
};

export default EaseFnEnum;
