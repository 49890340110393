export const TASK = {
  EmailCheck: 'email-check', // 邮箱验证
  Hreflang: 'hreflang', // 语言不匹配
  GameRedirect: 'game-redirect', // 重定向游戏提示
  LoginWithTiktok: 'login-with-tiktok', // tiktok内嵌页引导登录
  LoginWithShareKey: 'login-with-share-key', // 荒野游戏跳转绑定
  CookiePolicy: 'cookie-policy', // 同意cookie政策
  GuideRegisterCoupon: 'guide-register-coupon', // 注册优惠券
  GuideInstallPwaCoupon: 'guide-install-pwa-coupon', // 安装pwa优惠券
  GuideInstallPwa: 'guide-install-pwa', // 安装pwa
  GoogleOneClickLogin: 'google-one-click-login', // 谷歌一键快捷登录
};

const STARTUP_QUEUE = [
  TASK.CookiePolicy,
  TASK.EmailCheck,
  TASK.Hreflang,
  TASK.GameRedirect,
  TASK.LoginWithShareKey,
  TASK.GuideInstallPwaCoupon,
  TASK.GuideRegisterCoupon,
  TASK.GoogleOneClickLogin,
  TASK.LoginWithTiktok,
  TASK.GuideInstallPwa,
];

class Runner {
  constructor(queue) {
    this.index = 0;
    this.queue = queue.map((key) => ({
      key,
      handler: null,
    }));
    this.done = [];
  }

  register(key, handler) {
    const item = this.queue.find((i) => i.key === key);
    if (item) {
      item.handler = handler;
    }
  }

  async run() {
    const item = this.queue[this.index];
    if (!item) return;
    if (item.handler) {
      try {
        await item.handler();
        this.done.push(item.key);
      } catch (err) { /* */ }
    }
    this.index += 1;
    await this.run();
  }

  checkTaskDone(key) {
    return this.done.includes(key);
  }

  checkTasksDone(keys, type = 'every') {
    return keys[type]((key) => this.checkTaskDone(key));
  }
}

export const startupRunner = new Runner(STARTUP_QUEUE);
