import Vue from 'vue';
import Vuex from 'vuex';
import {
  TextType, GameType, ProfileErrorGuideMap, FeedbackLanguageMap,
} from '@/enums';
import { isStandalone } from '@/utils';
import AxiosExt from '@/utils/axios.ext';
import AxiosFeedback from '@/utils/axios.feedback';
import api from '@/api';
import GuideCreator from '@/plugins/guide/creator';

import modules from './modules/index';
import ProfileErrorHandler from './profile-error-handler';

import { version } from '../../public/manifest.json';

Vue.use(Vuex);

function recursiveFilterItem(filter) {
  if (!filter.children.length) {
    return {
      value: filter.value,
      title: filter.title,
      attribute: filter.name,
    };
  }
  const _filter = {};
  if (filter.value) {
    _filter.all = {
      attribute: filter.name,
      title_selected: filter.title,
      value: filter.value,
    };
  }
  _filter.list = filter.children.map((item) => recursiveFilterItem(item));
  _filter.attribute = _filter.list[0].attribute;
  _filter.key = filter.value;
  _filter.title = filter.title;

  return _filter;
}

const store = new Vuex.Store({
  state: {
    game: GameType.knivesout,
    appVersion: version,
    subgame: '',
    _subgames: {},
    subgames: [],
    _tags: {},
    tags: [],
    tagShortcuts: [],
    availableTags: new Set([]),
    shareKey: '',
    accountFpToken: {},
    feedbackFpToken: '',
    delayShareToken: false, // 延迟分享token

    deferredInstallPrompt: null,
    isInStandalone: isStandalone(),

    preventGlobalRefresh: false, // 阻止设置语言时全局刷新
  },
  mutations: {
    UPDATE_APP_VERSION(state, payload) {
      state.appVersion = payload.version;
    },
    /**
     * 设置全局延迟显示分享token的弹窗
     */
    SET_DELAY_SHARE_TOKEN(state, payload) {
      state.delayShareToken = payload;
    },
    /**
     * 设置全局游戏类型
     * @param {string} game 游戏类型
     */
    SET_GAME_TYPE(state, game) {
      state.game = game;
    },
    /**
     * 设置全局游戏服务器类型
     * @param {string} subgame 游戏服务器类型
     */
    SET_SUBGAME_TYPE(state, subgame) {
      state.subgame = subgame;
    },
    /**
     * 设置全局游戏服务器类型列表
     * @param {string} subgames 游戏服务器类型列表
     */
    SET_SUBGAMES(_state, { game, subgames, lang }) {
      _state.subgames = subgames;
      _state._subgames = { ..._state._subgames, [`${lang}-${game}`]: subgames };
    },
    /**
     * 设置全局游戏筛选项类型列表
     * @param {string} tags 筛选项列表
     */
    SET_TAGS(_state, {
      game, tags, shortcuts, lang,
    }) {
      _state.tags = tags;
      _state.tagShortcuts = shortcuts;
      _state._tags = { ..._state._tags, [`${lang}-${game}`]: { tags, shortcuts } };
      const availableTags = [];
      function traversalTags(_tags) {
        _tags.forEach((tag) => {
          if (tag.list) traversalTags(tag.list);
          if (tag.attribute) availableTags.push(tag.attribute);
          if (tag.all && tag.all.attribute) availableTags.push(tag.all.attribute);
        });
      }
      traversalTags(tags);
      _state.availableTags = new Set(availableTags);
    },
    /**
     * 设置分享码
     * @param {string} shareKey 游戏分享码
     */
    SET_SHARE_KEY(_state, shareKey) {
      _state.shareKey = shareKey;
    },
    SET_ACCOUNT_FP_TOKEN(_state, { fileType, token }) {
      _state.accountFpToken = { ..._state.accountFpToken, [fileType]: token };
    },
    SET_FEEDBACK_FP_TOKEN(_state, { token }) {
      _state.feedbackFpToken = token;
    },
    SET_DEFERRED_INSTALL_PROMPT(_state, prompt) {
      _state.deferredInstallPrompt = prompt;
    },
    SET_PREVENT_GLOBAL_REFRESH(_state, refresh) {
      _state.preventGlobalRefresh = refresh;
    },
  },
  getters: {
    delayShareToken: (_state) => _state.delayShareToken,
    gameType: (_state) => _state.game.replace(/\d+$/, ''),
    subgame: (_state, _getters) => {
      if (_getters.userSubgame) return _getters.userSubgame;
      if (_state.subgame) return _state.subgame;
      const selected = _state.subgames.find((item) => item.default) || _state.subgames[0];
      return selected ? selected.key : '';
    },
    subgames: (_state) => _state.subgames,
    tags: (_state) => _state.tags,
  },
  actions: {
    getVersion() {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', '/manifest.json', true);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              const responseData = JSON.parse(xhr.responseText);
              resolve(responseData.version);
            } else {
              reject(new Error(xhr));
            }
          }
        };
        xhr.onerror = () => {
          reject(new Error('Request failed'));
        };
        xhr.send();
      });
    },
    /**
     * 获取游戏服务器列表
     * @param {string} game 游戏
     */
    getSupportedSubgames({ state, commit, getters }, data = {}) {
      const { game } = data;
      const lang = getters.textType;
      const cachedSubgames = state._subgames[`${lang}-${state.game}`];
      if (cachedSubgames && cachedSubgames.length) {
        commit('SET_SUBGAMES', { game: state.game, subgames: cachedSubgames, lang });
        return cachedSubgames;
      }
      return AxiosExt({
        method: 'get',
        url: api.getSupportedSubgames(),
        params: {
          game: state.game || game,
        },
      }).then((resp) => {
        const subgames = [];
        const { items } = resp.data;
        items.forEach((item) => {
          subgames.push({
            key: item.subgame_name,
            label: item.subgame_realname,
            default: item.default,
          });
        });
        commit('SET_SUBGAMES', { game: state.game, subgames, lang });
        return subgames;
      });
    },
    /**
     * 获取游戏筛选项列表
     * @param {string} game 游戏
     */
    getMarketTags({ state, commit, getters }) {
      const lang = getters.textType;
      const { tags: cachedTags, shortcuts: cachedShortcuts } = state._tags[`${lang}-${state.game}`] || {};
      if (cachedTags && Array.isArray(cachedTags)) {
        commit('SET_TAGS', {
          game: state.game, tags: cachedTags, shortcuts: cachedShortcuts, lang,
        });
        return cachedTags;
      }
      return AxiosExt({
        method: 'get',
        url: api.getMarketTags(),
        params: {
          game: state.game,
        },
      }).then((resp) => {
        const tags = [];
        const { items, shortcuts = [] } = resp.data;
        items.forEach((item) => {
          tags.push(recursiveFilterItem(item));
        });
        commit('SET_TAGS', {
          game: state.game, tags, shortcuts, lang,
        });
        return tags;
      });
    },
    /**
     * 获取主站上传图片token
     */
    async getAccountGenToken({ state: _state, commit }, { fileType }) {
      const fpToken = _state.accountFpToken[fileType];
      const now = new Date().getTime();
      if (fpToken && now - fpToken.get_time <= fpToken.timeout * 1000) {
        return fpToken;
      }
      return AxiosExt({
        method: 'get',
        url: api.getAccountToken(),
        params: { file_type: fileType },
      }).then((resp) => {
        const token = resp.data;
        token.get_time = new Date().getTime();
        commit('SET_ACCOUNT_FP_TOKEN', { fileType, token });
        return token;
      });
    },
    /**
     * 获取反馈上传图片token
     */
    async getFeedbackGenToken({ state: _state, commit }) {
      const fpToken = _state.feedbackFpToken;
      const now = new Date().getTime();
      if (fpToken && now - fpToken.get_time <= fpToken.timeout * 1000) {
        return fpToken;
      }
      return AxiosFeedback({
        method: 'get',
        url: api.getFeedbackFpToken(),
      }).then((resp) => {
        const token = resp.data;
        token.get_time = new Date().getTime();
        commit('SET_FEEDBACK_FP_TOKEN', { token });
        return token;
      });
    },
  },
  modules,
});

AxiosExt.interceptors.request.use((req) => {
  const lang = store.getters.textType;
  const currency = store.getters.currencyCode;
  if (lang) req.headers['X-PS-Locale'] = lang;
  if (currency) req.headers['X-Currency'] = currency;
  return req;
}, (err) => Promise.reject(err));

// axios添加检测profile的响应拦截器
const guide = GuideCreator(store);
const handler = new ProfileErrorHandler(guide);
AxiosExt.interceptors.response.use(null, (error) => {
  const { type, data, config } = error;
  // 对data_error进行分类
  if (type === 'data_error') {
    const { status } = data;
    const guideType = ProfileErrorGuideMap[status];
    if (status === 'unauthorized') store.dispatch('appLogout');
    if (guideType) {
      const errorData = {
        type: 'profile_error',
        data,
      };
      if (config?.metadata?.ignore_retry) return Promise.reject(errorData);
      return handler.handle(guideType)
        .then(() => AxiosExt(config))
        .catch(() => Promise.reject(errorData));
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

AxiosFeedback.interceptors.request.use((req) => {
  if (store.state.Feedback.feedbackToken) {
    req.headers['FB-Authorization'] = store.state.Feedback.feedbackToken;
  }
  if (store.getters.textType) {
    const lang = FeedbackLanguageMap[store.getters.textType]
      || FeedbackLanguageMap[TextType.en];
    req.headers['FB-ClientPreferLang'] = lang;
  }
  return req;
}, (err) => Promise.reject(err));

export default store;
