<template>
  <component :is="currentComponent" />
</template>

<script>
import mobileAppC2C from './App.mobile.c2c.vue';
// 荒野的不打在里面哈，让动态去加载吧
const mobileApp = () => import(/* webpackChunkName: "market-mobile-entry" */'./App.mobile.vue');

export default {
  components: {
    mobileApp,
    mobileAppC2C,
  },
  data() {
    return {
    };
  },
  computed: {
    currentComponent() {
      const { name } = this.$route;
      if (!name) {
        return '';
      }
      if (name.startsWith('Market.')) {
        return 'mobileApp';
      }
      return 'mobileAppC2C';
    },
  },
  created() {
  },
};
</script>
