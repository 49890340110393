<script>
export default {
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
  },
  render(h) {
    const isImg = /<img.*?>/gi.test(this.htmlContent);
    return h('div', {
      class: `label ${isImg ? '' : 'color' }`,
      domProps: {
        innerHTML: this.htmlContent,
      },
    });
  },
};
</script>

<style scoped lang="less">
.label {
  line-height: 16px;
  min-width: 50px;
  text-align: center;
  font-weight: bold;
  color: @label_white;
  font-size: 12px;
  border-radius: 0 4px 4px 0;
  &.color {
    background: @tint_primary_button;
  }
  :deep(img) {
    width: 49px;
  }
}
</style>
