import defaultManifest from '../../public/manifest.json';

const { origin } = window.location;

const manifest = { ...defaultManifest };

manifest.start_url = origin + manifest.start_url;
manifest.scope = origin + manifest.scope;
manifest.icons = manifest.icons.map((icon) => {
  const _icon = { ...icon };
  _icon.src = origin + icon.src.slice(1);
  return _icon;
});

export default (params = {}) => {
  const { utmSource, utmCampaign } = params;
  manifest.start_url = origin
    + (utmSource
      ? `/?utm_source=${utmSource}&utm_medium=bookmark`
      : '/?utm_source=bookmark&utm_medium=bookmark')
    + (utmCampaign ? `&utm_campaign=${utmCampaign}` : '');
  const content = encodeURIComponent(JSON.stringify(manifest));
  const url = `data:application/manifest+json,${content}`;
  const element = document.querySelector('link[rel="manifest"]');
  element.setAttribute('href', url);
};
