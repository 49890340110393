import Axios from '@/utils/axios.ext';
import api from '@/api';
import LocalStorage from '@/utils/local-storage';

const state = {
  galleryNew: LocalStorage.get('galleryNew') ?? 0,
};

const getters = {
  galleryNew: (_state) => _state.galleryNew,
};
const mutations = {
  SET_GALLERY_NEW(_state, galleryNew) {
    _state.galleryNew = galleryNew;
  },
};
const actions = {
  /**
   * 获取我的评论
   * @param {string} sort_by 排序方式，time(.desc)、ups_num(.desc)，可选
   * @param {string} goods_id 商品id列表
   * @param {number} recommend 显示推荐的玩家秀列表
   * @param {number} state 0:待审核 1:审核通过 2:审核未通过 默认是1
   * @param {string} publisher_id 过滤只显示指定用户发布的玩家秀
   * @param {PageInfo} page 分页信息, 含 pageNum 和 pageSize
   */
  getPreviewList({ rootState }, {
    sort, page, id, goodsId, filters,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getPreviewList(),
      params: {
        game: rootState.game,
        sort_by: sort,
        page_num: page.pageNum,
        page_size: page.pageSize,
        publisher_id: id,
        goods_ids: goodsId,
        ...filters,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 上传玩家秀
   * @param {string} game 游戏
   * @param {string} icon_url 图片链接
   * @param {string} goods_ids 商品id列表，用,分割
   * @param {string} desc 描述
   */
  postPreviewCreate({ rootState }, {
    image, goodsIds, desc, anonymous,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postPreviewCreate(),
      data: {
        game: rootState.game,
        icon_urls: image,
        goods_ids: goodsIds,
        anonymous,
        desc,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 检查栏目是否有新信息
   * @param {string} game 游戏
   * @param {string} column_type 栏目对象类型
   * @param {string} column_id 商品id
   * @param {string} last_browse_time 最后访问时间
   */
  getPreviewCheckColumnNew({ rootState }, {
    goodsIds, time,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getPreviewCheckColumnNew(),
      params: {
        game: rootState.game,
        column_type: 1,
        column_id: goodsIds,
        last_browse_time: time,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 修改玩家秀内容
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   * @param {string} goods_ids 商品id列表，用,分割
   * @param {string} desc 描述
   */
  postChangePreviewContent({ rootState }, {
    id, goodsIds, desc,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postChangePreviewContent(),
      data: {
        game: rootState.game,
        preview_id: id,
        goods_ids: goodsIds,
        desc,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 修改玩家秀状态
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   * @param {string} to_state 修改到的状态 必须 0:待审核 1:审核通过 2:审核未通过
   * @param {string} recommend 是否推荐该玩家秀 true/false 可选
   */
  postChangePreviewState({ rootState }, {
    id, toState, recommend,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postChangePreviewState(),
      data: {
        game: rootState.game,
        preview_id: id,
        to_state: toState,
        recommend,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 玩家秀点赞
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   */
  postUpPreview({ rootState }, { id } = {}) {
    return Axios({
      method: 'post',
      url: api.postUpPreview(),
      data: {
        game: rootState.game,
        preview_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 玩家秀取消点赞
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   */
  postCancelUpPreview({ rootState }, { id } = {}) {
    return Axios({
      method: 'post',
      url: api.postCancelUpPreview(),
      data: {
        game: rootState.game,
        preview_id: id,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取玩家秀详情
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   */
  getPreviewDetail({ rootState }, { id } = {}) {
    return Axios({
      method: 'get',
      url: api.getPreviewDetail(),
      params: {
        game: rootState.game,
        preview_id: id,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取我的玩家秀
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   */
  getMyPreviewList({ rootState }, {
    sortBy, pageNum, pageSize, filters,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getMyPreviewList(),
      params: {
        game: rootState.game,
        sort_by: sortBy,
        page_num: pageNum,
        page_size: pageSize,
        ...filters,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 删除玩家秀
   * @param {string} game 游戏
   * @param {string} preview_id 玩家秀id
   */
  postDeletePreview({ rootState }, { id } = {}) {
    return Axios({
      method: 'post',
      url: api.postDeletePreview(),
      data: {
        game: rootState.game,
        preview_id: id,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取评论列表
   * @param {number} comment_type 评论对象类别，int，目前仅支持资讯（211）, 玩家秀（216)， 快讯(228)
   * @param {string} type_id 评论对象id
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getCommentList(_, {
    type, id, commentId, pageNum, pageSize, sortBy,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCommentList(),
      params: {
        comment_type: type,
        type_id: id,
        comment_id: commentId,
        page_num: pageNum,
        page_size: pageSize,
        sort_by: sortBy,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取回复列表
   * @param {string} comment_id 评论id
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getCommentReplyList(_, {
    id, typeId, pageNum, pageSize,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCommentReplyList(),
      params: {
        type_id: typeId,
        comment_id: id,
        page_num: pageNum,
        page_size: pageSize,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 发表评论
   * @param {number} comment_type 评论对象类别
   * @param {string} type_id 评论对象id
   * @param {string} message 评论内容
   */
  postAddComment(_, {
    id, message, srcCommentId, firstCommentId,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postAddComment(),
      data: {
        type_id: id,
        message,
        first_comment_id: firstCommentId,
        src_comment_id: srcCommentId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 删除评论
   * @param {string} comment_id 评论ID
   * @param {string} message 评论内容
   */
  postDeleteComment(_, { id } = {}) {
    return Axios({
      method: 'post',
      url: api.postDeleteComment(),
      data: {
        comment_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * （取消）点赞评论
   * @param {string} comment_id 评论ID
   * @param {string} action  行为up/cancel
   */
  postUpComment(_, { id, action } = {}) {
    return Axios({
      method: 'post',
      url: api.postUpComment(),
      data: {
        comment_id: id,
        action,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 发表评论
   * @param {string} comment_id 评论id
   * @param {string} reply_id 回复对象id
   * @param {string} message 评论内容
   */
  postAddCommentReply(_, { commentId, message, replyId } = {}) {
    return Axios({
      method: 'post',
      url: api.postAddCommentReply(),
      data: {
        comment_id: commentId,
        message,
        reply_id: replyId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 删除评论
   * @param {string} comment_id 评论ID
   * @param {string} reply_id 回复对象id
   */
  postDeleteCommentReply(_, { commentId, replyId } = {}) {
    return Axios({
      method: 'post',
      url: api.postDeleteCommentReply(),
      data: {
        comment_id: commentId,
        reply_id: replyId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * （取消）点赞评论
   * @param {string} comment_id 评论ID
   * @param {string} reply_id 回复对象id
   * @param {string} action  行为up/cancel
   */
  postUpCommentReply(_, { commentId, id, action } = {}) {
    return Axios({
      method: 'post',
      url: api.postUpCommentReply(),
      data: {
        comment_id: commentId,
        reply_id: id,
        action,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取我的评论
   * @param {number} size 获取的评论和回复数量
   * @param {number} comment_cnt 已获取评论数
   * @param {number} reply_cnt 已获取回复数
   */
  getMyCommentList(_, { size, ...cnt } = {}) {
    return Axios({
      method: 'get',
      url: api.getMyCommentList(),
      params: {
        size,
        ...cnt,
      },
    }).then((resp) => resp.data);
  },

  /**
   * （取消）点赞评论
   * @param {number} report_type 举报对象类别, 目前支持: 商品订单（101）/ 用户（208）/ 玩家秀（216）/ 评论（212）/回复（213）
   * @param {string} type_id 举报对象ID, 评论回复为混淆前ID, 商品为订单ID, 用户为用户ID， 玩家秀为玩家秀ID，均为混淆后
   * @param {string} report_sort  GOODS_DESC = 0              : 商品描述违规
                                  GOODS_NAMETAG = 1           : 商品改名违规
                                  GOODS_OTHER = 2             : 商品其他违规
                                  USER_PRON_VIO = 3           : 用户含敏感信息
                                  USER_AD = 4                 : 用户广告
                                  USER_OTHER = 5              : 用户其他违规
                                  PREVIEW_ERROR = 6           : 玩家秀搭配错误
                                  PREVIEW_DESC = 7            : 玩家秀描述违规
                                  PREVIEW_OTHER = 8           : 玩家秀其他违规
                                  COMMENT_AD = 9              : 评论/回复广告
                                  COMMENT_PRON_VIO = 10       : 评论/回复含敏感信息
                                  COMMENT_PERSON_ATTACK = 11  : 评论/回复玩家攻击
                                  COMMENT_OTHER = 12          : 评论/回复其他违规
   * @param {string} detail 额外原因，可选, 当report_sort 为 2,5,8,12 时添加
   */
  postAddReport(_, {
    type, id, sort, detail,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postAddReport(),
      data: {
        report_type: type,
        type_id: id,
        report_sort: sort,
        detail,
      },
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
